import * as React from 'react';
import { Box, Button, FormGroup, FormLabel, Grid, Link, MenuItem, Select, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import ReservationPlaceItem from './shared/ReservationPlaceItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const BoxLayout = styled(Box)(({ theme }) => ({
    width: '100%',
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
        width: '100%',
    },
}));


export default function RestaurantBookingForm(props) {
    const { setRestaurantDetails, restaurantDetails, restaurant, editTimeHour, editTimeMinutes, pdfFile } = props;
    //const [timeHour, setTimeHour] = React.useState("12:00");
    // const [timeMinutes, setTimeMinutes] = React.useState(`00`);
    const { t } = useTranslation('translation', { keyPrefix: "booking" });
    const times = ["12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00"]
    // useEffect(() => {
    //     if (editTimeHour) {
    //         setTimeHour(editTimeHour)
    //     }
    // }, [editTimeHour]);

    // useEffect(() => {
    //     if (editTimeMinutes) {
    //         setTimeMinutes(editTimeMinutes)
    //     }
    // }, [editTimeMinutes]);
    console.log(restaurant, 'restaurant');
    const upsert = (array, element) => { // (1)
        const i = array.findIndex(_element => _element.type === element.type);
        if (i > -1) array[i] = element; // (2)
        else array.push(element);
    }
    const handleRestaurantDetails = React.useCallback((value, type) => {
        let totalSeat = 0;
        let serviceFees = 0;
        //console.log(type, 'type');
        totalSeat = value;
        serviceFees = totalSeat * 1;
        // if (type === "dining_table_2_seater") {
        //     totalSeat = value * 2;
        //     serviceFees = totalSeat * 1;
        // }
        // if (type === "dining_table_4_seater") {
        //     totalSeat = value * 4
        //     serviceFees = totalSeat * 1;
        // }
        // if (type === "dining_table_6_seater") {
        //     totalSeat = value * 6
        //     serviceFees = totalSeat * 1;
        // }
        
        if (restaurantDetails && restaurantDetails.details && restaurantDetails.details.length > 0) {
            let gridDetails = restaurantDetails.details;
            let detailsObj = { type, value, totalSeat, serviceFees }
            upsert(gridDetails, detailsObj);
            setRestaurantDetails(prevState => ({
                ...prevState,
                details: [...gridDetails],
            }));
        } else {
            let detailsObj = { type, value, totalSeat, serviceFees }
            let object = {
                id: restaurant?.id,
                status: "reserved_full_day",
                reservation_time: restaurantDetails?.reservation_time || "12:00",
                details: [{ ...detailsObj }]
            }
            setRestaurantDetails(object)
        }
    }, [restaurantDetails, setRestaurantDetails, restaurant]);

    const handleTime = React.useCallback((value) => {
        if (!restaurantDetails) {
            setRestaurantDetails(prevState => ({
                ...prevState,
                id: restaurant?.id,
                status: "reserved_full_day",
                reservation_time: value,
                details: []
            }));
        } else {
            setRestaurantDetails(prevState => ({
                ...prevState,
                reservation_time: value,
            }));
        }
    }, [restaurantDetails, restaurant]);

    return (
        <BoxLayout className='identificationc-cls-addinghereusedresturant-option-boxing-type-cads'>

            {pdfFile && (
                <Box>
                    <FormGroup sx={{ color: 'black', ml: "0px", cursor: "pointer" }}>
                        <Link target="_blank" href={pdfFile}>{t("restaurantMenu")}</Link>
                    </FormGroup>
                </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', mt: '10px' }}>
                <Typography color="#000" fontSize="15px" fontWeight="600">
                    Restaurant
                </Typography>
                <hr style={{ border: '2px solid #F8F9FF', margin: '20px 0', width: '100%' }} />
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs} className='identificationc-cls-addinghereusedresturant-option-locationlized'>
                <FormGroup sx={{ color: 'black', mt: '5px', mb: "5px" }}>
                    <FormLabel>{t("whatTimeBookLabel")}</FormLabel>
                    <Stack direction={"row"} sx={{ mt: "15px", mb: "15px" }}>
                        <Grid container spacing={2}>
                            {times.map((item) => (
                                <Grid item>
                                    <Button
                                        onClick={() => handleTime(item)}
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: restaurantDetails?.reservation_time == item ? "#1212e2bf" : "",
                                            color: restaurantDetails?.reservation_time == item ? "white" : "",
                                            "&:hover": {
                                                backgroundColor: restaurantDetails?.reservation_time == item ? "#1212e2bf" : ""
                                            }
                                        }}
                                    >
                                        {moment(item, "HH:mm").format("HH:mm")}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        {/* <Select
                            sx={{
                                width: "80px",
                                background: '#fff',
                                border: 'none',
                                height: '55px',
                                color: "#000",
                                borderRadius: "1px",
                                "& fieldset.MuiOutlinedInput-notchedOutline": {
                                    borderTopRightRadius: "0px",
                                    borderBottomRightRadius: "0px"
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        fontSize: '16px',
                                        fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                                        color: '#06133a',
                                        '& .MuiMenuItem-root': {
                                            padding: 2,
                                        },
                                    },
                                },
                                variant: 'menu',
                                transformOrigin: { vertical: 'top', horizontal: 'center' },
                                getContentAnchorEl: null,
                                disableScrollLock: true,
                            }}
                            size="small"
                            labelId="book-simple-select-label"
                            id="book-simple-select"
                            value={timeHour}
                            onChange={(e) => setTimeHour(e.target.value)}
                            shrink={false}
                        >
                            {(function (rows, i, len) {
                                while (++i <= len) {
                                    rows.push(<MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={i}>{i}</MenuItem>)
                                }
                                return rows;
                            })([], 11, 22)}
                        </Select> */}
                        {/* <Select
                            sx={{
                                width: "80px",
                                color: '#000',
                                border: 'none',
                                height: '55px',
                                borderRadius: '19px',
                                "& fieldset.MuiOutlinedInput-notchedOutline": {
                                    borderTopLeftRadius: "0px 0px",
                                    borderBottomLeftRadius: "0px",
                                    borderLeft: "0px"
                                }
                            }}
                            inputProps={{ sx: { color: "#000 !important" } }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        fontSize: '16px',
                                        fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                                        color: '#06133a',
                                        '& .MuiMenuItem-root': {
                                            padding: 2,
                                        },
                                    },
                                },
                                variant: 'menu',
                                transformOrigin: { vertical: 'top', horizontal: 'center' },
                                getContentAnchorEl: null,
                                disableScrollLock: true,
                            }}
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={timeMinutes}
                            onChange={(e) => setTimeMinutes(e.target.value)}
                            shrink={false}
                        >
                            <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={`00`}>00</MenuItem>
                            <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={15}>15</MenuItem>
                            <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={30}>30</MenuItem>
                            <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={45}>45</MenuItem>
                        </Select> */}
                    </Stack>
                </FormGroup>
            </LocalizationProvider>
            <ReservationPlaceItem
                handleClickNextButton={(e) => handleRestaurantDetails(e, "dining_table_2_seater")}
                handleClickPrevButton={(e) => handleRestaurantDetails(e, "dining_table_2_seater")}
                counter={(restaurantDetails && restaurantDetails?.details.find(obj => { return obj.type === "dining_table_2_seater" })?.value) || 0}
                title={(restaurantDetails && restaurantDetails?.details.find(obj => { return obj.type === "dining_table_2_seater" })?.value) > 1 ? t("covered") : t("coveredSingle")}
            />
        </BoxLayout>
    );
}
