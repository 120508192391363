import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, AppBar, Toolbar, Typography, Stack } from '@mui/material';
import ProSiteButton from './ProSiteButton';
import SearchFilter from './SearchFilter';
import AccountPopover from './AccountPopover';
import { useLocation } from 'react-router-dom';
import search from '../../../assets/icons/search.svg';

import Iconify from '../../../components/iconify';

const headerStyles = {
  background: '#fff',
  height: '82px',
  justifyContent: 'center',
  padding: 0.5,
  boxShadow: '0 25px 50px -12px rgba(0,0,0,.5)!important',
  zIndex: '10',
};

const logoStyles = {
  px: 4,
  fontFamily: 'monospace',
  fontWeight: 300,
  color: 'inherit',
  textDecoration: 'none',
};

export default function MainHeader() {
  const [searchoption, setSearchoption] = useState(false);
  const location = useLocation();

  return (
    <AppBar position="fixed" sx={headerStyles} className='header-cls-adding-type'>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} className='tooling-bar-cls-adding'>
        <Typography
          className='typograph-cls-adding'
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            // px: 4,
            fontFamily: 'monospace',
            fontWeight: 300,
            color: 'inherit',
            textDecoration: 'none',
            minWidth: {
              xs: '100px',
              md: '300px',
              lg: '300px',
            },
          }}
        >
          <Box
            className='primary-svg-lc-sadding'
            component="img"
            alt="logo"
            src="/assets/logo-primary.svg"
            sx={{ maxWidth: { xs: '6rem', sm: "15rem", md: '15rem' } }}
          />
        </Typography>
        <Box
          className="flexing-md-sm-partlcls-adding"
          sx={{
            flexGrow: 2,
            display: {
              sm: 'flex',
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          {location.pathname !== '/' && <SearchFilter />}
        </Box>
        <Stack direction="row" className='boxing-cls-adding-ficed'>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }} className='boxing-cls-adding-ficed-magnificed'>
            {location.pathname !== '/' && (
              <Iconify
                className="icongify-cls-adding-used-searchoption"
                onClick={() => setSearchoption(!searchoption)}
                icon="eva:search-fill"
                sx={{
                  color: 'orange',
                  width: 22,
                  height: 22,
                  display: {
                    sm: 'none',
                    xs: 'none',
                    md: 'none',
                  },
                  marginRight: '20px',
                }}
              />
            )}
            <Box className="first-accoutn-boxing-cls-adding">
              <ProSiteButton className="accordingly-btn-cls-add" />
            </Box>
            <Box width="8rem" className="accoutn-boxing-cls-adding">
              <AccountPopover className="accordingly-btn-cls-add" />
            </Box>
          </Box>
        </Stack>
        {searchoption && (
          <Box
            className="stacking-cls-adding-used-portion"
            sx={{
              position: 'fixed',
              top: '80px',
              backgroundColor: 'white',
              p: 8,
              width: '100%',
              marginLeft: '-28px',
            }}
          >
            <Box
              onClick={() => setSearchoption(!searchoption)} className="stacking-cls-adding-used-portion-svg"
              sx={{ position: 'absolute', top: '10px', right: '50px', color: 'black' }}
            >
              X
            </Box>
            {/* <p sx={{color:'black'}}>dsdm</p> */}
            {location.pathname !== '/' && <SearchFilter />}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

MainHeader.propTypes = {
  onOpenNav: PropTypes.func,
};
