import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import "leaflet/dist/leaflet.css";
import moment from 'moment';
import 'moment/locale/fr';
import { AuthProvider } from './context/AuthContext';
import ReactGA from "react-ga4";

export default function App() {
  moment.locale('fr')

  ReactGA.initialize("G-96ED37M97W");

  
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <HelmetProvider className="helmet-provider-cls-addingtyop-off-all">
      <BrowserRouter className="browserrouter-provider-cls-addingtyop-off-all">
        <ThemeProvider className="themeproviderall-provider-cls-addingtyop-off-all">
          <ScrollToTop className="scrollyopbrowserrouter-provider-cls-addingtyop-off-all"/>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
