/* eslint-disable eqeqeq */
import { Field, Form, Formik } from 'formik';
import { Stack, FormControl, Button, Grid } from '@mui/material';
import { TextField } from 'formik-mui';
import { useCallback } from 'react';
import * as Yup from 'yup';
import { handlePartnerSinup } from '../../services/api/admin.api';
import PhoneField from '../form/formik/PhoneField';
import { useTranslation } from 'react-i18next';

const ContactFormSchema = Yup.object().shape({
  facilityName: Yup.string().required('Champ requis'),
  address: Yup.string().required('Champ requis'),
  city: Yup.string().required('Champ requis'),
  postalCode: Yup.string().required('Champ requis'),
  country: Yup.string().required('Champ requis'),
  firstName: Yup.string().required('Champ requis'),
  name: Yup.string().required('Champ requis'),
  email: Yup.string().email().required('Champ requis'),
  phone: Yup.string().required('Champ requis'),
});

const defaultValuesData = {
  id: '',
  facilityName: '',
  address: '',
  city: '',
  postalCode: '',
  country: '',
  firstName: '',
  name: '',
  email: '',
  phone: '',
};

const PartnerSignupForm = () => {
  const { t } = useTranslation('translation');

  const handleSubmit = useCallback(async (values) => {
    const { address, city, country, email, facilityName, firstName, name, phone, postalCode } = values || {};
    const formData = new FormData();
    formData.append('email', email);
    formData.append('address', address);
    formData.append('establishmentName', facilityName);
    formData.append('city', city);
    formData.append('phone', phone);
    formData.append('country', country);
    formData.append('firstName', name);
    formData.append('lastName', firstName);
    formData.append('postcode', postalCode);
    formData.append('subject', 'Beach Booker. B2C. Formulaire Devenez Partenaire');

    try {
      const res = await handlePartnerSinup(formData);
      if (!res.error && !res.errormessage && res.status) {
        return alert(res.message);
      }
      return alert(res.errormessage);
    } catch (error) {
      return alert(error.message || error.error || error);
    }
  }, []);

  return (
    <Grid container direction={'row'} sx={{ mt: 2, mb: 6 }} className='partnersignup-forming-cls-adding'>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Formik
          enableReinitialize
          validationSchema={ContactFormSchema}
          onSubmit={handleSubmit}
          initialValues={defaultValuesData}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {({ isValid }) => (
            <Form autoComplete="off" noValidate>
              <Stack spacing={3} sx={{ mt: 2, width: '600px', maxWidth: '600px' }}>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="facilityName"
                      fullWidth
                      placeholder={t("facilityName")}
                      label={t("facilityName")}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="address"
                      fullWidth
                      placeholder={t("establishmentAddress")}
                      label={t("establishmentAddress")}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <Field component={TextField} name="city" fullWidth placeholder="Ville" label="Ville" required />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="postalCode"
                      fullWidth
                      placeholder={t("codePostal")}
                      label={t("codePostal")}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field component={TextField} name="country" fullWidth placeholder={t("country")} label={t("country")} required />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="firstName"
                      fullWidth
                      placeholder={t("firstName")}
                      label={t("firstName")}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field component={TextField} name="name" fullWidth placeholder={t("name")} label={t("name")} required />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="email"
                      fullWidth
                      placeholder={t("email")}
                      label={t("email")}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={PhoneField}
                      name="phone"
                      fullWidth
                      placeholder={t("telephoneNotOptional")}
                      label={t("telephoneNotOptional")}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent={'center'}>
                  <Button size="large" variant="contained" type="submit">
                    {t("register")}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default PartnerSignupForm;
