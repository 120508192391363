/* eslint-disable eqeqeq */
import { Field, Form, Formik } from 'formik';
import { Stack, FormControl, Grid, InputAdornment, IconButton } from '@mui/material';
import { TextField } from 'formik-mui';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import PhoneField from '../form/formik/PhoneField';
import { register } from 'src/services/api/authentication';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Iconify from '../iconify/Iconify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { useTranslation } from 'react-i18next';



const defaultValuesData = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
};

const RegisterForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { setSession, setUser } = useAuth()
    const { t } = useTranslation('translation');
    const ContactFormSchema = Yup.object().shape({
        first_name: Yup.string().required().label(t("firstName")),
        last_name: Yup.string().required().label(t("lastName")),
        email: Yup.string().email().required().label(t("email")),
        password: Yup.string().required().label("password"),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const handleSubmit = useCallback(async (values, actions) => {
        const { first_name, last_name, email, phone, password } = values;
        try {
            const body = {
                first_name,
                last_name,
                email,
                phone,
                password
            };
            actions.setSubmitting(true);
            const res = await register(body);
            if (res.status && res.data) {
                const { token } = res.data;
                setUser(res.data);
                setSession(token, res.data?.id, res.data)
                toast.success(res.message);
                navigate("/", { replace: true })
            } else {
                toast.error(res.message[0] || t("somethingWentWrong"));
            }
            actions.setSubmitting(false)
        } catch (error) {
            actions.setSubmitting(false)
            toast.error(error?.response?.data?.message?.[0] || t("somethingWentWrong"));
        }
    }, []);


    const handleShowPassword = useCallback(() => {
        setShowPassword((show) => !show);
    }, []);

    return (
        <Grid container direction={'row'} sx={{ mt: 2, mb: 6 }} className='register-formcls'>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Formik
                    enableReinitialize
                    validationSchema={ContactFormSchema}
                    onSubmit={handleSubmit}
                    initialValues={defaultValuesData}
                    validateOnBlur={true}
                    validateOnChange={true}
                >
                    {({ errors, isSubmitting }) => (
                        <Form autoComplete="off" noValidate>
                            <Stack spacing={3} sx={{ mt: 2, width: '600px', maxWidth: '600px', '@media (max-width: 650px)': {
                width: '80vw',
              } }}>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            component={TextField}
                                            name="first_name"
                                            fullWidth
                                            placeholder={t("firstName")}
                                            label={t("firstName")}
                                            required
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            component={TextField}
                                            name="last_name"
                                            fullWidth
                                            placeholder={t("lastName")}
                                            label={t("lastName")}
                                            required
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            component={TextField}
                                            name="email"
                                            fullWidth
                                            placeholder={t("email")}
                                            label={t("email")}
                                            required
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            type={showPassword ? 'text' : 'password'}
                                            component={TextField}
                                            name="password"
                                            fullWidth
                                            placeholder={t("password")}
                                            label={t("password")}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword} edge="end">
                                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            type="password"
                                            component={TextField}
                                            name="confirmPassword"
                                            fullWidth
                                            placeholder={t("confirmPassword")}
                                            label={t("confirmPassword")}
                                            required
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            component={PhoneField}
                                            name="phone"
                                            fullWidth
                                            placeholder={t("telephone")}
                                            label={t("telephone")}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} justifyContent={'center'}>
                                    <LoadingButton loading={isSubmitting} size="large" variant="contained" type="submit">
                                        {t("send")}
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default RegisterForm;
