import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import Btn from './Btn';
import { useTranslation } from 'react-i18next';

const Layout = styled('section')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '60px 80px',
  borderBottomLeftRadius: '50px',
  fontFamily: ['TT Norms Pro Regular', 'Helvetica', 'sans-serif'].join(','),
  gap: '40px',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    textAlign: 'left',
    borderBottomLeftRadius: '200px',
  },
}));

function PartnerOverview() {
  const { t } = useTranslation('translation', { keyPrefix: 'partner.section2' });

  return (
    <Box sx={{ bgcolor: "#E8EDFF" }} className='editbookingforming-clsadding-daterang-pcket-viewpso'>
      <Layout>
        <Box>
          <img src={`/assets/images/beach1.jpg`} alt="" style={{ borderRadius: '5rem', width: '50rem', height: "20rem" }} />
        </Box>
        <Box sx={{ maxWidth: '60rem' }}>
          <Typography
            fontSize="40px"
            fontWeight="700"
            fontFamily='"TT Norms Pro Medium",Helvetica,sans-serif!important'
            sx={{ mb: '25px', color: '#06133A' }}
          >
            {t("title")}
          </Typography>
          <Typography
            variant="p"
            fontFamily='"TT Norms Pro Medium",Helvetica,sans-serif!important'
            fontWeight='500'
            fontSize='22px'
            sx={{
              display: 'block',
              color: '#06133A'
            }}>
            {t("subTitle")}
          </Typography>
          <Btn btnText={t("buttonText")} color="#06133A" bgColor="none" borderColor="#06133A" hoverBgColor="#E8EDFF" />
        </Box>
      </Layout>
    </Box>
  );
}

export default PartnerOverview;