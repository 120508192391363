/* eslint-disable default-case */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, Stack, Typography, Link as MLink } from '@mui/material';
import styled from '@emotion/styled';
import seat from '../../../assets/icons/seat.svg';
import doubleSeat from '../../../assets/icons/double_seat.svg';
import twoSeat from '../../../assets/icons/beachbooker_restaurant_dining_table_2_seater.svg';
import fourSeat from '../../../assets/icons/beachbooker_restaurant_dining_table_4_seater.svg';
import sixSeat from '../../../assets/icons/beachbooker_restaurant_dining_table_6_seater.svg';
import park from '../../../assets/icons/park.svg';
import shower from '../../../assets/icons/shower.svg';
import bell from '../../../assets/icons/shower.svg';
import tv from '../../../assets/icons/tv.svg';
import wifi from '../../../assets/icons/wifi.svg';
import copy from '../../../assets/icons/copy.svg';
import globe from '../../../assets/icons/globe.svg';
import facebook from '../../../assets/icons/facebook.svg';
import instagram from '../../../assets/icons/instagram.svg';
import location from '../../../assets/icons/location_outline.svg';
import PlaceBooking from './PlaceBooking';
import { Link } from 'react-router-dom';
import Map from 'src/components/map';
import moment from 'moment';
import Pusher from 'pusher-js';
import { getPlaceDetails } from 'src/services/api/admin.api';
import { useCallback } from 'react';
import { useAuth } from 'src/context/AuthContext';
import { useTranslation } from 'react-i18next';

const DetailLayout = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
  gap: '20px',
  padding: '0px 30px',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderBottomRightRadius: '200px',
  },
}));

const EquipmentLayout = styled(Box)(({ theme }) => ({
  color: 'black',
  display: 'flex',
  gap: '18px',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: '15rem',
  },
}));

const Heading = styled(Typography)({
  color: 'black',
  fontSize: '22px',
  fontWeight: '600',
  marginBottom: '20px',
});

const Item = styled(Typography)({
  color: 'black',
  fontSize: '18px',
  fontWeight: '500',
});

export default function PlaceDetails(props) {
  const { data, id, coordinates, setCoordinates, setDate, date } = props;
  const [beachStats, setBeachStats] = useState(null);
  const [restaurantStats, setRestaurantStats] = useState(null);
  const [descriptions, setDescriptions] = useState({});
  const [currentCoordinateds, setCurrentCoordinates] = useState({});
  const { user } = useAuth();
  const mainT = useTranslation('translation');
  const { t } = useTranslation('translation', { keyPrefix: 'details' });
  const bookingT = useTranslation('translation', { keyPrefix: 'booking' });

  const handleLocationRedirect = () => {
    const myUrl = `https://www.google.com/maps/dir/${currentCoordinateds?.latitude},${currentCoordinateds?.longitude}/${(coordinates || [])[1]
      },${(coordinates || [])[0]}`;
    window.open(myUrl, '_blank');
  };

  useEffect(() => {
    if (data && data._beachStats) {
      setBeachStats(data._beachStats);
    }
    if (data && data._restStats) {
      setRestaurantStats(data._restStats);
    }
  }, [data]);

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
    }
  };

  const setGeolocation = (position) => {
    const { latitude, longitude } = position?.coords || {};
    setCurrentCoordinates({ latitude, longitude });
    return;
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => setGeolocation(position),
      (error) => showError(error)
    );
  }, []);

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address);
    return alert('copied!');
  };

  const getCordinates = () => {
    if (data?.geometry) {
      const cordinates = data.geometry ? JSON.parse(data?.geometry) : '';
      if (cordinates) {
        setCoordinates(cordinates?.coordinates || []);
      }
    }
  };

  const getDescription = () => {
    if (data?.descriptions) {
      const descriptions = JSON.parse(data?.descriptions);
      setDescriptions(descriptions || {});
    }
  };
  useEffect(() => {
    getCordinates();
    getDescription();
  }, [data, id]);

  const getPlaceData = async (idData, dfrom) => {
    try {
      const res = await getPlaceDetails(idData, { dfrom });
      if (res.status && res.data && res.data._beachStats) {
        setBeachStats(res.data._beachStats);
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const pusher = new Pusher('aee380cc21208c12d218', {
      cluster: 'ap2',
    });
    const channel1 = pusher.subscribe('reservation-beach');
    channel1.bind('reservation-beach', function (res) {
      const resData = res.data;
      if (resData && resData.id && resData.date && date) {
        let selectedDate = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        if (moment(resData.date).isSame(selectedDate) && data.beaches.length > 0 && data.beaches[0]?.id == resData.id) {
          getPlaceData(data.id, resData.date);
        }
      }
    });
    return () => {
      pusher.unsubscribe('reservation-beach');
    };
  }, [data, date]);

  const getCheckBookPermission = useCallback(() => {
    if (data && data.beaches && data.beaches.length > 0 && data.beaches[0]?.is_member) {
      return user?.is_member ? true : false;
    } else if (data?.isPartner) {
      return true;
    } else {
      return false;
    }
  }, [data, user]);

  return (
    <DetailLayout className='editbookingforming-clsadding-daterang-pcket-details-layout-cls-adding'>
      <Grid
        container
        sx={{
          display: 'flex',
          direction: 'row',
          '@media (max-width: 900px) ': {
            flexDirection: 'column-reverse',
          },
        }}
        direction="row"
      >
        <Grid xs={12} md={7}>
          <Box sx={{ bgColor: '#000' }}>
            {descriptions?.fr ? (
              <>
                <section
                  className='place-details-first-section new-paragraph-justify-edit'
                  style={{
                    color: 'black',
                    maxWidth: '40rem',
                    mt: '30px',
                  }}
                >
                  <Typography
                    dangerouslySetInnerHTML={{ __html: descriptions?.fr }}
                    sx={{
                      fontFamily: 'TT Norms Pro Regular, Helvetica, sans-serif !important',
                      fontSize: '20px !important',
                    }}
                  ></Typography>
                  <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
                </section>
              </>
            ) : null}

            {data?.openingPeriod ? (
              <>
                <section className='place-details-second-section'>
                  <Heading
                    sx={{
                      fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                    }}
                  >
                    {t('openingPeriod')}
                  </Heading>
                  <Item dangerouslySetInnerHTML={{ __html: data?.openingPeriod }}></Item>
                </section>
                <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
              </>
            ) : null}

            {data?.pricingText ? (
              <>
                <section className='place-details-third-section'>
                  <Heading
                    sx={{
                      fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                    }}
                  >
                    {t('price')}
                  </Heading>
                  <Box
                    color="black"
                    fontSize="18px"
                    dangerouslySetInnerHTML={{ __html: data?.pricingText }}
                  ></Box>
                </section>
                <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
              </>
            ) : null}

            {beachStats &&
              beachStats.double_sunbed &&
              beachStats.sunbed &&
              (beachStats.double_sunbed.free > 0 || beachStats.sunbed.free > 0) && (
                <section className='place-details-four-section'>
                  <Heading
                    sx={{
                      fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                    }}
                  >
                    {t('availableSeats')}
                  </Heading>
                  <Stack direction="row" spacing={1}>
                    {beachStats && beachStats.sunbed && beachStats.sunbed.free > 0 && (
                      <Stack item>
                        <Box
                          sx={{
                            width: 'fit-content',
                            border: '2px solid #EAEDF8',
                            borderRadius: '20px',
                            p: '18px 80px 18px 20px',
                          }}
                        >
                          <img src={seat} alt="seat" />
                          <Typography fontSize="17px" color="black" fontWeight="600" mt="15px">
                            {beachStats.sunbed.free} {mainT.t('deckchairs')}
                          </Typography>
                          <Typography fontSize="14px" color="black">
                            {t('on')} {beachStats.sunbed.total} {t('inTotal')}
                          </Typography>
                        </Box>
                      </Stack>
                    )}
                    {beachStats && beachStats.double_sunbed && beachStats.double_sunbed.free > 0 && (
                      <Stack item>
                        <Box
                          sx={{
                            width: 'fit-content',
                            border: '2px solid #EAEDF8',
                            borderRadius: '20px',
                            p: '18px 80px 18px 20px',
                          }}
                        >
                          <img src={doubleSeat} alt="seat" />
                          <Typography fontSize="17px" color="black" fontWeight="600" mt="15px">
                            {beachStats.double_sunbed.free} {mainT.t('beds')}
                          </Typography>
                          <Typography fontSize="14px" color="black">
                            {t('on')} {beachStats.double_sunbed.total} {t('inTotal')}
                          </Typography>
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </section>
              )}

            {(data?.restaurants || []).length > 0 && restaurantStats && (
              <>
                <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
                <section className='place-details-four-section'>
                  <Heading
                    sx={{
                      fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                    }}
                  >
                    {t('availableResSeats')}
                  </Heading>
                  <Stack direction="row" spacing={1}>
                    {restaurantStats && restaurantStats.two_seat && restaurantStats.two_seat.free > 0 && (
                      <Stack item>
                        <Box
                          sx={{
                            width: 'fit-content',
                            minWidth: "250px",
                            border: '2px solid #EAEDF8',
                            borderRadius: '20px',
                            p: '18px 20px 18px 20px',
                          }}
                        >
                          <img width={"40px"} height={"40px"} src={twoSeat} alt="seat" />
                          <Typography fontSize="17px" color="black" fontWeight="600" mt="15px">
                            {/* {restaurantStats.two_seat.free}  */}
                            {mainT.t('twoSeat')}
                          </Typography>
                          {/* <Typography fontSize="14px" color="black">
                            {t('on')} {restaurantStats.two_seat.total} {t('inTotalRest')}
                          </Typography> */}

                          {data?.restaurants && data?.restaurants.length > 0 && (
                            <>
                              <Typography fontSize="14px" color="black">
                                {t('lunch')} : {data?.restaurants[0]?.available?.lunch?.two_seat} places
                              </Typography>
                              <Typography fontSize="14px" color="black">
                                {t('dinner')} :  {data?.restaurants[0]?.available?.dinner?.two_seat} places
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Stack>
                    )}
                    {restaurantStats && restaurantStats.four_seat && restaurantStats.four_seat.free > 0 && (
                      <Stack item>
                        <Box
                          sx={{
                            width: 'fit-content',
                            minWidth: "250px",
                            border: '2px solid #EAEDF8',
                            borderRadius: '20px',
                            p: '18px 20px 18px 20px',
                          }}
                        >
                          <img width={"40px"} height={"40px"} src={fourSeat} alt="seat" />
                          <Typography fontSize="17px" color="black" fontWeight="600" mt="15px">
                            {mainT.t('fourSeat')}
                          </Typography>
                          {/* <Typography fontSize="14px" color="black">
                            {t('on')} {restaurantStats.four_seat.total} {t('inTotalRest')}
                          </Typography> */}
                          {data?.restaurants && data?.restaurants.length > 0 && (
                            <Stack direction={"column"}>
                              <Typography fontSize="14px" color="black">
                                {t('lunch')} : {data?.restaurants[0]?.available?.lunch?.four_seat} places
                              </Typography>
                              <Typography fontSize="14px" color="black">
                                {t('dinner')} :  {data?.restaurants[0]?.available?.dinner?.four_seat} places
                              </Typography>
                            </Stack>
                          )}
                        </Box>
                      </Stack>
                    )}
                    {restaurantStats && restaurantStats.six_seat && restaurantStats.six_seat.free > 0 && (
                      <Stack item>
                        <Box
                          sx={{
                            width: 'fit-content',
                            minWidth: "250px",
                            border: '2px solid #EAEDF8',
                            borderRadius: '20px',
                            p: '18px 20px 18px 20px',
                          }}
                        >
                          <img width={"40px"} height={"40px"} src={sixSeat} alt="seat" />
                          <Typography fontSize="17px" color="black" fontWeight="600" mt="15px">
                            {/* {restaurantStats.six_seat.free}  */}
                            {mainT.t('sixSeat')}
                          </Typography>
                          {/* <Typography fontSize="14px" color="black">
                            {t('on')} {restaurantStats.six_seat.total} {t('inTotalRest')}
                          </Typography> */}

                          {data?.restaurants && data?.restaurants.length > 0 && (
                            <Stack direction={"column"}>
                              <Typography fontSize="14px" color="black">
                                {t('lunch')} : {data?.restaurants[0]?.available?.lunch?.six_seat} places
                              </Typography>
                              <Typography fontSize="14px" color="black">
                                {t('dinner')} :  {data?.restaurants[0]?.available?.dinner?.six_seat} places
                              </Typography>
                            </Stack>
                          )}
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </section>
              </>
            )}

            <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
            {(data?.restaurants || []).length > 0 && data?.restaurants[0]?.pdf_file && (
              <>
                <section className='place-details-five-section'>
                  <Heading
                    sx={{
                      fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                    }}
                  >
                    {mainT.t("restaurantPackages")}
                  </Heading>
                  <EquipmentLayout>
                    <MLink target="_blank" href={data?.restaurants[0]?.pdf_file}>{bookingT.t("restaurantMenu")}</MLink>
                  </EquipmentLayout>
                </section>
                <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
              </>
            )}
            {data?.business_link && data?.business_link && (
              <>
                <section className='place-details-five-section'>
                  <Heading
                    sx={{
                      fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                      cursor: "pointer"
                    }}
                  >
                    {mainT.t("businessLink")}
                  </Heading>
                  <EquipmentLayout>
                    <MLink target="_blank" href={data?.business_link}>{mainT.t("businessLinkText")}</MLink>
                  </EquipmentLayout>
                </section>
                <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
              </>
            )}
            {(data?.place_services || []).length ? (
              <>
                <section className='place-details-six-section'>
                  <Heading
                    sx={{
                      fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                    }}
                  >
                    {mainT.t('services')}
                  </Heading>
                  <EquipmentLayout>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                      {(data?.place_services || []).map((data) => {
                        const { icon, name } = data.services;
                        const imageNameObj = { parking: park, wifi: wifi, bell: bell, tv: tv, shower: shower };
                        const imageIcon = imageNameObj[icon];
                        return (
                          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <img src={imageIcon} alt="" />
                            <Item>{name}</Item>
                          </Box>
                        );
                      })}
                    </Box>
                  </EquipmentLayout>
                </section>
                <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />
              </>
            ) : null}

            <section className='place-details-seven-section'>
              <Heading
                sx={{
                  fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important',
                  fontWeight: 'bolder',
                  fontSize: '25px !important',
                }}
              >
                {mainT.t('location')}
              </Heading>
              <Item>{data?.addressLine1}</Item>
              <Item>{data?.addressLine2}</Item>
              <Item>
                {data?.zip} {data?.city.name}
              </Item>
              <Button
                sx={{ mb: '30px', '&:hover': { backgroundColor: '#E8EDFF' }, borderRadius: '15px', mt: '15px' }}
                onClick={() => handleCopyAddress(`${data?.addressLine1}, ${data?.city.name}, ${data?.zip} `)}
              >
                <img src={copy} alt="" />
                <Typography ml="10px" fontSize="17px" color="black">
                  {t('copyAddress')}
                </Typography>
              </Button>

              <div style={{ marginTop: -40 }} />
              <Button
                sx={{ mb: '30px', '&:hover': { backgroundColor: '#E8EDFF' }, borderRadius: '15px', mt: '15px' }}
                onClick={handleLocationRedirect}
              >
                <img src={location} alt="" />
                <Typography ml="10px" fontSize="17px" color="black">
                  {t('getDirection')}
                </Typography>
              </Button>
              {(coordinates || []).length && (
                <Map
                  latitude={(coordinates || [])[0]}
                  longitude={(coordinates || [])[1]}
                  sx={{
                    height: '240px',
                    borderRadius: '20px',
                  }}
                />
              )}
            </section>
            <hr style={{ border: '1px solid #EAEDF8', margin: '30px 0' }} />

            {data?.websiteUrl || data?.facebookUrl || data?.instagramUrl || data?.phone ? (
              <>
                <section className='contacts-names-all'>
                  <Heading
                    sx={{
                      fontWeight: 'bolder',
                      fontSize: '25px !important',
                      fontFamily: 'TT Norms Pro Medium ,Helvetica, sans-serif !important;',
                    }}
                  >
                    Contacts
                  </Heading>
                  {data && (
                    <Box sx={{ display: 'flex', gap: '30px' }}>
                      <a href={data?.websiteUrl} hidden={!data?.websiteUrl} target="_blank" rel="noreferrer">
                        <img src={globe} alt="globe" style={{ width: '40px' }} />
                      </a>
                      <a href={data?.facebookUrl} hidden={!data?.facebookUrl} target="_blank" rel="noreferrer">
                        <img src={facebook} alt="facebook" style={{ width: '40px' }} />
                      </a>
                      <a href={data?.instagramUrl} hidden={!data?.instagramUrl} target="_blank" rel="noreferrer">
                        <img src={instagram} alt="instagram" style={{ width: '40px' }} />
                      </a>
                    </Box>
                  )}
                  {data?.phone && (
                    <Link to={`tel:${data?.phone}`}>
                      <Button
                        sx={{
                          color: 'black',
                          fontSize: '17px',
                          fontWeight: '500',
                          border: '3px solid black',
                          borderRadius: '15px',
                          p: '6px 25px',
                          mt: '30px',
                          '&:hover': {
                            backgroundColor: '#E8EDFF',
                          },
                        }}
                      >
                        {mainT.t('contacterEtablissement')}
                      </Button>
                    </Link>
                  )}
                </section>
              </>
            ) : null}
          </Box>
        </Grid>
        <Grid xs={12} md={5} className='grid-alltmpl-cls-adding-here'>
          {getCheckBookPermission() ? (
            <PlaceBooking
              className='grid-alltmpl-cls-adding-here-placeholding'
              date={date}
              setDate={setDate}
              beachesStats={beachStats}
              priceLists={data?.price_lists}
              startDate={data?.beaches[0]?.start_date}
              endDate={data?.beaches[0]?.end_date}
              noBookingText={data?.no_booking_text}
            />
          ) : (
            data?.no_booking_text && (
              <Box className="mb-4 only-not-partner grid-alltmpl-cls-adding-here-no-booking">
                {data?.no_booking_text}
                {/* "Hey ! Vous aimez {data?.names?.fr || ''} ? Cette plage ne fait pas encore partie de nos partenaires et n'est pas disponible à la réservation pour le moment ! Et si vous leur parliez de nous pour qu'elle nous rejoigne ? Ensemble, faisons grandir Beach Booker ! ❤️", */}
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </DetailLayout>
  );
}
