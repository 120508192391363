import { Button, Typography } from '@mui/material';
import React from 'react';
import back from '../../../../assets/icons/back.svg';
import { useTranslation } from 'react-i18next';

export default function PaymentBackButton({ prevTab, title = null }) {
    const { t } = useTranslation('translation');

    return (
        <Button
            className='paymentbeachiconimg-cls-adding'
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                '&:hover': { backgroundColor: '#E8EDFF' },
                p: '2px 5px',
                borderRadius: '10px',
                mb: '15px',
            }}
            onClick={prevTab}
        >
            <img src={back} alt="" style={{ width: '14px' }} />
            <Typography
                color="#06133a"
                fontSize="15px"
                fontFamily={'"TT Norms Pro Regular",Helvetica,sans-serif!important'}
            >
                {title ? t("backUpdated") : t("back")}
            </Typography>
        </Button>
    );
}
