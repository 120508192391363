import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import umbrella from '../../../assets/icons/umbrella.svg';
import PaymentBackButton from 'src/pages/Booking/sections/shared/PaymentBackButton';

const HeroLayout = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '20px', // Adjusted padding for mobile and tablet
}));

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '25rem',
  display: 'flex',
  gap: '10px',
  '@media (max-width: 650px)': {
    width: '100%',
    flexDirection: 'column'
  },
  // '@media (max-width: 900px)':{
  //   width: '100%'
  // }
}));

const ImgContainer1 = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '10px', // Adjusted margin for mobile and tablet
  '@media (max-width: 650px)': {
    width: '100%',
    height: '100%'
  },


}));

const ImgContainer2 = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 850px) ': {
    height: '100%'
  },
}));

const SubImgContainer1 = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  width: '100%',

  height: '100%',
  '@media (min-width: 650px) and (max-width: 900px) ': {
    flexDirection: 'column',
  },
}));

const SubImgContainer2 = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

export default function PlaceHero({ data = null, params }) {
  const { names = null, image = [], isPartner } = data;
  const name = names ? names : '';
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  return (
    <HeroLayout className='editbookingforming-clsadding-daterang-pcket-hero-layout'>
      {params && params?.isAround == 'true' && (
        <PaymentBackButton title="test" prevTab={() => navigate(`/finder?city=around_me&dfrom=${params?.dfrom}&dend=${params?.dend}&is_restaurant=false`)} />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '10px' }} className='editbookingforming-clsadding-daterang-pcket-hero-layout-placehero'>
        <Typography
          className='editbookingforming-clsadding-daterang-pcket-hero-layout-typlo'
          fontSize={{ xs: '30px', md: '40px' }} // Adjusted font size for mobile and tablet
          fontWeight="700"
          color="#06133A"
          fontFamily="TT Norms Pro Medium, Helvetica, sans-serif !important"
        >
          {name?.fr}
        </Typography>
        {isPartner && (
          <>
            <img src={umbrella} alt="umbrella" style={{ margin: '0 8px 0 15px' }} className='editbookingforming-clsadding-daterang-pcket-hero-layoutimgo' />
            <Typography
              className='editbookingforming-clsadding-daterang-pcket-hero-layout-tyko'
              color="#06133A"
              fontSize="10px"
              fontWeight="600"
              padding="4px 5px"
              borderRadius="5px"
              width="fit-content"
              bgcolor="#FCEEED"
            >
              {t('partnerText')}
            </Typography>
          </>
        )}
      </Box>
      <Container className='editbookingforming-clsadding-daterang-pcket-hero-layoutcontiner'>
        {image && (image || [])[0] && (
          <ImgContainer1 className='editbookingforming-clsadding-daterang-pcket-hero-layoutimgconts'>
            <Box
              className='editbookingforming-clsadding-daterang-pcket-hero-layoutboxingcontetsiop'
              sx={{
                backgroundImage: `url(${image[0].url})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: '100% 100%',
                width: '100%',
                height: '100%',
                borderRadius: '20px',
              }}
            ></Box>
          </ImgContainer1>
        )}
        {image && (image || [])[1] && (
          <ImgContainer2 className='editbookingforming-clsadding-daterang-pcket-hero-layoutimgconts2'>
            <SubImgContainer1 className='editbookingforming-clsadding-daterang-pcket-hero-layoutimgconts2-sub'>
              <Box
                className='editbookingforming-clsadding-daterang-pcket-hero-layout-secondboxing'
                sx={{
                  backgroundImage: `url(${image[1].url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: '100% 100%',
                  width: '100%',
                  height: '100%',
                  borderRadius: '20px',
                }}
              ></Box>
              {image && (image || [])[2] && (
                <Box
                  className='editbookingforming-clsadding-daterang-pcket-hero-layout-fiveboxing'
                  sx={{
                    backgroundImage: `url(${image[2].url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: '100% 100%',
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                ></Box>
              )}
            </SubImgContainer1>
            {image && (image || [])[3] && (
              <SubImgContainer2 className='editbookingforming-clsadding-daterang-pcket-hero-layoutimgconts23'>
                <Box
                  className='editbookingforming-clsadding-daterang-pcket-hero-layout-sixboxing'
                  sx={{
                    backgroundImage: `url(${image[3].url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: '100% 100%',
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                ></Box>
              </SubImgContainer2>
            )}
          </ImgContainer2>
        )}
      </Container>
    </HeroLayout>
  );
}
