import { useCallback, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, FormControl, Grid, Stack, Typography } from '@mui/material';
import { object, ref, string } from 'yup';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { updateNewPassword } from 'src/services/api/authentication';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SetNewPassword() {
    const SetNewPasswordSchema = object().shape({
        password: string().required('Password is required'),
        confirmPassword: string().when('password', (password, field) =>
            password ? field.required("Confirm password is required").oneOf([ref('password'), null], "Password and Confirm Password must match") : field
        ),
    });
    const { token = '' } = useParams();

    const [sent, setSent] = useState(false);

    const { t } = useTranslation('translation');

    const defaultValuesData = {
        password: '',
        confirmPassword: ''
    };

    const handleSubmit = useCallback(
        async (values, actions) => {
            try {
                actions.setSubmitting(true);

                await updateNewPassword({ ...values, token })
                    .then(async (res) => {
                        if (res && res.status && res.data) {
                            toast.success(res.message);
                            setSent(true)
                        } else {
                            toast.error(res.message);
                        }
                    }).catch((err) => {
                        actions.setSubmitting(false);
                        if (err) {
                            toast.error(t('somethingWentWrong'));
                        }
                    });
                actions.setSubmitting(false);
            } catch (error) {
                actions.setSubmitting(false);
                toast.error(error?.response?.data?.message?.[0] || t('somethingWentWrong'));
            }
        },
        [t, token]
    );

    return (

        <RootStyle>
            <Container>
                <Box sx={{ maxWidth: 480, mx: 'auto', justifyContent: "center" }}>
                    {!sent ? (
                        <>
                            <Typography variant="h4" sx={{ color: 'text.secondary', textAlign: "center", mb: 1 }}>
                                {t("setYourPassword")}
                            </Typography>

                            <Grid container direction={'row'} sx={{ mt: 0, mb: 0 }} className='login-formcls'>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Formik
                                        enableReinitialize
                                        validationSchema={SetNewPasswordSchema}
                                        onSubmit={handleSubmit}
                                        initialValues={defaultValuesData}
                                        validateOnBlur={true}
                                        validateOnChange={true}
                                    >
                                        {({ errors }) => (
                                            <Form autoComplete="off" noValidate>
                                                <Stack
                                                    spacing={3}
                                                    sx={{
                                                        mt: 2,
                                                        width: '600px',
                                                        maxWidth: '600px',
                                                        '@media (max-width: 650px) ': {
                                                            width: '80vw',
                                                        },
                                                    }}
                                                >
                                                    <Stack direction="row">
                                                        <FormControl fullWidth>
                                                            <Field
                                                                component={TextField}
                                                                type="password"
                                                                name="password"
                                                                fullWidth
                                                                placeholder={t('password')}
                                                                label={t('password')}
                                                                required
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack direction="row">
                                                        <FormControl fullWidth>
                                                            <Field
                                                                component={TextField}
                                                                type="password"
                                                                name="confirmPassword"
                                                                fullWidth
                                                                placeholder={t('confirmPassword')}
                                                                label={t('confirmPassword')}
                                                                required
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} justifyContent={'center'}>
                                                        <Button size="large" variant="contained" type="submit">
                                                            {t('confirm')}
                                                        </Button>
                                                    </Stack>

                                                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                                                        <Button
                                                            fullWidth
                                                            size="small"
                                                            variant="text"
                                                            component={RouterLink}
                                                            to={"/login"}
                                                            sx={{ mt: 1, width: "15px" }}
                                                        >
                                                            {t("backButton")}
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Box sx={{ textAlign: 'center', justifyContent: "center" }}>
                            <Typography variant="h3" sx={{ color: 'text.secondary' }}>
                                {t("setYourPasswordSuccess")}
                            </Typography>
                            <Button
                                size="large"
                                variant="contained"
                                component={RouterLink}
                                to={"/login"}
                                sx={{ mt: 5 }}
                            >
                                {t("backButton")}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </RootStyle>
    );
}
