import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';


function DeleteDialog({
    onClose,
    handleClickDelete,
    title = "Suppression",
    message = "Merci de sélectionnez une seule zone ou objet avant de spécifier son identifiant."
}) {
    const { t } = useTranslation('translation');

    return (
        <Dialog maxWidth="sm" open={true} className='delete-dialog-cls-adding'>
            <DialogTitle sx={{color: "#637381", fontWeight:700}}>{title}</DialogTitle>
            <DialogContent sx={{color: "#637381"}}>
                {message}
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={3} justifyContent={"flex-end"}>
                    <Button onClick={onClose}>{t("no")}</Button>
                    <Button onClick={handleClickDelete} variant="contained">{t("yes")}</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDialog;

DeleteDialog.propTypes = {
    onClose: PropTypes.func,
    handleClickDelete: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
};
