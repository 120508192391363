import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BeachSearchComponent from 'src/components/shared/BeachSearchComponent';
import moment from 'moment';
import search from '../../../assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import DatePickerHeaderComponent from 'src/components/shared/DatePickerHeaderComponent';
export default function SearchFilter() {
  const navigate = useNavigate();
  const [city, setCity] = useState('');

  const [selectedValue, setSelectedValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  useEffect(() => {
    let latestDate = params.dfrom ? moment(params.dfrom, "YYYY-MM-DD").toDate() : moment(new Date()).toDate()
    let latestEndDate = params.dend ? moment(params.dend, "YYYY-MM-DD").toDate() : moment(new Date()).toDate()
    setSelectedValue({
      startDate: latestDate,
      endDate: latestEndDate,
      key: 'selection',
    });
  }, [params])

  const [searchType, setSearchType] = useState('beach');
  const { t } = useTranslation('translation');

  const handleOnchangeCustomer = useCallback((e, value) => {
    setCity(value);
  }, []);

  const handleChangeType = useCallback((e) => {
    setSearchType(e.target.value);
  }, []);

  const handleNavigate = () => {
    const startDate = selectedValue?.startDate ? moment(selectedValue?.startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
    const endDate = selectedValue?.endDate ? moment(selectedValue?.endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');

    //const latestDate = date ? moment(date).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
    if (city && city.itemType === 'city') {
      navigate(`/finder?city=${city.key}&dfrom=${startDate}&dend=${endDate}&is_restaurant=${searchType === 'beach' ? false : true}`, {
        replace: true,
      });
    } else {
      if (city) {
        if (searchType === 'beach') {
          navigate(`/place/${city.id}?dfrom=${startDate}&dend=${endDate}&zoom=7`, { replace: true });
        } else {
          if (city) {
            if (searchType === 'beach') {
              navigate(`/place/${city.id}?dfrom=${startDate}&dend=${endDate}&zoom=7&defaultRestaurant=${false}`, { replace: true });
            } else {
              navigate(`/place/${city.id}/book?dfrom=${startDate}&dend=${endDate}&zoom=7&defaultRestaurant=${true}`, {
                replace: true,
              });
            }
          } else {
            navigate(
              `/finder?dfrom=${startDate}&dend=${endDate}&is_restaurant=${searchType === 'beach' ? false : true}&defaultRestaurant=${searchType === 'beach' ? false : true
              }`,
              { replace: true }
            );
          }
        }
      } else {
        navigate(`/finder?dfrom=${startDate}&dend=${endDate}&is_restaurant=${searchType === 'beach' ? false : true}`, {
          replace: true,
        });
      }
    }
  };

  const handleChangeDate = React.useCallback(
    (e) => {
      if (e.startDate && e.endDate) {
        setSelectedValue(e);
      }
    },
    []
  );

  return (
    <Grid
      className='seacrhfilter-cls-adding'
      gap="1.5rem"
      container
      direction={'row'}
      justifyContent={'center'}
      sx={{
        display: { xs: 'none', sm: "none", md: 'none', lg: 'flex' },
        '@media (max-width: 900px)': {
          width: '100%',
          //   spacing:1
        },
      }}
    >
      <Grid
        className='seacrhfilters-cls-adding'
        item
        sx={{
          '@media (min-width: 778px) and (max-width: 900px)': {
            width: '30%',
          },
          '@media (max-width: 778px)': {
            width: '100%',
          },
        }}
      >
        <FormControl fullWidth className='seacrhfilter-cls-addingform'>
          <Select
            className='seacrhfilter-cls-adding-select'
            autoWidth={false}
            sx={{
              background: '#fff',
              border: 'none',
              height: '55px',
              borderRadius: '19px',
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  fontSize: '16px',
                  fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                  color: '#06133a',
                  '& .MuiMenuItem-root': {
                    padding: 2,
                  },
                },
              },
              variant: 'menu',
              transformOrigin: { vertical: 'top', horizontal: 'center' },
              getContentAnchorEl: null,
              disableScrollLock: true,
            }}
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchType}
            onChange={handleChangeType}
            shrink={false}
          >
            <MenuItem sx={{ height: '30px' }} disableGutters={true} dense={true} value={'beach'} className="seacrhfilter-cls-adding-selectsds">
              {t('beach')}
            </MenuItem>
            <MenuItem sx={{ height: '30px' }} dense={true} value={'restaurant'} className="seacrhfilter-cls-adding-restyuio">
              {t('restaurant')}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        className='seacrhfilter-cls-addinggrid'
        width="30%"
        item
        sx={{
          '@media (min-width: 778px) and (max-width: 900px)': {
            width: '50%',
          },
          '@media (max-width: 778px)': {
            width: '100%',
            marginTop: '5px',
          },
        }}
      >
        <BeachSearchComponent
          className='beachofseacrhfilter-cls-adding'
          label={t('or')}
          shrink={false}
          searchType={searchType}
          option={{ itemType: 'city', name: 'Around Me', key: 'around_me' }}
          handleOnchangeCustomer={handleOnchangeCustomer}
          style={{
            '.MuiOutlinedInput-notchedOutline': {
              borderRadius: '13px',
              borderColor: 'transparent',
            },
            '.MuiAutocomplete-inputRoot': {
              height: '55px',
              border: '2px solid #eaedf8',
              borderRadius: '19px',
            },
            '.MuiInputBase-root': {
              height: '55px',
            },
            '.MuiFormLabel-root': {
              marginTop: '-10px',
            },
            '.MuiInputBase-input': {
              marginTop: '-17px',
            },
          }}
        />
      </Grid>
      <Grid
        className='grid-offingseacrhfilter-cls-adding'
        item
        sx={{
          position: 'relative',
          '@media (min-width: 778px) and (max-width: 900px)': {
            width: '30%',
          },
          '@media (max-width: 778px)': {
            width: '100%',
            marginTop: '5px',
          },
        }}
      >
        <FormControl size="small" sx={{ width: '100%' }} className='formcontroling-select-cls-adding'>
          <DatePickerHeaderComponent
            setDate={setSelectedValue}
            handleChangeDate={handleChangeDate}
            defaultValue={selectedValue}
            className={'landing-calendar'}
            shrink={false}
            style={{
              background: '#fff',
              borderRadius: '19px',
              '.MuiInputBase-input': {
                marginTop: '10px',
              },
              '.MuiInputBase-root': {
                height: '55px',
              },
              '.MuiFormLabel-root': {
                marginTop: '-5px',
              },
            }}
            sx={{ width: '100%' }}
          />
        </FormControl>
      </Grid>
      <Grid
        className='seacrhfilter-cls-adding-uiio'
        item
        sx={{
          justifyContent: 'center',
          '@media (min-width: 778px) and (max-width: 900px)': {
            width: '10%',
          },
          '@media (max-width: 778px)': {
            width: '100%',
          },
        }}
      >
        <Box
          className="searchborderwidth-cls-adding"
          sx={{
            // borderColor: '#EAEDF8',
            // borderStyle: 'solid',
            borderLeftWidth: '2px',
            borderRightWidth: '0',
            borderTopWidth: '0',
            borderBottomWidth: '0',
            p: '6px',
            borderRadius: '13px',
          }}
        >
          <Box
            className="searchborderwidth-cls-adding-handle"
            onClick={handleNavigate}
            component="button"
            sx={{
              cursor: 'pointer',
              bgcolor: '#ff8478',
              p: '8px',
              border: 'none',
              outline: 'none',
              borderRadius: '13px',
              display: 'flex',
              gap: 2,
              margin: 'auto',
              '&:hover': {
                bgcolor: '#E55446',
              },
            }}
          >
            <Box component="img" src={search} width="25px" className="searchborderwidth-cls-component-cls-adding"></Box>
            <Typography
              sx={{
                '@media (min-width: 778px)': {
                  display: 'none',
                },
                '@media (max-width: 778px)': {
                  display: 'block',
                },
              }}
            >
              Search
            </Typography>
          </Box>
        </Box>
        {/* <Button
                    size="small"
                    variant="contained"
                    startIcon={<SearchIcon size="large" sx={{ width: '20px', height: '20px' }} />}
                    sx={{
                        height: "48px",
                        border: 'none',
                        borderRadius: '14px',
                        fontSize: '14px',
                        pt: '10px',
                        pb: '10px',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
                    }}
                    onClick={handleNavigate}
                >
                    Rechercher
                </Button> */}
      </Grid>
    </Grid>
  );
}

SearchFilter.propTypes = {
  onOpenNav: PropTypes.func,
};
