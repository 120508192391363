import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


export default function SearchNotFound({ searchQuery = '', ...other }) {
    const { t } = useTranslation('translation');

    return (
        <Paper {...other} className='searchnotfound-cls-adding'>
            <Typography color={"#637381"} gutterBottom align="center" variant="subtitle1">
                {t("dataNotFound")}
            </Typography>
            {searchQuery && (<Typography color={"#637381"} variant="body2" align="center">
                {t("noResultFoundFor")} &nbsp;
                <strong>&quot;{searchQuery}&quot;</strong>. {t("typeOtherWord")}
            </Typography>)}
        </Paper>
    );
}

SearchNotFound.propTypes = {
    searchQuery: PropTypes.string,
};

