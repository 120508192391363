import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TypographyWithLine from 'src/components/shared/TypographyWithLine';
import PartnerBeachSlider from 'src/components/home/PartnerBeachSlider';
import { useTranslation } from 'react-i18next';

function ProductPartnerBeach() {
  const { t } = useTranslation('translation', { keyPrefix: 'homePage.partner' });
  return (
    <Box component="section" sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.contrastText' }} className="finder-list-location-xdawd-beaching-offing-hash-taled">
      <Container sx={{ mt: 10, mb: 10, display: 'flex', position: 'relative', padding: 6 }} maxWidth="lg" className="finder-list-location-xdawd-lgadasd">
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <TypographyWithLine
              text={t('title')}
              sx={{
                fontSize: { xs: '24px', sm: '28px', md: '40px' },
                whiteSpace: 'nowrap',

              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <PartnerBeachSlider />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductPartnerBeach;
