/* eslint-disable array-callback-return */
import { Helmet } from 'react-helmet-async';
import PartnerHero from './sections/PartnerHero';
import PartnerOverview from './sections/PartnerOverview';
import PartnerBeachBooker from './sections/PartnerBeachBooker';
import PartnerRestaurant from './sections/PartnerRestaurant';
import PartnerContact from './sections/PartnerContact';

export default function PartnerPage() {
    return (
        <>
            <Helmet className='editbookingforming-clsadding-daterang-pcket-titlekho'>
                <title> Beach Booker | Beach Booker </title>
            </Helmet>
            <PartnerHero className='editbookingforming-clsadding-daterang-pcket-hero-ftg' />
            <PartnerOverview className='editbookingforming-clsadding-daterang-pcket-overview' />
            <PartnerBeachBooker className='editbookingforming-clsadding-daterang-pcket-boker' />
            <PartnerRestaurant className='editbookingforming-clsadding-daterang-pcket-resturant' />
            <PartnerContact className='editbookingforming-clsadding-daterang-pcket-contact' />
        </>
    );
}