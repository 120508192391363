import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';

export default function Map(props) {
  const { longitude, latitude, sx } = props;
  const customIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
  });
  return (
    <MapContainer style={sx} center={[longitude, latitude]} zoom={13} scrollWheelZoom={true} className='mappingcls-adding-here'>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[longitude, latitude]} icon={customIcon}>        
      </Marker>
    </MapContainer>
  );
}
