import api from './api';

const API_PREFIX = '/api/b2c';

const login = async (body) => {
    const url = `${API_PREFIX}/login`;
    const response = await api.post(url, body);
    return response;
};

const forgotPassword = async (body) => {
    const url = `${API_PREFIX}/forgot-password`;
    const response = await api.post(url, body);
    return response;
};

const updateNewPassword = async (body) => {
    const url = `${API_PREFIX}/set-password`;
    const response = await api.post(url, body);
    return response;
};

const register = async (body) => {
    const url = `${API_PREFIX}/signup`;
    const response = await api.post(url, body);
    return response;
};

const me = async () => {
    const url = `${API_PREFIX}/me`;
    const response = await api.get(url);
    return response;
}

const myBooking = async () => {
    const url = `${API_PREFIX}/me`;
    const response = await api.get(url);
    return response;
}

const reservationTableDetail = async (params) => {
    const url = `${API_PREFIX}/get-reservation-detail`;
    console.log(params, 'params');
    const response = await api.get(url, { params });
    return response;
};

const reservationDetail = async (id) => {
    const url = `${API_PREFIX}/reservation-detail/${id}`;
    const response = await api.get(url);
    return response;
};

const updateBeachReservation = async (id, body) => {
    const response = await api.put(`api/update-reservation-beach/${id}`, body);
    return response;
};

const deleteReservation = async (id, isResDeleted) => {
    const response = await api.delete(`${API_PREFIX}/delete-reservation/${id}/${isResDeleted ? 'restaurant' : 'beach'}`);
    return response;
};

const editBooking = async (id) => {
    const response = await api.get(`${API_PREFIX}/delete-reservation/${id}`);
    return response;
};

export {
    updateNewPassword,
    forgotPassword,
    editBooking,
    login,
    register,
    me,
    myBooking,
    reservationTableDetail,
    updateBeachReservation,
    deleteReservation,
    reservationDetail
};
