import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TypographyWithLine from 'src/components/shared/TypographyWithLine';
import { Button, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moreIcon from 'src/assets/icons/more.png';
import { useTranslation } from 'react-i18next';

function ProductPartner() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'homePage.becomePartner' });

  return (
    <Box component="section" sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.contrastText' }} className="finder-list-location-xdawd-product-parnter-cls-adding">
      <Container sx={{ mt: 10, mb: 10, display: 'flex', position: 'relative' }} maxWidth="100%" className='contiaint-cldadafa'>
        <Grid container spacing={2} justifyContent={'space-evenly'} className='contiaint-cldadafaevenly'>
          <Grid item className='contiaint-cldadafaevenly-item'>
            <TypographyWithLine text={t('title')} sx={{ fontSize: '40px' }} className="typo-all-howto-do" />
            <Typography
              className="typo-all-howto-do-ex"
              color="secondary.main"
              fontWeight="700"
              sx={{
                mt: 2,
                mb: 0.5,
                color: '#ed7b74',
                fontSize: '2rem!important',
                lineHeight: 1,
                fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
              }}
            >
              {t('subTitle')}
            </Typography>
            <Typography color="common.black" fontSize="1.1rem" className="typo-all-howto-do-vb">
              {t('description')}
            </Typography>
            <Button
              className='contiaint-cldadafa-lusing'
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '2px solid #fff',
                p: '6px 30px',
                borderRadius: '15px',
                bgcolor: '#1A1919',
                m: '30px auto',
              }}
              onClick={() => navigate('/contact')}
            >
              <Box component="img" src={moreIcon}></Box>
              <Divider
                orientation="vertical"
                color="#fff"
                sx={{ fontSize: '25px', height: '20px', width: '3px', mx: 1.5 }}
              />
              <TypographyWithLine
                text={t('buttonText')}
                sx={{
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#fff',
                  '&:before': {
                    width: '0',
                  },
                }}
              />
            </Button>
          </Grid>
          <Grid item className='grid-img-full-width'>
            <Box
              className='grid-img-full-width-all'
              sx={{
                borderRadius: '64px',
              }}
              component="img"
              alt="logo"
              src={`/assets/images/become-partner.png`}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductPartner;
