/* eslint-disable eqeqeq */
import styled from '@emotion/styled';
import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import Reservation, { ReservationLayout } from './Reservation';
import Identification from './Identification';
import Payment from './Payment';
import BookingForm, { BOOKING_RATE } from './BookingForm';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { getAdminBeachDetails, getBeachBeachReservationsCustomersPaymentsForADate, getHutBeachReservationsForACustomer } from 'src/services/api/admin.api';
import update from "immutability-helper";
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getPriceByDay } from 'src/utils/common';
import { useTranslation } from 'react-i18next';


const Circle = styled(Box)({
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    marginBottom: '5px',
});

const Label = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '600',
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block',
    },
}));

const Layout = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: ['TT Norms Pro Regular', 'Helvetica', 'sans-serif'].join(','),
});

const FlexBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '6rem',
});

const Line = styled(Box)(({ theme }) => ({
    border: '1px solid #EAEDF8',
    margin: '0 -32px 5px -32px ',
    width: '15rem',
    [theme.breakpoints.up('md')]: {
        margin: '0 -32px 22px -32px',
    },
}));

const FormLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '40px',
    justifyContent: 'center',
    padding: '45px 20px 70px 20px',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderBottomRightRadius: '50px',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        borderBottomRightRadius: '100px',
    },
}));

const USER_DEFAULT_DATA = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
};

export default function Tab({ details }) {
    const { id } = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [userData, setUserData] = useState(USER_DEFAULT_DATA);
    const [totalAmount, setTotalAmount] = useState(0);
    const [mainServiceFees, setMainServiceFees] = useState(0);
    const [restaurantDetails, setRestaurantDetails] = useState(null);
    const [beachDetails, setBeachDetails] = useState({
        id: 1,
        status: "reserved_full_day",
        half_day_type: "firstHalf",
        details: []
    });
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [isBeach, setIsBeach] = useState(false);
    const [isHalfDay, setIsHalfDay] = useState(false);
    const [isCheckedCondition, setIsCheckedCondition] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [selectedBox, setSelectedBox] = useState([]);
    const [getLoading, setGetLoading] = useState(false);
    const [beach, setBeach] = useState(null);
    const { t } = useTranslation('translation', { keyPrefix: 'booking' });

    const getBeachData = async (dateObj, idData) => {
        setGetLoading(true);
        setSelectedBox([]);
        const resBeach = await getAdminBeachDetails(idData);
        if (resBeach.status && resBeach.data) {
            let activeBeach = resBeach.data;
            let isScheduleMatch = false;
            let scheduleData = [];

            scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];

            if (scheduleData.length > 0) {
                for (let j = 0; j < scheduleData.length; j++) {
                    if (scheduleData[j] === moment(dateObj).locale("en").format('dddd').toLowerCase()) {
                        isScheduleMatch = true;
                    }
                }
            }

            if (activeBeach && activeBeach.plan && isScheduleMatch) {
                let planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                if (planData.length > 0) {
                    planData = planData.filter((ele) => { return { ...ele, available: true } })
                }
                const latestBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
                await setBeach(latestBeach);
                await refreshReservationsCustomersPayments(latestBeach, dateObj)
                setGetLoading(false)
            } else {
                setBeach(null)
                setGetLoading(false)
            }
        } else {
            setBeach(null)
            setGetLoading(false)
        }
    }

    const handleChangeMapCheckbox = useCallback(async (e) => {
        if (beach && beach.plan) {
            setSelectedBox([])
            setBeachDetails(prevState => ({
                ...prevState,
                isMapBook: e.target.checked,
                details: prevState.details && prevState.details.length > 0 ? prevState.details.map((ele) => {
                    delete ele.position
                    return ele
                }) : []
            }));
            let _spotArray = [...beach.plan];
            for await (const [i, IValue] of Object.entries(_spotArray)) {
                for await (const [j, JValue] of Object.entries(IValue)) {
                    _spotArray[i][j] = { ..._spotArray[i][j], selected: false }
                }
            }
            setBeach({ ...beach, plan: _spotArray });
        }
    }, [setBeach, beach])

    const getBeachBeachReservationsCustomersPayments = async (beachId, dateObj) => {
        const resCustomersPaymentsData = await getBeachBeachReservationsCustomersPaymentsForADate(beachId, moment(dateObj).format("YYYY-MM-DD"));
        if (resCustomersPaymentsData && resCustomersPaymentsData.status) {
            const reservationsCustomersPayments = resCustomersPaymentsData.data.map((ele) => {
                ele.details = ele.details ? JSON.parse(ele.details) : [];
                return ele;
            })
            return reservationsCustomersPayments;
        }
        return []
    }

    const refreshReservationsCustomersPayments = async (beachSelectedObj, dateObj) => {
        let reservationsCustomersPayments = undefined;
        if (beachSelectedObj) {
            if (beachSelectedObj.plan) {

                let _gridPlans = [...beachSelectedObj.plan];
                reservationsCustomersPayments = await getBeachBeachReservationsCustomersPayments(beachSelectedObj.id, dateObj);
                if (reservationsCustomersPayments) {
                    // Add loyalty to this object
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        let reservationsResponse = await getHutBeachReservationsForACustomer(beachSelectedObj.id, reservationsCustomersPayments[i].customerId)
                        if (reservationsResponse && reservationsResponse.status && reservationsResponse.data) {
                            reservationsCustomersPayments[i].loyalty = reservationsResponse.data.length
                        }
                    }
                    // Add spot id to this object

                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            reservationsCustomersPayments[i].details[j].id = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y]?.id
                        }
                    }
                    // For each reservations we display the spots reserved
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            let reservationId = reservationsCustomersPayments[i].id;
                            let type = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y]?.type
                            let reservationTYpe = reservationsCustomersPayments[i].details[j].status
                            let newType = type.substring(0, type.lastIndexOf("_")) + "_" + reservationTYpe
                            if (reservationTYpe == "reserved_half_day") {
                                let halfDayType = reservationsCustomersPayments[i].details[j].half_day_type;
                                if (halfDayType) {
                                    newType += `_${halfDayType}`;
                                }
                            }
                            // If "In", we set the right type (and icon) and available to false
                            if (reservationsCustomersPayments[i].status === 'in') {
                                newType += "_in"
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            } else if (reservationsCustomersPayments[i].status === 'out') {
                                // If "Out", we set to the old type and available to true (the spot can be reserved again)
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            }
                            // If "Noshow", we set to the old type and available to true (the spot can be reserved again)
                            else if (reservationsCustomersPayments[i].status === 'noshow') {
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            }
                            // If we don't have any status, we set available to false
                            else {
                                // TODO for now it's always "unpaid" because se don't manage payment
                                const statusImage = reservationsCustomersPayments[i].payment ? reservationsCustomersPayments[i].payment.status : "unpaid";
                                newType += "_" + statusImage;
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            }
                            // New type
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].type = newType
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].originalType = type
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].reservationId = reservationId
                        }
                    }
                    setBeach(prevState => ({
                        ...prevState,
                        plan: [..._gridPlans],
                    }));

                    await refreshAvailabilities(_gridPlans);
                }
            }
        }
    }

    const refreshAvailabilities = useCallback(async (beachSelectedPlan) => {
        let sunbedFullDayAvailableCountData = 0
        let doubleSunbedFullDayAvailableCountData = 0
        // let doubleSunbedHalfDayAvailableCount = 0;
        // let sunbedHalfDayAvailableCount = 0;

        const spots = beachSelectedPlan

        if (spots.length > 0) {
            if (spots) {
                for await (const [i, IValue] of Object.entries(spots)) {
                    for await (const [j] of Object.entries(IValue)) {
                        if (spots[i][j].type.startsWith("double_sunbed") && spots[i][j].type.indexOf('free') >= 0) {
                            doubleSunbedFullDayAvailableCountData++
                        } else if (spots[i][j].type.startsWith("sunbed") && spots[i][j].type.indexOf('free') >= 0) {
                            sunbedFullDayAvailableCountData++
                        }
                    }
                }
            }
        }
        let updateBeachDetails = beachDetails.details ? beachDetails.details : [];
        if (sunbedFullDayAvailableCountData > 0) {
            let alreadyDetails = _.find(updateBeachDetails, { type: "sunbed_0_degrees_free" })
            let detailsObj = null
            if (alreadyDetails) {
                detailsObj = { ...alreadyDetails, totalFree: sunbedFullDayAvailableCountData, position: [] }
            } else {
                detailsObj = {
                    type: "sunbed_0_degrees_free",
                    value: 0,
                    totalSeat: 0,
                    serviceFees: 0,
                    fees: 0,
                    totalFree: sunbedFullDayAvailableCountData,
                    position: []
                }
            }
            upsert(updateBeachDetails, detailsObj);
        }
        if (doubleSunbedFullDayAvailableCountData > 0) {
            let alreadyDetails = _.find(updateBeachDetails, { type: "double_sunbed_0_degrees_free" })
            let detailsDoubleObj
            if (alreadyDetails) {
                detailsDoubleObj = { ...alreadyDetails, totalFree: doubleSunbedFullDayAvailableCountData }
            } else {
                detailsDoubleObj = {
                    type: "double_sunbed_0_degrees_free",
                    value: 0,
                    totalSeat: 0,
                    serviceFees: 0,
                    fees: 0,
                    totalFree: doubleSunbedFullDayAvailableCountData,
                }
            }
            upsert(updateBeachDetails, detailsDoubleObj);
        }
        setBeachDetails({ ...beachDetails, details: [...updateBeachDetails] })
    }, [beachDetails]);

    const upsert = (array, element) => { // (1)
        const i = array.findIndex(_element => _element.type === element.type);
        if (i > -1) array[i] = element; // (2)
        else array.push(element);
    }
    const handleRemoveMapSelection = useCallback(async(value, type) => {
        if (beachDetails && beachDetails.details && beachDetails.details.length > 0) {
            const detailsType = _.find(beachDetails.details, {type});
            if(detailsType){
                if(value < detailsType.position.length){
                    alert("You have already map selected, if you want to remove please unselect map seat first.")
                }
            }        
        }
    }, [selectedBox, beachDetails]);

    const handleOnClickBox = useCallback(async (rowIndex, columnIndex) => {
        let _gridArray = [...beach.plan];
        const existingVal = _gridArray[rowIndex][columnIndex];
        if (existingVal) {
            let updateBeachDetails = beachDetails.details ? beachDetails.details : [];
            if (updateBeachDetails && updateBeachDetails.length > 0) {
                if (existingVal.type.startsWith("double_sunbed")) {
                    let doubleBedDetails = _.find(updateBeachDetails, { type: "double_sunbed_0_degrees_free" });
                    let currentObj = { ...existingVal, mapType: existingVal.type, x: rowIndex, y: columnIndex, row: rowIndex, col: columnIndex }
                    let doubleBedPosition = [];
                    if (doubleBedDetails && doubleBedDetails.position && doubleBedDetails.position.length > 0) {
                        let existingPosition = doubleBedDetails.position;
                        const index = existingPosition.findIndex((item) => item.row === rowIndex && item.col === columnIndex);
                        if (index !== -1) {
                            doubleBedPosition = update(existingPosition, {
                                $splice: [[existingPosition.indexOf({ row: rowIndex, col: columnIndex }), 1]],
                            });
                        } else {
                            doubleBedPosition = [...existingPosition, currentObj]
                        }
                    } else {
                        doubleBedPosition = [{ ...currentObj }]
                    }
                    if (doubleBedPosition && doubleBedPosition.length > doubleBedDetails.value) {
                        return false
                    }
                    upsert(updateBeachDetails, { ...doubleBedDetails, position: doubleBedPosition, mapSelectedTotal: doubleBedPosition.length || 0 });
                    setSelectedBox(doubleBedPosition)
                    setBeachDetails({ ...beachDetails, details: updateBeachDetails })
                } else if (existingVal.type.startsWith("sunbed")) {
                    let doubleBedDetails = _.find(updateBeachDetails, { type: "sunbed_0_degrees_free" });
                    let currentObj = { ...existingVal, x: rowIndex, y: columnIndex, mapType: existingVal.type, type: existingVal.type, row: rowIndex, col: columnIndex }
                    let doubleBedPosition = [];

                    if (doubleBedDetails && doubleBedDetails.position && doubleBedDetails.position.length > 0) {
                        let existingPostion = doubleBedDetails.position;
                        const index = existingPostion.findIndex((item) => item.row === rowIndex && item.col === columnIndex);
                        if (index !== -1) {
                            doubleBedPosition = update(existingPostion, {
                                $splice: [[existingPostion.indexOf({ row: rowIndex, col: columnIndex }), 1]],
                            });
                        } else {
                            doubleBedPosition = [...existingPostion, currentObj]
                        }
                    } else {
                        doubleBedPosition = [{ ...currentObj }]
                    }
                    if (doubleBedPosition && doubleBedPosition.length > doubleBedDetails.value) {
                        return false
                    }
                    upsert(updateBeachDetails, { ...doubleBedDetails, position: doubleBedPosition, mapSelectedTotal: doubleBedPosition.length || 0 });
                    setSelectedBox(doubleBedPosition)
                    setBeachDetails({ ...beachDetails, details: updateBeachDetails })
                }
                _gridArray[rowIndex][columnIndex] = { ...existingVal, selected: existingVal?.selected ? !existingVal?.selected : true };
                await setBeach(prevState => ({
                    ...prevState,
                    plan: [..._gridArray],
                }));
            } else {
                toast.error("Veuillez sélectionner au moins un numéro du compteur")
            }
        }
    }, [selectedBox, beach, setBeach, beachDetails]);

    React.useEffect(() => {
        const restId = details?.restaurants[0]?.id
        if (params?.restaurantBooked && restId) {
            let totalSeat = Number(params?.restaurantBooked) || 0 * 2;
            let serviceFees = totalSeat * 1;
            let type = "dining_table_2_seater"
            let detailsObj = { type, value: Number(params?.restaurantBooked) || 0, totalSeat, serviceFees }
            let object = {
                id: restId,
                status: "reserved_full_day",
                reservation_time: "12:00",
                details: [{ ...detailsObj }]
            }
            setRestaurantDetails(object)
        }
    }, [params?.restaurantBooked, details]);

    React.useEffect(() => {
        if (selectedDate && selectedDate.startDate && details) {
            const beachId = details.beaches && details.beaches.length > 0 ? details.beaches[0]['id'] : null
            if (beachId) {
                let startDate = moment(selectedDate.startDate).format("YYYY-MM-DD");
                getBeachData(startDate, beachId);
                setTimeout(() => {
                    let latestMap = []
                    if (beachDetails.details.length > 0) {
                        latestMap = beachDetails.details.map((ele) => {
                            return {
                                ...ele,
                                position: []
                            }
                        })
                    }
                    console.log(beachId, 'beachId');
                    setBeachDetails(prevState => ({
                        ...prevState,
                        id: beachId,
                        details: latestMap
                    }));
                }, 3000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details]);


    React.useEffect(() => {
        let latestDate = params.dfrom ? moment(params.dfrom, "YYYY-MM-DD").toDate() : moment(new Date()).toDate()
        let latestEndDate = params.dend ? moment(params.dend, "YYYY-MM-DD").toDate() : moment(new Date()).toDate()
        let dateRange = {
            startDate: latestDate,
            endDate: latestEndDate,
            key: "selection"
        }
        setSelectedDate(dateRange);

        if (params.sunbed || params.double_sunbed) {
            let sunBed = params.sunbed ? Number(params.sunbed) : 0
            let doubleBed = params.double_sunbed ? Number(params.double_sunbed) : 0
            let singleCharge = getPriceByDay(dateRange, "single", isRestaurant);
            let doubleCharge = getPriceByDay(dateRange, "double", isRestaurant);

            let beachDetailArr = [
                {
                    type: "sunbed_0_degrees_free",
                    value: sunBed,
                    totalSeat: sunBed,
                    serviceFees: (sunBed * singleCharge),
                    fees: (sunBed * BOOKING_RATE),
                    totalFree: 0
                },
                {
                    type: "double_sunbed_0_degrees_free",
                    value: doubleBed,
                    totalSeat: doubleBed,
                    serviceFees: (doubleBed * doubleCharge),
                    fees: (doubleBed * BOOKING_RATE),
                    totalFree: 0
                },
            ];
            setBeachDetails({
                id,
                isMapBook: false,
                status: "reserved_full_day",
                half_day_type: "firstHalf",
                details: beachDetailArr
            })
        }
    }, []);

    const prevTab = (e) => {
        e.preventDefault();
        if (tabIndex > 0) {
            setTabIndex(tabIndex - 1);
        }
    };

    const nextTab = (e, type = "all") => {
        e.preventDefault();
        if (tabIndex < 2) {
            if (tabIndex == 0) {
                setTabIndex(tabIndex + 1);
            } else {
                setTabIndex(tabIndex + 1);
            }
        }
    };

    const circleStyle = (index) => {
        if (tabIndex === index) {
            return { backgroundColor: '#FF8478' };
        } else if (tabIndex > index) {
            return { backgroundColor: '#000' };
        } else {
            return { backgroundColor: '#BCC1D1' };
        }
    };

    const labelStyle = (index) => {
        if (tabIndex >= index) {
            return { color: '#000' };
        } else {
            return { color: '#BCC1D1' };
        }
    };

    return (
        <>
            <Layout className='identificationc-cls-addinghereusedresturant-optiontabbing'>
                <FlexBox>
                    <Circle style={circleStyle(0)}></Circle>
                    <Label style={labelStyle(0)}>{t("reservation")}</Label>
                </FlexBox>
                <Line></Line>
                <FlexBox>
                    <Circle style={circleStyle(1)}></Circle>
                    <Label style={labelStyle(1)}>{t("identification")}</Label>
                </FlexBox>
                <Line></Line>
                <FlexBox>
                    <Circle style={circleStyle(2)}></Circle>
                    <Label style={labelStyle(2)}>{t("payment")}</Label>
                </FlexBox>
            </Layout>
            <div className='main-bg-adding-here-custm-support'>
                <Box className="needaddbg-custom-here">
                    <FormLayout className='identificationc-cls-addinghereusedresturant-option-tabbing-console'>
                        {tabIndex === 0 ? (
                            getLoading ? (
                                <ReservationLayout className='identificationc-cls-addinghereusedresturant-option-tabbing-console-resrvation'>
                                    <Box
                                        className='identificationc-cls-addinghereusedresturant-option-tabbing-console-resrvation-box'
                                        justifyContent={"center"} sx={{ display: "flex", mt: 10, alignContent: "center", justifyContent: "center" }}>
                                        <CircularProgress />
                                    </Box>
                                </ReservationLayout>
                            ) : (
                                <Reservation
                                    className='identificationc-cls-addinghereusedresturant-option-tabbing-console-resrvation-layouts'
                                    id={id}
                                    details={details}
                                    setSelectedDate={setSelectedDate}
                                    setRestaurantDetails={setRestaurantDetails}
                                    isRestaurant={isRestaurant}
                                    setIsRestaurant={setIsRestaurant}
                                    setIsBeach={setIsBeach}
                                    isBeach={isBeach}
                                    restaurantDetails={restaurantDetails}
                                    setBeachDetails={setBeachDetails}
                                    defaultRestaurant={params?.defaultRestaurant || false}
                                    beachDetails={beachDetails}
                                    getLoading={getLoading}
                                    beach={beach}
                                    handleOnClickBox={handleOnClickBox}
                                    handleChangeMapCheckbox={handleChangeMapCheckbox}
                                    setIsHalfDay={setIsHalfDay}
                                    isHalfDay={isHalfDay}
                                    handleRemoveMapSelection={handleRemoveMapSelection}
                                />
                            )

                        ) : null}

                        {tabIndex === 1 ? (
                            <Identification
                                className='identificationc-cls-addinghereusedresturant-option-tabbing-console-resrvation-identifiaction'
                                nextTab={nextTab}
                                prevTab={prevTab}
                                id={id}
                                userData={userData}
                                setUserData={setUserData}
                                setTabIndex={setTabIndex}
                            />
                        ) : null}

                        {tabIndex === 2 ? (
                            <Payment
                                className='identificationc-cls-addinghereusedresturant-option-tabbing-console-payment'
                                nextTab={nextTab}
                                prevTab={prevTab}
                                date={selectedDate}
                                id={id}
                                userData={userData}
                                totalAmount={totalAmount}
                                restaurantDetails={restaurantDetails}
                                beachDetails={beachDetails}
                                isCheckedCondition={isCheckedCondition}
                                setIsCheckedCondition={setIsCheckedCondition}
                                invoiceDetails={invoiceDetails}
                                isRestaurant={isRestaurant}
                                defaultRestaurant={params?.defaultRestaurant || false}
                                details={details}
                                mainServiceFees={mainServiceFees}
                            />
                        ) : null}

                        <BookingForm
                            className='identificationc-cls-addinghereusedresturant-option-tabbing-console-bookingform'
                            nextTab={nextTab}
                            tabIndex={tabIndex}
                            date={selectedDate}
                            setSelectedDate={setSelectedDate}
                            id={id}
                            setTotalAmount={setTotalAmount}
                            totalAmount={totalAmount}
                            isCheckedCondition={isCheckedCondition}
                            restaurantDetails={restaurantDetails}
                            isRestaurant={isRestaurant}
                            beachDetails={beachDetails}
                            selectedBox={selectedBox}
                            details={details}
                            defaultRestaurant={params?.defaultRestaurant || false}
                            isBeach={isBeach}
                            setMainServiceFees={setMainServiceFees}
                            mainServiceFees={mainServiceFees}
                        />
                    </FormLayout>
                </Box>
            </div>

        </>
    );
}
