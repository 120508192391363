import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ProSiteButton() {
  const { t } = useTranslation('translation');
  return (
    <Button
      className='prositebtn-cls-adding-portionofhigh-resol'
      variant="text"
      size="small"
      sx={{
        mr: 1,
        px: 0,
        minWidth: '120px !important',
        width: '120px !important',
        padding: '0px 20px',
        height: '48px',
        borderRadius: '14px',
        color: '#06133a',
        fontSize: '16px',
        fontWeight: '700 !important',
        fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
        display: 'contents',
        marginRight: '10px',
        '&:hover': {
          background: '#e8edff',
          transition: 'background-color .2s cubic-bezier(.4,0,.6,1)',
        },
      }}
      onClick={() => window.open('https://pro.beachbooker.fr/login', '_blank')}
    >
      {t("professional_area")}
    </Button>
  );
}

ProSiteButton.propTypes = {
  onOpenNav: PropTypes.func,
};
