/* eslint-disable eqeqeq */
import { Field, Form, Formik } from 'formik';
import { Stack, FormControl, Button, Grid, Link } from '@mui/material';
import { TextField } from 'formik-mui';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useAuth } from 'src/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { login } from 'src/services/api/authentication';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const LoginFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const defaultValuesData = {
  email: '',
  password: '',
};

const LoginForm = () => {
  const { setSession, setUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        actions.setSubmitting(true);
        await login(values)
          .then(async (res) => {
            if (res && res.status && res.data) {
              const { token } = res.data;
              setUser(res.data);
              setSession(token, res.data?.id, res.data);
              toast.success(res.message);
              navigate('/', { replace: true });
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            actions.setSubmitting(false);
            if (err) {
              toast.error(t('somethingWentWrong'));
            }
          });
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        toast.error(error?.response?.data?.message?.[0] || t('somethingWentWrong'));
      }
    },
    [navigate, setSession, setUser]
  );

  return (
    <Grid container direction={'row'} sx={{ mt: 0, mb: 0 }} className='login-formcls'>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Formik
          enableReinitialize
          validationSchema={LoginFormSchema}
          onSubmit={handleSubmit}
          initialValues={defaultValuesData}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {({ errors }) => (
            <Form autoComplete="off" noValidate>
              <Stack
                spacing={3}
                sx={{
                  mt: 2,
                  width: '600px',
                  maxWidth: '600px',
                  '@media (max-width: 650px) ': {
                    width: '80vw',
                  },
                }}
              >
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="email"
                      fullWidth
                      placeholder={t('email')}
                      label={t('email')}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      type="password"
                      component={TextField}
                      name="password"
                      fullWidth
                      placeholder={t('password')}
                      label={t('password')}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent={'center'}>
                  <Button size="large" variant="contained" type="submit">
                    {t('confirm')}
                  </Button>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                  <Link component={RouterLink} variant="subtitle2" to={"/reset-password"}>
                    {t("forgotPassword")}
                  </Link>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
