import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import Btn from './Btn';
import { useTranslation } from "react-i18next";

const Layout = styled('section')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column-reverse',
  textAlign: 'center',
  alignItems: 'center',
  fontFamily: ['TT Norms Pro Regular', 'Helvetica', 'sans-serif'].join(','),
  justifyContent: 'space-between',
  backgroundColor: '#06133A',
  padding: '60px 60px 130px 60px',
  borderBottomRightRadius: '50px',
  gap: '40px',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    textAlign: 'left',
    borderBottomRightRadius: '200px',
  },
}));

function PartnerHero() {
  const { t } = useTranslation('translation', { keyPrefix: 'partner.section1' });

  return (
    <Layout className='editbookingforming-clsadding-daterang-pcket-lsp'>
      <Box sx={{ maxWidth: '80rem' }}>
        <Typography
          fontSize="64px"
          fontWeight="700"
          marginBottom="20px"
          fontFamily='"TT Norms Pro Medium",Helvetica,sans-serif!important'
        >
          {t("title")}
        </Typography>
        <Typography
          variant="p"
          color="#fff"
          fontSize="22px"
          fontFamily='"TT Norms Pro Medium",Helvetica,sans-serif!important'
          fontWeight="500"
          sx={{
            lineHeight: '30px',
            display: 'block',
          }}>
          {t("subTitle")}
        </Typography>
        <Btn btnText={t("buttonText")} color="#fff" bgColor="none" borderColor="#fff" hoverBgColor="#1A2549" />
      </Box>
      <Box>
        <img src={`/assets/images/beach.jpg`} alt="" style={{ borderRadius: '5rem', width: '50rem', height: "20rem" }} />
      </Box>
    </Layout>
  );
}

export default PartnerHero;
