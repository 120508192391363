import React, { useMemo } from 'react';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import useResponsiveFontSize from './useResponsiveFontSize';
import ConfirmButton from './shared/ConfirmButton';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        [fontSize]
    );

    return options;
};

const SplitForm = ({ createBeachBookingPaymet, isCheckedCondition = false, setIsCheckedCondition, saveLoading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const { t } = useTranslation('translation', { keyPrefix: "paymentTab" });

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        });

        if (paymentMethod) {
            createBeachBookingPaymet({ cardTokenId: paymentMethod?.id });
        } else if (error) {
            alert(error.message || error.error || error);
        }

        return false;
    };

    return (
        <form onSubmit={handleSubmit} className='splitfomr-cls-adding-type'>
            <label>
                {t("cardNumber")}
                <CardNumberElement
                    options={options}
                    onReady={() => {
                        console.log('CardNumberElement [ready]');
                    }}
                    onChange={(event) => {
                        console.log('CardNumberElement [change]', event);
                    }}
                    onBlur={() => {
                        console.log('CardNumberElement [blur]');
                    }}
                    onFocus={() => {
                        console.log('CardNumberElement [focus]');
                    }}
                />
            </label>
            <label>
                {t("expiryDate")}
                <CardExpiryElement
                    options={options}
                    onReady={() => {
                        console.log('CardNumberElement [ready]');
                    }}
                    onChange={(event) => {
                        console.log('CardNumberElement [change]', event);
                    }}
                    onBlur={() => {
                        console.log('CardNumberElement [blur]');
                    }}
                    onFocus={() => {
                        console.log('CardNumberElement [focus]');
                    }}
                />
            </label>
            <label>
                CVC
                <CardCvcElement
                    options={options}
                    onReady={() => {
                        console.log('CardNumberElement [ready]');
                    }}
                    onChange={(event) => {
                        console.log('CardNumberElement [change]', event);
                    }}
                    onBlur={() => {
                        console.log('CardNumberElement [blur]');
                    }}
                    onFocus={() => {
                        console.log('CardNumberElement [focus]');
                    }}
                />
            </label>

            <FormGroup sx={{ color: 'black', mt: '30px', mb: "20px" }}>
                <FormControlLabel
                    checked={isCheckedCondition}
                    onClick={(e) => {
                        setIsCheckedCondition(e.target.checked);
                    }}
                    control={<Checkbox />}
                    label={t("acceptConditionText")}
                />
            </FormGroup>
            <ConfirmButton
                disabled={!stripe || !isCheckedCondition || saveLoading}
                saveLoading={saveLoading}
                text={t("confirm")}
                handleOnClick={handleSubmit}
            />
        </form>
    );
};

export default SplitForm;
