/* eslint-disable array-callback-return */
import { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ActionMoreMenu from 'src/components/shared/ActionMoreMenu';
import { deleteReservation, reservationTableDetail } from 'src/services/api/authentication';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import ChangePasswordForm from 'src/components/settings/ChangePasswordForm';
import { useTranslation } from 'react-i18next';

export default function ChangePasswordPage() {
  const [queryFilter, setQueryFilter] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const handleEdit = useCallback(
    (row) => {
      navigate('/member/edit-booking/' + row?.id);
    },
    [navigate]
  );

  const handleDelete = useCallback(
    (id) => {
      setIsDeleted(true);
      setSelectedItem(id);
    },
    [setIsDeleted]
  );
  const refresh = useCallback(() => {
    handleDataTableChange(queryFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilter]);

  const handleClickDelete = useCallback(
    async (id) => {
      const res = await deleteReservation(id);
      if (res && res.status) {
        toast.success('Supprimer avec succès');
      }
      setSelectedItem(null);
      setIsDeleted(null);
      refresh();
    },
    [refresh]
  );

  const findSeaNumber = useCallback((ele, row = 0, col = 1) => {
    const plan = ele.plan ? JSON.parse(ele.plan)?.plan : [];
    if (plan && plan.length > 0) {
      const selectedRow = plan[row][col];
      if (selectedRow && selectedRow.id) {
        return <li>{selectedRow.id}</li>;
      } else {
        return `[${Number(row)}, ${Number(col)}]`;
      }
    }
    return `[${Number(row)}, ${Number(col)}]`;
  }, []);

  const columns = [
    // {
    //     name: 'id',
    //     label: 'Nº',
    //     isSearchable: true,
    //     isSortable: true,
    // },
    {
      name: 'customer.firstname',
      label: 'Prénom',
      isSearchable: true,
      isSortable: true,
    },
    {
      name: 'customer.lastname',
      label: 'Nom',
      isSearchable: true,
      isSortable: true,
    },
    {
      name: 'customer.phone',
      label: 'Téléphone',
      isSearchable: false,
      isSortable: true,
    },
    {
      name: 'date',
      label: 'Date',
      isSearchable: false,
      isSortable: true,
      render: (row) => (row?.date ? moment(row.date).format('DD/MM/YYYY') : '-'),
    },
    {
      name: 'restaurant',
      label: 'Restaurant',
      isSortable: false,
      render: (row) => {
        if (row.restaurant === null || !row.restaurant) {
          return 'Non';
        } else {
          return 'Oui';
        }
      },
    },
    // {
    //     name: 'customer.comment',
    //     label: 'Commentaire client',
    //     isSortable: false,
    //     render: (row) =>
    //         <NoteColumn
    //             withSave={false}
    //             value={row?.customer?.comment || null}
    //             row={row}
    //         />,
    // },
    {
      key: 'details',
      label: 'Détails',
      sortable: true,
      render: (row) => {
        const detailsData = row.details ? JSON.parse(row.details) : [];
        if (detailsData.length > 0) {
          return (
            <ul>
              {detailsData.map((ele) => (
                <li>
                  {ele.type.startsWith('double_sunbed') && <span>Bed </span>}
                  {ele.type.startsWith('sunbed') && <span>Transat </span>}
                  {ele.status == 'reserved_full_day' && <span>journée </span>}

                  {ele.status == 'reserved_half_day' && <span>½ journée </span>}
                </li>
              ))}
            </ul>
          );
        } else {
          return null;
        }
      },
    },
    {
      name: 'location',
      label: 'Emplacement',
      render: (row) => {
        const detailsData = row.details ? JSON.parse(row.details) : [];
        if (detailsData.length > 0) {
          return (
            <ul className="table-list-number">
              {detailsData.map((ele) => findSeaNumber(row.beach, ele.position.x, ele.position.y))}
            </ul>
          );
        } else {
          return null;
        }
      },
    },
    // {
    //     name: 'comment',
    //     label: 'Note',
    //     isSortable: false,
    //     render: (row) =>
    //         <NoteColumn
    //             withSave={true}
    //             value={row.comment}
    //             row={row}
    //         />,
    // },

    // {
    //     name: 'status',
    //     label: 'Statut',
    //     isSortable: false,
    //     render: (row) => {
    //         if (row.status === "in") {
    //             return (<span style={{ color: "green" }}>In</span>)
    //         }
    //         if (row.status === "out") {
    //             return <span style={{ color: "green" }}>Out</span>
    //         }
    //         if (row.status === "noshow") {
    //             return (<span style={{ color: "orange" }}>No show</span>)
    //         }
    //     }
    // },
    {
      name: '',
      label: 'Action',
      props: { align: 'center' },
      render: (row) => <ActionMoreMenu row={row} onClickDelete={handleDelete} onClickEdit={handleEdit} />,
    },
  ];

  const handleDataTableChange = useCallback(async (filter) => {
    setQueryFilter(filter);
    const res = await reservationTableDetail({ ...filter });
    if (res && res.data && res.data) {
      setData(res.data);
      setTotal(res.total);
    } else {
      setData([]);
      setTotal(0);
    }
  }, []);

  useEffect(() => {
    if (queryFilter) {
      handleDataTableChange(queryFilter);
    }
  }, [handleDataTableChange, queryFilter]);

  return (
    <>
      <Helmet className="helment-circle-out">
        <title> {t('changePassword')} | Beach Booker </title>
      </Helmet>
      <Container className='container-grid-cls-adding'>
        <Grid
          className='container-grid-cls-adding-flexing'
          container
          direction="column"
          justifyContent="center"
          sx={{
            minHeight: 'calc(100vh - 250px)',
            py: 3,
            px: 3,
            width: '100%',
            mt: '50px'
          }}
        >
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              sx={{
                fontSize: '40px',
                color: '#06133A',
                fontStyle: 'normal',
                fontWeight: '700',
                fontFamily: 'TT Norms Pro Medium ,Helvetica,sans-serif!important',
                fontHeight: 52,
              }}
            >
              {t('changePassword')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              width: '50vw',
              margin: 'auto',
              '@media (max-width: 650px)': {
                width: '100%',
              },
            }}
          >
            <ChangePasswordForm />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
