import * as React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import DateRangePickerComponent from 'src/components/shared/DateRangePickerComponent';
import { useCallback } from 'react';
import { MAP_FEES } from '../Booking/sections/BookingForm';
import { getPriceByCategories, getPriceByDay } from 'src/utils/common';
import { useTranslation } from 'react-i18next';

const BoxLayout = styled(Box)(({ theme }) => ({
    width: '100%',
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
        width: '30%',
    },
}));

const FlexBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
});

export default function EditBookingForm(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        nextTab,
        tabIndex,
        setTotalAmount,
        totalAmount,
        date,
        setSelectedDate,
        restaurantDetails,
        defaultRestaurant,
        isRestaurant,
        beachDetails,
        selectedBox,
        paidAmount,
        details,
        setMainServiceFees
    } = props;

    const isRestaurantSelected = restaurantDetails && restaurantDetails.details && restaurantDetails.details.length > 0 ? true : false;

    const { t } = useTranslation('translation', { keyPrefix: "booking" });

    const handleChangeDate = React.useCallback(
        (e) => {
            if (e.startDate && e.endDate) {
                const lDate = moment(e.startDate).format('YYYY-MM-DD');
                const eDate = moment(e.endDate).format('YYYY-MM-DD');
                setSearchParams({ dfrom: lDate, dend: eDate });
                setSelectedDate(e);
            }
        },
        [setSearchParams, setSelectedDate]
    );

    const updateTotalAmount = () => {
        let amount = 0;
        let mainServiceAmount = 0;
        console.log(beachDetails, 'beachDetails.details');
        if (beachDetails && beachDetails.details && beachDetails.details.length > 0) {
            beachDetails.details.map((ele) => {
                let positionLength = (ele && ele.position) ? ele.position.length : 0;
                let latestValueWithoutMap = ele.value - positionLength;
                let priceType = ele.type.startsWith("sunbed") ? "sunbed" : "double_sunbed"
                amount += ele.value * getPriceByCategories(details?.price_lists, priceType);
                let isRestroSelected = defaultRestaurant || defaultRestaurant == "true" || isRestaurant ? true : false
                let charge = ele.type.startsWith("sunbed") ? getPriceByDay(date, "single", isRestroSelected) : getPriceByDay(date, "double", isRestroSelected);
                let mainServiceData = Number(latestValueWithoutMap) * charge
                amount += mainServiceData;
                mainServiceAmount += mainServiceData;
                if (positionLength > 0) {
                    let mainMapPrice = positionLength * MAP_FEES
                    amount += mainMapPrice
                    mainServiceAmount += mainMapPrice;
                }
            })
        }

        setMainServiceFees(mainServiceAmount)

        if (restaurantDetails && restaurantDetails.details && restaurantDetails.details.length > 0) {
            restaurantDetails.details.map((ele) => {
                amount += Number(ele.value);
            })
        }
        let amountData = (getDaysCount() * amount);

        setTotalAmount(parseFloat(amountData).toFixed(2));
    };
    console.log(restaurantDetails, 'restaurantDetails');
    const updateTotalAmountOld = () => {
        let amount = 0;
        if (beachDetails && beachDetails.details && beachDetails.details.length > 0) {
            beachDetails.details.map((ele) => {
                let positionLength = (ele && ele.position) ? ele.position.length : 0;
                let latestValueWithoutMap = ele.value - positionLength
                let charge = ele.type.startsWith("sunbed") ? getPriceByDay(date, "single", isRestaurant) : getPriceByDay(date, "double", isRestaurant);
                if (ele.value > 0) {
                    let priceType = ele.type.startsWith("sunbed") ? "sunbed" : "double_sunbed"
                    amount += getPriceByCategories(details?.price_lists, priceType);
                }
                amount += Number(latestValueWithoutMap) * charge;
                if (positionLength > 0) {
                    amount += positionLength * MAP_FEES
                }
            })
        }
        if (restaurantDetails && restaurantDetails.details && restaurantDetails.details.length > 0) {
            restaurantDetails.details.map((ele) => {
                amount += Number(ele.value);
            })
        }
        let amountData = (getDaysCount() * amount)
        setTotalAmount(parseFloat(amountData).toFixed(2));
    };

    useEffect(() => {
        updateTotalAmount();
    }, [isRestaurant, restaurantDetails, beachDetails, selectedBox, beachDetails?.details]);

    const buttonVisibility = (tabIndex) => {
        if (tabIndex === 0) {
            return { display: 'block' };
        } else {
            return { display: 'none' };
        }
    };

    const selectedBoxLengthByTotal = useCallback(() => {
        if (beachDetails && beachDetails.details.length > 0) {
            let totalSelected = 0;
            beachDetails.details.map((ele) => {
                totalSelected += ele.position ? ele.position.length : 0
            })
            return totalSelected * MAP_FEES
        }
        return 0
    }, [beachDetails])


    const getBeachabel = (type) => {
        if (type && type.startsWith("sunbed")) {
            return 'Transat';
        } else if (type && type.startsWith("double")) {
            return 'Bed';
        }
        return null
    };

    const getDaysCount = useCallback(() => {
        if (date && date.startDate && date.endDate) {
            let a = moment(date.startDate);
            let b = moment(date.endDate);
            const days = b.diff(a, 'days')
            return days + 1
        }
        return 1
    }, [date]);

    const selectedBoxLengthByType = (type) => {
        if (beachDetails && beachDetails.details.length > 0) {
            let detailsData = _.find(beachDetails.details, { type });
            let latestFilter = (detailsData && detailsData.position) ? detailsData.position.length : 0;
            return latestFilter || 0
        }
        return 0
    };

    return (
        <BoxLayout className='boxlayout-flexing-clds-adding-forming-style-offing-cls'>
            <form
                className='editbookingforming-clsadding'
                style={{
                    color: 'black',
                    border: '2px solid #EAEDF8',
                    padding: '15px 20px',
                    borderRadius: '20px',
                    width: '100%',
                    height: 'fit-content',
                }}
            >
                <Box
                    className='editbookingforming-clsadding-daterang-pcket'
                    sx={{
                        border: '1px solid #EAEDF8',
                        borderRadius: '20px',
                        marginTop: '-5px',
                    }}
                >
                    <DateRangePickerComponent
                        defaultValue={date}
                        handleChangeDate={handleChangeDate}
                    />
                </Box>

                <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '600', m: '25px 0 6px 0' }}>
                    {t("detailsField")}
                </Typography>

                {(beachDetails && beachDetails.details.length > 0) && (
                    beachDetails.details.map((ele) => {
                        //let serviceCharge = ele.type.startsWith("sunbed") ? getPriceByDay(date, "single", isRestaurant) : getPriceByDay(date, "double", isRestaurant);
                        let priceType = ele.type.startsWith("sunbed") ? "sunbed" : "double_sunbed"
                        let adminPrice = ele.value * getPriceByCategories(details?.price_lists, priceType);
                        return (
                            <>
                                <FlexBox my="10px">
                                    <Typography sx={{ color: 'black', fontSize: '15px', fontWeight: '500' }}>
                                        {ele.value} x {getBeachabel(ele.type)}
                                    </Typography>
                                    <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '500' }}>
                                        {adminPrice} €
                                    </Typography>
                                </FlexBox>
                            </>
                        )
                    })
                )}

                <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '600', m: '25px 0 6px 0' }}>
                    {t("serviceCharge")}
                </Typography>

                {(beachDetails && beachDetails.details.length > 0) && (
                    beachDetails.details.map((ele) => {

                        let positionLength = (ele && ele.position) ? ele.position.length : 0;
                        let latestValueWithoutMap = ele.value - positionLength;
                        let isRestroSelected = defaultRestaurant || defaultRestaurant == "true" || isRestaurant ? true : false
                        let charge = ele.type.startsWith("sunbed") ? getPriceByDay(date, "single", isRestroSelected) : getPriceByDay(date, "double", isRestroSelected);
                        let mainServiceData = Number(latestValueWithoutMap) * charge
                        return (
                            <>
                                <FlexBox my="10px">
                                    <Typography sx={{ color: 'black', fontSize: '15px', fontWeight: '500' }}>
                                        {ele.value} x {getBeachabel(ele.type)}
                                    </Typography>
                                    <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '500' }}>
                                        {mainServiceData} €
                                    </Typography>
                                </FlexBox>
                            </>
                        )
                    }))}

                {beachDetails && beachDetails.isMapBook && (
                    <FlexBox className='beachiconimg-cls-adding-flexing'>
                        <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '500' }}>{t("seatChoice")}</Typography>
                        <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '500' }}>
                            {selectedBoxLengthByTotal()} €
                        </Typography>
                    </FlexBox>
                )}

                {restaurantDetails && restaurantDetails.details.length > 0 && (
                    <>
                        {restaurantDetails.details.map((ele) => (
                            <>
                                <FlexBox my="10px">
                                    <Typography sx={{ color: 'black', fontSize: '15px', fontWeight: '500' }}>
                                        {ele.value} x {ele.value > 1 ? t("covered") : t("coveredSingle")}
                                    </Typography>
                                    <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '500' }}>{ele.value} €</Typography>
                                </FlexBox>
                            </>
                        ))}
                        <br />
                    </>
                )}
                <FlexBox>
                    <Typography color="black" fontSize="16px" fontWeight="500">
                        {t("alreadyPaid")}
                    </Typography>
                    <Typography color="black" fontSize="22px" fontWeight="600" mb="10px">
                        {paidAmount} €
                    </Typography>
                </FlexBox>

                <FlexBox>
                    <Typography color="black" fontSize="16px" fontWeight="500">
                        {t("amountToBePaid")}
                    </Typography>
                    <Typography color="black" fontSize="22px" fontWeight="600" mb="10px">
                        {(Number(totalAmount) - Number(paidAmount))} €
                    </Typography>
                </FlexBox>



                <LoadingButton
                    type="submit"
                    style={buttonVisibility(tabIndex)}
                    sx={{
                        textAlign: 'center',
                        bgcolor: '#FF8478',
                        width: '100%',
                        color: '#fff',
                        '&:hover': { backgroundColor: '#E55446' },
                        fontSize: '15px',
                        borderRadius: '15px',
                        py: '10px',
                        fontWeight: '500',
                    }}
                    onClick={(e) => nextTab(e, 'reservation')}
                >
                    {t("continue")}
                </LoadingButton>
            </form>
        </BoxLayout>
    );
}
