import { Tooltip } from '@mui/material';

export default function CustomeTooltip({ children, title }) {
    return (
        <Tooltip
            title={title}
            className='tooltip-adding-cls-adding'
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        background: "#616161e6",
                        padding: "5px 16px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        fontWeight: 400,
                        fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important'
                    }
                }
            }}
        >
            {children}
        </Tooltip>
    );
}


