import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';
import { useState, useEffect } from 'react';
import { getHutList } from 'src/services/api/admin.api';
import { useNavigate } from 'react-router-dom';
import umbrella from '../../assets/icons/umbrella.svg';
import moment from 'moment';    
import { useTranslation } from 'react-i18next';

const CarouselItem = ({ item }) => {
  const navigate = useNavigate();
  const { names = null, image = [], city = null, id, isPartner = false } = item;
  const name = names;
  const { t } = useTranslation('translation');

  const imageUrl = (image || [])[0]?.url;
  return (
    <Card
      className='partnerbeachslider-cls-adding'
      sx={{ 
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': { bgcolor: 'secondary.light', cursor: 'pointer' },
        width: '100%', // Adjusted width for responsiveness
        padding:'10px',
        '@media (max-width: 601px)': {
          width:'80%',
          margin:'auto'
        },
      }}
      onClick={() => {
        if (id) {
          navigate(`/place/${id}?dfrom=${moment().format("YYYY-MM-DD")}`);
        }
      }}
    >
      <CardContent sx={{width:'100%', padding: 0}} >
        <Grid container direction={'row'} justifyItems={'center'} alignItems={'center'} sx={{width:'100%', padding:0}}> 
          <Grid item xs={12} sx={{width:'100%', padding:0}}>
            <Box 
              sx={{
                width: '100%', // Adjusted width for responsiveness
                height: '200px',
                borderRadius: '48px',
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover', // Adjusted background size
                padding:0
              }}
            />

            {isPartner && (
              <Box
                className="finder-list-partner"
                position={"absolute"}
                sx={{
                  display: "block",
                  top: 45,
                  zIndex: 999,
                  left: "12%",
                }}
              >
                <Button startIcon={<img src={umbrella} alt="umbrella" style={{ width: "20px", height: "20px" }} />}>{t("partnerText")}</Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{width:'100%'}}  mt="1.25rem">
            <Typography
              sx={{
                color: '#d88177!important',
                textTransform: 'uppercase',
                fontSize: '18px',
                fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
                fontWeight: 700,
                ml: 1.5,
                mb: 0,
                width:'100%'
              }}
              color="secondary.main"
              textAlign={'start'}
              gutterBottom
            >
              {name?.fr || ''}
            </Typography>

            <Typography
              sx={{
                fontSize: '14px',
                mt: '-2px',
                ml: 1.5,
                fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
                fontWeight: 400,
                width:'100%'
              }}
              color="#bcc1d1"
              textAlign={'start'}
              gutterBottom
            >
              {city ? city.name : null}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'block', marginTop: '-25px' }} onClick={onClick} />;
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'block', marginTop: '-25px' }} onClick={onClick} />;
}

export default function PartnerBeachSlider() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // Adjusted tablet breakpoint check

  const [data, setData] = useState([]);

  const hutListData = async () => {
    const params = {
      count: 10, // Adjust number of slides for mobile and tablet
      offset: 0,
      isPartner: true,
    };
    const res = await getHutList(params);
    if (res && res.data) {
      setData(res.data);
    }
  };

  useEffect(() => {
    hutListData();
  }, [isMobile, isTablet]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    rows: 1,
    slidesToShow: isMobile ? 1 : (isTablet ? 3 : 5), // Adjust number of slides for mobile and tablet
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Slider className='beach-partner-click' {...settings}>
      {data && data.map((item, index) => (
        <div key={index}>
          <CarouselItem item={item} />
        </div>
      ))}
    </Slider>
  );
}
