/* eslint-disable eqeqeq */
import { Box, FormControl } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import BeachSearchFinderComponent from 'src/components/shared/BeachSearchFinderComponent';
import DatePickerComponent from 'src/components/shared/DatePickerComponent';
import { useNavigate } from 'react-router-dom';
import search from '../../../assets/icons/search.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePickerHeaderComponent from 'src/components/shared/DatePickerHeaderComponent';

export default function FinderSearchBar({ cities, setSelectedSearchItem, selectedSearchItem, refreshPageData, params }) {
    const navigate = useNavigate();
    const [selectedCityValue, setSelectedCityValue] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const { t } = useTranslation('translation');

    const [selectedValue, setSelectedValue] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const handleChangeRangeDate = React.useCallback(
        (e) => {
            if (e.startDate && e.endDate) {
                setSelectedValue(e);
            }
        },
        []
    );
    useEffect(() => {        
        let latestDate = params.dfrom ? moment(params.dfrom, "YYYY-MM-DD").toDate() : moment(new Date()).toDate()
        let latestEndDate = params.dend ? moment(params.dend, "YYYY-MM-DD").toDate() : moment(new Date()).toDate()
        setSelectedValue({
            startDate: latestDate,
            endDate: latestEndDate,
            key: 'selection',
        });
    }, [params])

    useEffect(() => {
        if (selectedSearchItem && selectedSearchItem.searchItem) {
            setSelectedCityValue(selectedSearchItem.searchItem)
        }
        if (selectedSearchItem && selectedSearchItem.date) {
            setSelectedDate(selectedSearchItem.date)
        }
    }, [selectedSearchItem]);

    const handleOnchangeCustomer = useCallback((e, value) => {
        setSelectedCityValue(value)
        //setSelectedSearchItem({ ...selectedSearchItem, searchItem: value })
    }, []);

    const handleChangeDate = useCallback((value) => {
        setSelectedDate(value)
        // setSelectedSearchItem({ ...selectedSearchItem, date: value })
    }, []);

    const handleNavigate = () => {
        const startDate = selectedValue?.startDate ? moment(selectedValue?.startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        const endDate = selectedValue?.endDate ? moment(selectedValue?.endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');

        if (selectedCityValue && selectedCityValue.itemType == "city") {
            navigate(`/finder?city=${selectedCityValue?.key}&dfrom=${startDate}&dend=${endDate}&zoom=7`, { replace: true });
        } else if (selectedCityValue) {
            navigate(`/place/${selectedCityValue?.id}?dfrom=${startDate}&dend=${endDate}&zoom=7`, { replace: true });
        }
        refreshPageData({ selectedCityValue, startDate, endDate })
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} className="finder-list-location-xdawd">
            <Box
                className="finder-list-location-xdawdflexing-hh"
                sx={{
                    width: '15rem',
                }}
            >
                <BeachSearchFinderComponent
                    className="finder-list-location-xdawdflexing-hh-or-hh"
                    label={t("or")}
                    cities={cities}
                    defaultValue={selectedCityValue}
                    handleOnchangeCustomer={handleOnchangeCustomer}
                    style={{
                        '.MuiOutlinedInput-notchedOutline': {
                            borderRadius: '13px',
                            borderTopRightRadius: '0',
                            borderBottomRightRadius: '0',
                        },
                        '	.MuiAutocomplete-inputRoot': {
                            height: '47px',
                        },
                        '.MuiAutocomplete-input': {
                            marginTop: '-20px',
                        },
                    }}
                />
            </Box>
            <Box
                className="finder-list-location-xdawdflexing-hh-or-hh-same-here"
                sx={{
                    width: '15rem',
                }}
                position="relative"
            >
                    <DatePickerHeaderComponent
                        setDate={setSelectedValue}
                        handleChangeDate={handleChangeRangeDate}
                        defaultValue={selectedValue}     
                        shrink={false}                   
                        style={{
                            background: '#fff',
                            '.MuiOutlinedInput-notchedOutline': {
                                borderLeftWidth: '0',
                                borderRightWidth: '0',
                                borderRadius: '0',
                                zIndex: '1',
                            },
                            '.MuiInputBase-input': {
                                marginTop: '15px',
                              },
                              '.MuiInputBase-root': {
                                height: '48px',
                              },
                              '.MuiFormLabel-root': {
                                marginTop: '-5px',
                              },
                        }}
                    />
            </Box>
            <Box sx={{ border: '2px solid #EAEDF8', p: '5px', marginLeft: '-8px', zIndex: '2', borderRadius: '13px' }} className="cursing-pointeryu">
                <Box
                    className='namingborder-tyuiop-cls'
                    onClick={() => handleNavigate()}
                    component="button"
                    sx={{
                        cursor: 'pointer',
                        bgcolor: '#ff8478',
                        p: '8px',
                        border: 'none',
                        outline: 'none',
                        borderRadius: '13px',
                        '&:hover': {
                            bgcolor: '#E55446',
                        },
                    }}
                >
                    <Box component="img" src={search} width="19px" className='increasing-w-h-all'></Box>
                </Box>
            </Box>
        </Box>
    );
}
