import { LoadingButton } from '@mui/lab';
import React from 'react';

export default function ConfirmButton({ handleOnClick, disabled = false, text, saveLoading }) {
    return (
        <LoadingButton
            className='loadingbeachiconimg-cls-adding'
            loading={saveLoading}
            variant="contained"
            type="submit"
            size="large"
            disabled={disabled}
            {...handleOnClick && { onClick: handleOnClick }}
            sx={{
                fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
                fontWeight: "500",
                borderRadius: "14px",
                border: "none",
                color: "#fff",
                background: "#ff8478",
                ":hover": {
                    bgcolor: "#e55446"
                }
            }}
        >
            {text}
        </LoadingButton >
    );
}
