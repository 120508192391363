/* eslint-disable array-callback-return */
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useCallback, useEffect, useState } from 'react';
import { getBeachSearchData } from 'src/services/api/user.api';
import { Box, Button, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { getCityNames } from 'src/services/api/filter.api';
import locationIcon from "../../../src/assets/icons/location.svg";
import useDebounce from 'src/hooks/useDebounce';
import PlaceIcon from '@mui/icons-material/Place';

export default function BeachSearchComponent({ handleOnchangeCustomer, label, style, option, shrink = null, searchType }) {
    const [open, setOpen] = useState(false);
    const [cities, setCities] = useState([]);
    const [options, setOptions] = useState([]);
    const [newInputValue, setNewInputValue] = useState(null);
    const searchText = useDebounce(newInputValue, 500);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (option) {
            setOptions([option])
        }
    }, [option]);

    const fetchCities = async () => {
        const res = await getCityNames({ sort: "name" });
        let cityData = []
        if (res.status && res.data) {
            cityData = res?.data
        }
        setCities(cityData);
    }

    useEffect(() => {
        fetchCities()
    }, []);

    const aroundMeUrl = 'https://www.freeiconspng.com/thumbs/location-icon-png/location-icon-24.png';

    const beachSearchData = async (searchText) => {
        if (searchText) {
            // setOptions([]);
            setOpen(true)
            setLoading(true)
            let results = await getBeachSearchData(searchText, searchType);
            let latestCity = cities.filter((q) => { return q.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 });
            let latestOptions = results?.data || [];
            latestOptions = latestOptions.map((ele) => {
                return { ...ele, name: ele.names ? ele.names?.fr : null }
            })
            if (latestCity && latestCity.length > 0) {
                latestCity.map((ele) => {
                    latestOptions.push({ id: ele.id, itemType: "city", ...ele })
                });
            }
            setLoading(false)
            if (latestOptions && latestOptions.length > 0) {
                console.log(latestOptions, 'latestOptions');
                setOptions([...latestOptions]);
            } else {
                setOptions([]);
            }
        }
        return;
    };

    useEffect(() => {
        if (searchText) {
            beachSearchData(searchText);
        }
    }, [searchText])

    const handleInputChange = useCallback((value) => {
        setNewInputValue(value);
    }, []);

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => {
            if (option.itemType == "city") {
                return option.name
            } else {
                return option.city?.name + " " + (option && option.names && option.names != '' ? option.names?.fr : '')
            }
        },
    });

    return (
        <Autocomplete
            id="customer-asynchronous-demo"
            className='beachsearchcompo-cls-adding'
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            sx={{
                ...style,
            }}
            options={options}
            loading={loading}
            filterOptions={filterOptions}
            onChange={handleOnchangeCustomer}
            onInputChange={async (event, newInputValue, reason) => {
                if (reason === 'input') {
                    handleInputChange(newInputValue);
                }
            }}
            getOptionLabel={(option) => {
                if (option) {
                    if (option.itemType == "city") {
                        return option.name
                    } else {
                        return (option && option.names && option.names != '' ? option.names?.fr : '')
                    }
                }
                return null

            }}
            renderOption={(props, option, { selected }) => {
                if (option) {
                    const { image = [], names, city, name, itemType = null } = option;
                    let namesLatest = itemType == "city" ? name : (names ? names?.fr : name)
                    const imageUrl = itemType == "city" ? locationIcon : (image || [])[0]?.url;
                    let cityName = !itemType ? city?.name : null;

                    return (
                        <ListItem
                            {...props}
                            key={option.id}
                            disablePadding
                            disableGutters
                            dense={true}
                            sx={{
                                height: '60px',
                                borderRadius: '14px',
                            }}
                        >
                            <ListItemButton>
                                <ListItemAvatar>
                                    {itemType == "city" ? (
                                        <Button
                                            variant="outlined"
                                            startIcon={<PlaceIcon />}
                                            sx={
                                                {
                                                    maxWidth: "44px !important",
                                                    minWidth: "44px !important",
                                                    height: "44px",
                                                    background: "#eaedf8 !important",
                                                    border: "#eaedf8",
                                                    borderRadius: "6px",
                                                    "svg": {
                                                        marginLeft: "12px",
                                                        fill: "#000",
                                                        fontSize: "20px !important"
                                                    }
                                                }
                                            }
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: '43px',
                                                height: '43px',
                                                borderRadius: '4px',
                                            }}
                                            component="img"
                                            alt={namesLatest}
                                            src={imageUrl || aroundMeUrl}
                                        />
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ color: '#06133a' }}
                                    id={option.id}
                                    primaryTypographyProps={{
                                        fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                                        fontSize: "16px",
                                        color: "#06133a",
                                        fontWeight: 500
                                    }}
                                    primary={namesLatest}
                                    secondary={cityName}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        background: '#fff',
                        borderRadius: '19px',
                    }}
                    label={label}
                    {...shrink != null && {
                        InputLabelProps: { shrink: shrink }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
