import * as React from 'react';
import Card from '@mui/material/Card';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import SelectComponent from '../shared/SelectComponent';
import search from '../../assets/icons/search.svg';
import { useTranslation } from 'react-i18next';

export default function MyBookingFilter({ setFilter, filter, handleSearch, handleReset }) {
  const { t } = useTranslation('translation');

  const handleChange = (event, name) => {
    setFilter((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const filters = [
    { label: t('all'), value: 'all' },
    { label: t('active'), value: 'current' },
    { label: t('past'), value: 'completed' },
    { label: t('cancelled'), value: 'canceled' },
  ];
  const typeFilters = [
    { label: t('all'), value: 'all' },
    { label: 'Restaurant', value: 'restaurant' },
    { label: t('beach'), value: 'beach' },
  ];

  return (
    <Card className='my-booking-partner-cls-adding'>
        <Grid container direction={'row'} alignItems={'center'} py={2}>
            <Grid sx={{ ml: 1.5 }}>
                <Typography fontSize={'16px'} fontWeight={'600'} color={'#06133A'} align="left">
                    {t("filters")}:
                </Typography>
            </Grid>
            <Grid sx={{ ml: 1.5 }}>
                <FormControl sx={{ m: 1, minWidth: 240 }} size="medium">
                    <SelectComponent
                        options={filters}
                        value={filter?.type}
                        label={t("status")}
                        onChange={(e) => handleChange(e, 'type')}
                    />
                </FormControl>
            </Grid>
            <Grid>
                <FormControl sx={{ m: 1, minWidth: 240 }} size="medium">
                    <SelectComponent
                        options={typeFilters}
                        value={filter?.byStatus}
                        label={t("select")}
                        onChange={(e) => handleChange(e, 'byStatus')}
                    />
                </FormControl>
            </Grid>
            <Grid>
                <Box
                    onClick={handleSearch}
                    component="button"
                    sx={{
                        cursor: 'pointer',
                        bgcolor: '#ff8478',
                        p: '8px',
                        border: 'none',
                        outline: 'none',
                        borderRadius: '13px',
                        '&:hover': {
                            bgcolor: '#E55446',
                        },
                    }}
                >
                    <Box component="img" src={search} width="19px"></Box>
                </Box>
            </Grid>
        </Grid>
    </Card>
);
}
