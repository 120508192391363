/* eslint-disable array-callback-return */
import { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import DataTable from 'src/components/dataTable/DataTable';
import DeleteDialog from 'src/components/shared/DeleteDialog';
import ActionMoreMenu from 'src/components/shared/ActionMoreMenu';
import { deleteReservation, reservationTableDetail } from 'src/services/api/authentication';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import MyBookingFilter from 'src/components/my-booking/MyBookingFilter';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function MyBookingPage() {
    const [queryFilter, setQueryFilter] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [filter, setFilter] = useState({ type: '', byStatus: '' });
    const [isDeleted, setIsDeleted] = useState(false);
    const [isResDeleted, setIsResDeleted] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation('translation');

    const handleEdit = useCallback((row) => {
        navigate("/member/edit-booking/" + row?.id)
    }, [navigate]);

    const handleDelete = useCallback(
        (id, row) => {
            let isRestaurant = row?.restaurant_id || null
            setIsResDeleted(isRestaurant)
            setIsDeleted(true)
            setSelectedItem(id);
        },
        [setIsDeleted]
    );
    const refresh = useCallback(() => {
        handleDataTableChange(queryFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryFilter]);

    const findNumberOfPerson = useCallback((row) => {
        let seat = 0
        if (row.length > 0) {
            // eslint-disable-next-line array-callback-return
            row.map((ele) => {
                if (ele && ele.type) {
                    const seatNumber = ele.type.replace(/[^0-9]/g, "");
                    if (seatNumber) {
                        seat = seat + Number(seatNumber)
                    }
                }
            })
        }
        return seat
    }, [])

    const handleClickDelete = useCallback(async (id) => {
        const res = await deleteReservation(id, isResDeleted);
        if (res && res.status) {
            toast.success("Supprimer avec succès");
        }
        setIsResDeleted(false)
        setSelectedItem(null)
        setIsDeleted(null)
        refresh()
    },
        [refresh, isResDeleted]
    );

    const findSeaNumber = useCallback((ele, row = 0, col = 1) => {
        if (ele && ele.hasOwnProperty("halfday")) {
            const plan = ele && ele.plan ? JSON.parse(ele.plan)?.plan : [];
            if (plan && plan.length > 0) {
                const selectedRow = plan[row][col];
                if (selectedRow && selectedRow.id) {
                    return selectedRow.id
                } else {
                    return `[${(Number(row))}, ${(Number(col))}]`
                }
            }
            return `[${(Number(row))}, ${(Number(col))}]`
        }
        return null

    }, []);

    const columns = [
        // {
        //     name: 'id',
        //     label: 'Nº',
        //     isSearchable: true,
        //     isSortable: true,
        // },
        {
            name: 'customer.firstname',
            label: t("firstName"),
            isSearchable: false,
            isSortable: true,
        },
        {
            name: 'customer.lastname',
            label: t("name"),
            isSearchable: false,
            isSortable: true,
        },
        {
            name: 'beachName',
            label: filter.byStatus === "restaurant" ? t("restaurantName") : t("beachName"),
            isSearchable: false,
            isSortable: true,
        },
        {
            name: 'customer.phone',
            label: t("phone"),
            isSearchable: false,
            isSortable: true,
        },
        {
            name: 'date',
            label: 'Date',
            isSearchable: false,
            props: { align: 'center' },
            isSortable: true,
            render: (row) => row?.date ? moment(row.date).format("DD/MM/YYYY") : "-"
        },
        {
            ...filter.byStatus !== "restaurant" && (
                {
                    name: 'restaurant',
                    label: 'Restaurant',
                    isSearchable: false,
                    props: { align: 'center' },
                    isSortable: true,
                    render: (row) => {
                        if (row.restaurant === null || !row.restaurant) {
                            return "Non"
                        } else {
                            return "Oui"
                        }
                    }
                })
        },
        // {
        //     name: 'customer.comment',
        //     label: 'Commentaire client',
        //     isSortable: false,
        //     render: (row) =>
        //         <NoteColumn
        //             withSave={false}
        //             value={row?.customer?.comment || null}
        //             row={row}
        //         />,
        // },
        {
            key: 'details',
            label: t("detailsField"),
            isSearchable: false,
            isSortable: false,
            render: (row) => {
                const detailsData = row.details ? JSON.parse(row.details) : [];
                if (detailsData.length > 0) {
                    return (
                        <ul>
                            {detailsData.map((ele) => (
                                <li>
                                    {ele.type.startsWith('double_sunbed') && (
                                        <span>Bed </span>
                                    )}
                                    {ele.type.startsWith('sunbed') && (
                                        <span>Transat </span>
                                    )}
                                    {ele.status == "reserved_full_day" && (
                                        <span>journée </span>
                                    )}

                                    {ele.status == "reserved_half_day" && (
                                        <span>½ journée </span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )
                } else {
                    return null
                }
            },
        },
        {
            name: 'location',
            label: t("locationField"),
            isSearchable: false,
            isSortable: false,
            render: (row) => {
                const detailsData = row.details ? JSON.parse(row.details) : [];
                if (detailsData.length > 0) {
                    let i =0 ;
                    return detailsData.map((ele) => {
                        i++;
                        const number = findSeaNumber(row.beach, ele.position.x, ele.position.y);                        
                        return number ? `${number} ${detailsData.length !== i ? '/' : ''}` : '';
                    })
                } else {
                    return null
                }
            },
        },
        {
            name: 'total_price',
            label: t("totalPrice"),
            props: { align: 'center' },
            isSearchable: false,
            isSortable: true,
            render: (row) => `${row?.total_price || 0} €`
        },

        // {
        //     name: 'status',
        //     label: 'Statut',
        //     isSortable: false,
        //     render: (row) => {
        //         if (row.status === "in") {
        //             return (<span style={{ color: "green" }}>In</span>)
        //         }
        //         if (row.status === "out") {
        //             return <span style={{ color: "green" }}>Out</span>
        //         }
        //         if (row.status === "noshow") {
        //             return (<span style={{ color: "orange" }}>No show</span>)
        //         }
        //     }
        // },
        {
            ...filter.byStatus !== "beach" && (
                {
                    name: 'details',
                    label: 'Nombre de personnes',
                    isSearchable: false,
                    isSortable: false,
                    props: { align: 'center' },
                    render: (row) => {
                        const detailsData = row.details ? JSON.parse(row.details) : [];
                        if (detailsData.length > 0) {
                            return findNumberOfPerson(detailsData) > 0 ? findNumberOfPerson(detailsData) : null
                        } else {
                            return null
                        }
                    },
                })
        },
        {
            name: '',
            label: "Action",
            props: { align: 'center' },
            render: (row) => (
                <ActionMoreMenu
                    row={row}
                    onClickDelete={(id) => handleDelete(id, row)}
                    onClickEdit={handleEdit}
                />
            ),
        }
    ];

    const handleDataTableChange = useCallback(
        async (filter) => {
            setQueryFilter(filter);
            if (filter && filter.sort && filter.sortBy) {
                let orderByData = data
                orderByData = _.orderBy(orderByData, filter.sort, filter.sortBy);
                setData(orderByData);
            } else {
                const res = await reservationTableDetail({ ...filter });
                if (res && res.data && res.data) {
                    const updatedData = res.data.map((ele) => {
                        return { ...ele, beachName: ele?.beach?.name || ele?.restaurant?.name }
                    });
                    setData(updatedData);
                    setTotal(res.total)
                } else {
                    setData([])
                    setTotal(0)
                }
            }
        },
        [data]
    );

    const handleSearch = useCallback(() => {
        const filtersData = { ...queryFilter, ...filter }
        if (filtersData.type === "all") {
            delete filtersData.type
        }
        if (filtersData.byStatus === "all") {
            delete filtersData.byStatus
        }
        console.log(filtersData, 'filtersData');
        setQueryFilter(filtersData);
    }, [filter, queryFilter]);

    const handleReset = useCallback(() => {
        setFilter({ type: 'all', byStatus: 'all' })
    }, []);

    useEffect(() => {
        if (queryFilter) {
            handleDataTableChange(queryFilter);
        }
    }, [queryFilter]);

    return (
        <>
            <Helmet>
                <title> {t("myBooking")} | Beach Booker </title>
            </Helmet>
            <Container maxWidth={false} className='editbookingforming-clsadding-daterang-pcket-beach-booker-typo-cls-adding'>
                <Grid
                    className='grid-type-hereoseneseof-all-aded'
                    container
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{
                        minHeight: 'calc(100vh - 250px)',
                        py: 6,
                        px: 6,
                        width: "100%",
                        '@media (max-width: 650px)': {
                            py: 6,
                            px: 3
                        }
                    }}
                >
                    <Grid item xs={12} className='grid-type-hereoseneseof-all-adedtwelve'>
                        <Typography
                            className='grid-type-hereoseneseof-all-tyopth'
                            textAlign="center"
                            sx={{
                                fontSize: '40px',
                                color: '#06133A',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                fontFamily: 'TT Norms Pro Medium ,Helvetica,sans-serif!important',
                                fontHeight: 52,
                            }}
                        >
                            {t("myBooking")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ width: "100%", mt: -2 }} className='grid-type-hereoseneseof-all-newgrid'>
                        <MyBookingFilter
                            className='grid-type-hereoseneseof-all-mybooked'
                            setFilter={setFilter}
                            filter={filter}
                            handleSearch={handleSearch}
                            handleReset={handleReset}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ width: "100%" }} className='grid-type-hereoseneseof-all-elevengird'>
                        <DataTable
                            className='grid-type-hereoseneseof-all-tableresponsive'
                            data={data}
                            hasFilter={false}
                            columns={columns}
                            initialLoading
                            totalRow={total}
                            isPagination={false}
                            onChange={handleDataTableChange}
                        />
                    </Grid>
                    {isDeleted && (
                        <DeleteDialog
                            className='grid-type-hereoseneseof-all-datedialog'
                            title={t("removalReservation")}
                            message={t("removalReservationConfirm")}
                            onClose={() => {
                                setSelectedItem(null);
                                setIsDeleted(false)
                            }}
                            handleClickDelete={() => {
                                handleClickDelete(selectedItem)
                                setSelectedItem(null);
                                setIsDeleted(false)
                            }}
                        />
                    )}
                </Grid>
            </Container>
        </>
    );
}
