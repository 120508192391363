import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, IconButton, Menu, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { alpha } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export default function AccountPopover() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const { user, logout } = useAuth();
    const { t } = useTranslation('translation');

    const handleLogout = async () => {
        handleClose();
        try {
            logout();
        } catch (error) {
            sessionStorage.removeItem('x-auth');
            console.error(error);
        }
    };

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleMenuItemClick = (event, option) => {
        handleClose();
        navigate(option.linkTo);
    };

    const MENU_OPTIONS = [
        {
            label: t("myBooking"),
            icon: 'eva:home-fill',
            linkTo: `/member/${user?.id}/my-booking`,
        },
        {
            label: t("changePassword"),
            icon: 'eva:lock-outline',
            linkTo: `/member/change-password`,
        },
    ];

    if (user) {
        return (
            <>
                <Button
                    className='accoutn-poping-cls-adding'
                    variant="text"
                    size="small"
                    sx={{
                        mr: 5,
                        ml: 1,
                        px: 0,
                        minWidth: '90px !important',
                        width: '90px !important',
                        padding: '5px 10px',
                        height: '48px',
                        borderRadius: '14px',
                        color: '#06133a',
                        fontSize: '16px',
                        fontWeight: '700 !important',
                        fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                        '&:hover': {
                            background: '#e8edff',
                            transition: 'background-color .2s cubic-bezier(.4,0,.6,1)',
                        },
                    }}
                    onClick={() => navigate(`/member/${user?.id}/my-booking`)}
                >
                    {t("myBooks")}
                </Button>
                <IconButton
                    className='ics-brtn-cls-addinghj'
                    onClick={(e) => handleOpen(e)}
                    sx={{
                        p: 0,
                        ...(open && {
                            '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                            },
                        }),
                    }}
                >
                    <Avatar src={user?.photoURL} alt={user?.firstName} />
                </IconButton>

                <Popover
                    className='poping-cls-addigh-used'
                    open={open && Boolean(open)}
                    anchorEl={open}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    disablePortal={false}
                    disableScrollLock={true}
                    PaperProps={{
                        sx: {
                            p: 0,
                            mt: 1,
                            mr: 0,
                            width: 250,
                            '& .MuiMenuItem-root': {
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >
                    <Box sx={{ my: 1.5, px: 2.5 }} className="typo-boxing-cls-adding-used">
                        <Typography variant="subtitle2" noWrap>
                            {user?.firstName}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                            {user?.email}
                        </Typography>
                    </Box>

                    <Divider sx={{ borderStyle: 'dashed' }} className='divider-sx-adding' />

                    <Stack sx={{ p: 1 }}>
                        {MENU_OPTIONS.map((option, index) => (
                            <MenuItem key={option.label} onClick={(event) => handleMenuItemClick(event, option)} className='menuitem-cls-adding-account-opover'>
                                <Box
                                    key={option.label + index}
                                    component={Icon}
                                    icon={option.icon}
                                    sx={{
                                        mr: 1,
                                        width: 24,
                                        height: 24,
                                        color: '#637381',
                                    }}
                                />
                                <Typography color="#637381">{option.label}</Typography>
                            </MenuItem>
                        ))}
                    </Stack>
                    <Divider sx={{ borderStyle: 'dashed' }} className='accoutn-poping-cls-adding-used' />
                    <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                        <Box
                            className='account-pooinglc-ssadding-csl'
                            component={Icon}
                            icon={'eva:power-fill'}
                            sx={{
                                mr: 1,
                                width: 24,
                                height: 24,
                                color: '#637381',
                            }}
                        />
                        <Typography color="#637381">Se déconnecter</Typography>
                    </MenuItem>
                </Popover>
            </>
        );
    } else {
        return (
            <Button
                className='account-using-poping-cls-klo'
                variant="text"
                size="small"
                sx={{
                    mr: 5,
                    px: 0,
                    minWidth: '125px !important',
                    width: '125px !important',
                    padding: '0px 10px',
                    height: '48px',
                    borderRadius: '14px',
                    color: '#06133a',
                    fontSize: '16px',
                    fontWeight: '700 !important',
                    fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                    '&:hover': {
                        background: '#e8edff',
                        transition: 'background-color .2s cubic-bezier(.4,0,.6,1)',
                    },
                }}
                onClick={() => navigate(`/login`)}
            >
                Se connecter
            </Button>
        );
    }
}

AccountPopover.propTypes = {
    onOpenNav: PropTypes.func,
};
