import { renderToStaticMarkup } from 'react-dom/server'
import { divIcon } from 'leaflet'

export const getRequiredSVGPinByCategory = (address, handleOnClickMarker) => {

    const iconMarkup = renderToStaticMarkup(
        <span
            className="finder-map__marker__icon text-body-2 font-weight-bold listed-located-offing-type">
            <span
                className="
        finder-map__marker__icon__title
        text-body-2
        font-weight-bold
      "
            >
                {address?.name || ''}
            </span>
        </span>
    )
    const customMarketIcon = divIcon({
        className: 'custom-div-icon',
        html: iconMarkup,
        iconSize: [10, 10],
        iconAnchor: [0, 0],
    })
    return customMarketIcon
}