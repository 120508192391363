import { useEffect, useState } from 'react';
/* eslint-disable array-callback-return */
import { CircularProgress, Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { reservationDetail } from 'src/services/api/authentication';
import EditTab from './EditTab';
import { getPlaceDetails } from 'src/services/api/admin.api';


export default function EditBookingPage() {
    const { id = null } = useParams();
    const [details, setDetails] = useState(null);
    const [hutId, setHutId] = useState(null);
    const [loading, setLoading] = useState(false);

    const getReservationData = async (id) => {
        setLoading(true)
        const res = await reservationDetail(id);
        if (res.status && res.data) {
            setLoading(false);
            let hutIdData = null
            console.log(res.data, 'res.data ------------------');
            if (res.data && res.data.reservation_beach) {
                hutIdData = res.data.reservation_beach?.beach?.hutId
            } else if (res.data && res.data.reservation_restaurant && res.data.reservation_restaurant.restaurant && res.data.reservation_restaurant.restaurant.hut_id) {
                hutIdData = res.data.reservation_restaurant.restaurant.hut_id
            }
            let beachData;
            if (hutIdData) {
                console.log(hutIdData, 'hutIdData -----------');
                beachData = await getPlaceDetails(hutIdData);
            }
            setHutId(hutIdData);
            setDetails({ ...res.data, price_lists: beachData?.data?.price_lists});
        } else {
            setLoading(false)
            setDetails(null);
        }
    }

    useEffect(() => {
        if (id) {
            getReservationData(id);
        }
    }, [id]);
    return (
        <>
            <Helmet>
                <title> Edit Booking | Beach Booker </title>
            </Helmet>
            <Container maxWidth={false} className='editbookingforming-clsadding-daterang-pcketcontainer'>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    spacing={2}
                    sx={{ py: 4 }}
                >
                    <Grid item xs={12}>
                        {details && (
                            <EditTab details={details} />
                        )}
                        {loading && (
                            <CircularProgress />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
