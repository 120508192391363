import { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, FormControl, Grid, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { forgotPassword } from 'src/services/api/authentication';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';
import SentIcon from 'src/assets/icons/icon_sent';

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
    const ResetPasswordSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    });

    const [email, setEmail] = useState('');

    const [sent, setSent] = useState(false);

    const { t } = useTranslation('translation');

    const defaultValuesData = {
        email: '',
    };

    const handleSubmit = useCallback(
        async (values, actions) => {
            try {
                actions.setSubmitting(true);
                setEmail(values?.email);
                await forgotPassword(values)
                    .then(async (res) => {
                        if (res && res.status && res.data) {
                            toast.success(res.message);
                            setSent(true)
                        } else {
                            toast.error(res.message);
                        }
                    }).catch((err) => {
                        actions.setSubmitting(false);
                        if (err) {
                            toast.error(t('somethingWentWrong'));
                        }
                    });
                actions.setSubmitting(false);
            } catch (error) {
                actions.setSubmitting(false);
                toast.error(error?.response?.data?.message?.[0] || t('somethingWentWrong'));
            }
        },
        [t]
    );

    return (

        <RootStyle>
            <Container>
                <Box sx={{ maxWidth: 480, mx: 'auto' }}>
                    {!sent ? (
                        <>
                            <Typography variant="h3" paragraph>
                                {t('forgotPasswordTitle')}
                            </Typography>

                            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                                {t('forgotPasswordSubTitle')}
                            </Typography>

                            <Grid container direction={'row'} sx={{ mt: 0, mb: 0 }} className='login-formcls'>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Formik
                                        enableReinitialize
                                        validationSchema={ResetPasswordSchema}
                                        onSubmit={handleSubmit}
                                        initialValues={defaultValuesData}
                                        validateOnBlur={true}
                                        validateOnChange={true}
                                    >
                                        {({ errors }) => (
                                            <Form autoComplete="off" noValidate>
                                                <Stack
                                                    spacing={3}
                                                    sx={{
                                                        mt: 2,
                                                        width: '600px',
                                                        maxWidth: '600px',
                                                        '@media (max-width: 650px) ': {
                                                            width: '80vw',
                                                        },
                                                    }}
                                                >
                                                    <Stack direction="row">
                                                        <FormControl fullWidth>
                                                            <Field
                                                                component={TextField}
                                                                name="email"
                                                                fullWidth
                                                                placeholder={t('email')}
                                                                label={t('email')}
                                                                required
                                                            />
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} justifyContent={'center'}>
                                                        <Button size="large" variant="contained" type="submit">
                                                            {t('confirm')}
                                                        </Button>
                                                    </Stack>

                                                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                                                        <Button
                                                            fullWidth
                                                            size="small"
                                                            variant="text"
                                                            component={RouterLink}
                                                            to={"/login"}
                                                            sx={{ mt: 1, width: "15px" }}
                                                        >
                                                            {t("backButton")}
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Box sx={{ textAlign: 'center', justifyContent: "center" }}>
                            <SentIcon sx={{ mb: 2, mx: 'auto', height: 60 }} />

                            <Typography variant="h4" sx={{ color: 'text.secondary' }}>
                                {t("requestSuccess")}
                            </Typography>

                            <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: "center" }}>
                                {t("successConfirm")} &nbsp;
                                <strong>{email}</strong>
                                <br />
                                {t("successConfirmEmail")}
                            </Typography>

                            <Button
                                size="large"
                                variant="contained"
                                component={RouterLink}
                                to={"/login"}
                                sx={{ mt: 5 }}
                            >
                                {t("backButton")}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </RootStyle>
    );
}
