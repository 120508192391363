import { ErrorMessage } from 'formik';
import { useCallback } from 'react';
import { Box } from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const PhoneField = ({
    form: { setFieldValue, errors, touched, handleChange },
    field: { name, value },
    label,
    placeholder,
    limit = 110,
    ...props
}) => {

    const onChange = useCallback(
        (e) => {

            setFieldValue(name, e);
        },
        [setFieldValue, name]
    );

    return (
        <div className='phone-field-addingcls'>
        <Box sx={{ width: '100%' }}>
            <PhoneInput
                width={"100%"}
                country={'fr'}
                fullWidth={true}
                label={label}
                specialLabel={label}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                enableSearch={true}
            />
            <ErrorMessage
                name={name}
                component="div"
                className="invalid-feedback"
            />
        </Box>
        </div>
    );
}

export default PhoneField;