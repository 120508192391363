import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Box, Typography, styled } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { editPayment, setupPayment } from 'src/services/api/admin.api';
import CheckoutForm from '../Booking/sections/CheckoutForm';
import { PaymentStatusEnum } from 'src/types/common.type';
import PaymentBackButton from '../Booking/sections/shared/PaymentBackButton';
import PaymentMainTitle from '../Booking/sections/shared/PaymentMainTitle';
import { useAuth } from 'src/context/AuthContext';

const Layout = styled(Box)(({ theme }) => ({
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
        width: '60%',
        margin: '0',
    },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function EditPayment({
    id: hutId,
    userData,
    totalAmount,
    prevTab,
    restaurantDetails,
    beachDetails,
    setIsCheckedCondition,
    isCheckedCondition,
    invoiceDetails,
    date,
    details,
    setSaveLoading,
    saveLoading = false,
    paidAmount
}) {
    const [paymentIntent, setPaymentIntent] = useState({});
    const { client_secret: clientSecret, id } = paymentIntent || {};
    const navigate = useNavigate();
    const { user } = useAuth()
    const setupIntentPayment = async () => {
        const res = await setupPayment();
        if (res) {
            console.log('card payment is : ', res);
            setPaymentIntent(res.data);
        }
    };

    const createBeachBookingPaymet = async ({ cardTokenId }) => {
        const formData = new FormData();
        let dateStart = moment(date?.startDate).format('YYYY-MM-DD');
        let dateEnd = moment(date?.endDate).format('YYYY-MM-DD');
        const ordersData = {
            state: PaymentStatusEnum.AWAITING_PAYMENT,
            dateStart: dateStart,
            dateEnd: dateEnd,
            completeReason: '',
            commentClient: '',
            commentAdmin: '',
            contactEmail: userData?.email,
            contactPhone: userData?.phone,
            contactName: userData?.firstname,
            contactLastName: userData?.lastname,
            locale: 'fr',
            stripeCouponId: '',
            stripePaymentIntentId: '',
            stripePaymentStatus: '',
            paidAt: '',
            completedAt: '',
            hutId: details?.reservation_beach?.beach?.hutId,
            noShowPaid: '',
            stripeSetupIntentId: '',
            details: '',
            date: '',
            comment: '',
            status: '',
            source: '',
            customerId: '',
            beachId: '',
            restaurant: true,
            orderItems: [],
            paidAmount: paidAmount
        };

        formData.append('clientOrderAmount', totalAmount);
        formData.append('cardTokenId', cardTokenId);
        formData.append('currency', 'usd');
        formData.append('order', JSON.stringify(ordersData));
        formData.append('invoiceDetails', JSON.stringify(invoiceDetails));
        if (restaurantDetails) {
            formData.append('restaurantDetails', JSON.stringify(restaurantDetails));
        }
        if (beachDetails) {
            formData.append('beachDetails', JSON.stringify(beachDetails));
        }
        try {
            setSaveLoading(true);
            const res = await editPayment(formData, details?.reservation_beach?.id);
            setSaveLoading(false);
            if (res?.status) {
                alert(res?.message);
                navigate(`/member/${user?.id}/my-booking`);
            }
        } catch (err) {
            return alert(err.message || err.error || err);
        }
    };

    useEffect(() => {
        setupIntentPayment();
    }, [date, hutId]);

    const options = {
        clientSecret: `${id}_secret_${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
    };

    return (
        <Layout className='payment-btn-cls-adding-title-offing-cls-element-checkform'>
            <PaymentBackButton prevTab={prevTab} />
            <PaymentMainTitle
                title="Paiement"
                subTitle={
                    'Le règlement est à effectuer directement chez le prestataire. Nous prélevons uniquement les frais de service et une autorisation bancaire en cas de non annulation conformément aux CGV.'
                }
            />
            <hr style={{ border: '1px solid #EAEDF8', margin: '20px 0' }} />
            <Typography
                color="#000"
                fontSize="22px"
                fontWeight="500"
                fontFamily={'"TT Norms Pro Medium",Helvetica,sans-serif!important'}
            >
                Entrez vos coordonnées
            </Typography>
            <div style={{ color: 'black' }} className="stripe-card-form">
                {clientSecret && id ? (
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm
                            options={options}
                            createBeachBookingPaymet={createBeachBookingPaymet}
                            isCheckedCondition={isCheckedCondition}
                            setIsCheckedCondition={setIsCheckedCondition}
                            saveLoading={saveLoading}
                        />
                    </Elements>
                ) : null}
            </div>
        </Layout>
    );
}
