import { Typography, Box, Container, Grid, Divider, Link, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FooterLink = ({ text, handleOnClick, isDisabled }) => {
  return (
    <Stack direction={'row'} className='stack-link-footering-cls-adding-offing'>
      <Link
        className='footer-btn-cls-adding-type-of-cls-o'
        component="button"
        onClick={handleOnClick}
        color={'#fff'}
        sx={{
          color: isDisabled ? 'gray' : '',
          marginTop: 2,
          fontSize: '1.3rem',
          fontWeight: 500,
          textDecoration: 'underline',
          fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif !important',
          fontStyle: 'normal',
          lineHeight: '20px',
          mx: {
            md: 'auto',
            xs: 'none',
            sm: 'auto',
          },
          '@media (max-width: 650px)': {
            mx: 'none',
          },
        }}
      >
        {text}
      </Link>
    </Stack>
  );
};

const FooterHeaderText = ({ text }) => {
  return (
    <Typography
      // border="1px solid red"
      mt={{ lg: '-3rem', xs: '0rem' }}
      color="text.primary"
      gutterBottom
      sx={{
        fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif !important',
        textAlign: 'center',
        fontSize: '1.3rem !important',
        fontWeight: 'bolder !important',
        textTransform: 'uppercase',
        color: '#fff !important',
        lineHeight: '22px',
        fontStyle: 'normal',
      }}
    >
      {text}
    </Typography>
  );
};

export default function Footer() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const footerT = useTranslation('translation', { keyPrefix: 'footer' });

  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
  };

  const downloadFile = () => {
    window.location.href = '/assets/pdf/CGV_Beach_Booker.pdf';
  };

  return (
    <Box
      className="footer-part-handle"
      sx={{
        background: '#1a1919',
        pb: 6,
      }}
    >
      <Box
        className="footer-part-handles"
        sx={{
          flexGrow: 1,
          justifyContent: 'space-between',
          display: 'flex',
          background: '#1a1919',
          color: '#fff',
        }}
      >
        <Container
          className="footer-part-handles-add"
          maxWidth={'false'}
          component="footer"
          sx={{
            mt: 0,
            pt: 3,
          }}
        >
          <Grid
            className="footer-part-handles-add-premium"
            container
            sx={{
              py: 2,
              mb: 3,
              justifyContent: {
                xs: 'start',
                sm: 'start',
                md: 'space-evenly',
              },
            }}
          >
            <Grid
              className="footer-part-handles-add-premium-opi"
              sx={{
                width: { sm: '50%', xs: '100%', md: 'auto' },
                '@media (max-width: 650px)': {
                  width: '100%',
                },
              }}
              item
              maxWidth="300px"
            >
              <Box component="img" alt="logo" src="/assets/logo-footer.png" />
              <Typography
                className="footer-part-handles-add-premium-typo"
                fontSize={'1.15rem'}
                mt="6px"
                mb="15px"
                fontFamily='"TT Norms Pro Regular",Helvetica,sans-serif!important'
              >
                contact@beachbooker.fr
              </Typography>
              <Typography fontSize={'1.15rem'} fontFamily='"TT Norms Pro Regular",Helvetica,sans-serif!important' className="footer-part-handles-add-premium-poyuio">
                1095 rue Henri Becquerel, 34000 Montpellier
              </Typography>
            </Grid>
            <Divider
              className="footer-part-handles-add-premium-divider"
              sx={{
                display: { sm: 'block', xs: 'none', md: 'block' },
                '@media (max-width: 650px)': {
                  display: 'none',
                },
              }}
              color="#fff"
              orientation="vertical"
              flexItem
            ></Divider>
            <Grid
              className="footer-part-handles-add-premium-divider-grdign"
              item
              sx={{
                marginTop: { sm: '50px', md: 'none' },
                width: { sm: '50%', xs: '100%', md: 'auto' },
                '@media (max-width: 650px)': {
                  width: '100%',
                  marginTop: '50px',
                  justifyContent: 'flex-start',
                  display: 'grid',
                },
              }}
            >
              <FooterHeaderText text={footerT.t('community')} className="footer-part-handles-add-premium-divider" />
              <FooterLink
                className="footer-part-handles-add-premium-divider-link-text"
                text={footerT.t('contactUs')}
                handleOnClick={() => navigate('/contact')}
                isDisabled={window.location.pathname === '/contact'}
              />
              <FooterLink text={'CGV'} handleOnClick={downloadFile} className="footer-part-handles-add-premium-divider-link-text-download" />
            </Grid>
            <Divider
              className="footer-part-handles-add-premium-divider-link-text-all-the-side"
              sx={{
                display: { sm: 'none', xs: 'none', md: 'block' },
                '@media (max-width: 650px)': {
                  display: 'none',
                },
              }}
              color="#fff"
              orientation="vertical"
              flexItem
            ></Divider>
            <Grid
              className="footer-part-handles-add-premium-divider-link-textmap"
              sx={{
                marginTop: { sm: '50px', md: 'none' },
                width: { sm: '50%', xs: '100%', md: 'auto' },
                '@media (max-width: 650px)': {
                  width: '100%',
                  marginTop: '50px',
                  display: 'grid',
                  justifyContent: 'flex-start',
                },
                color: 'red',
              }}
              item
            >
              <FooterHeaderText text={footerT.t('professional')} className="footer-part-handles-add-premium-divider-link-textmap-professional" />
              <FooterLink
                className="footer-part-handles-add-premium-divider-link-textmap-footer-link-professional"
                text={footerT.t('becomePartner')}
                handleOnClick={() => navigate('/partner')}
                isDisabled={window.location.pathname === '/partner'}
              />
            </Grid>
            <Divider
              className="footer-part-handles-add-premium-divider-link-textmap-blocking"
              sx={{
                display: { sm: 'block', xs: 'none', md: 'block' },
                '@media (max-width: 650px)': {
                  display: 'none',
                },
              }}
              color="#fff"
              orientation="vertical"
              flexItem
            ></Divider>
            <Grid
              className="footer-part-handles-add-premium-divider-link-textmap"
              item
              sx={{
                marginTop: { sm: '50px' },
                width: { sm: '48%', xs: '100%', md: 'auto' },
                '@media (max-width: 650px)': {
                  marginTop: '50px',
                  display: 'grid',
                  justifyContent: 'flex-start',
                },
              }}
            >
              <FooterHeaderText text={footerT.t('social')} className="footer-part-handles-add-premium-divider-link-textmap-social" />
              <Stack direction={'row'} mx="auto" my="2" sx={{ mt: 2 }} width="fit-content" spacing={2} className="footer-part-handles-add-premium-divider-link-stackdirection">
                <Box
                  className="footer-part-handles-add-premium-divider-link-textmap-social-box"
                  onClick={() => window.open('https://www.instagram.com/beachbookerfrance/', '_blank')}
                  component="img"
                  alt="instagram"
                  src="/assets/images/instagram.png"
                  sx={{ height: '50px', weight: '50px', cursor: 'pointer' }}
                />
                <Box
                  className="footer-part-handles-add-premium-divider-link-textmap-social-boxing-social"
                  onClick={() => window.open('https://www.facebook.com/beachbookerfrance', '_blank')}
                  component="img"
                  sx={{ height: '50px', weight: '50px', cursor: 'pointer' }}
                  alt="facebook"
                  src="/assets/images/facebook.png"
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container className="footer-part-handles-add-premium-divider-link-textmap-social-grid-container">
            <Grid item xs={12} className="footer-part-handles-add-premium-divider-link-textmap-grid-column">
              <Divider color="#fff" />
            </Grid>
            <Grid container sx={{ mt: 4 }} justifyContent="space-between" className="footer-part-handles-add-premium-divider-link-textmap-spacingmax">
              <Grid item className="footer-part-handles-add-premium-divider-link-textmap-social-booker">
                <Typography fontSize={'14px'} fontFamily={'"TT Norms Pro Regular",Helvetica,sans-serif!important'}>
                  © Beach Booker, 2022 - {footerT.t('rightsReserved')}
                </Typography>
                <div className='flexing-area'>
                  <select className="custom-select selection-opton" style={{ width: 80 }} onChange={changeLanguageHandler}>
                    <option value="fr">French</option>
                    <option value="en">English</option>
                  </select>
                </div>
              </Grid>
              <Grid item className="footer-part-handles-add-premium-divider-link-textmap-social-typo">
                <Typography fontSize={'14px'} fontFamily={'"TT Norms Pro Regular",Helvetica,sans-serif!important'} className='griding-off-cls'>
                  {footerT.t('policy')} -
                  <div className='breakuio'>
                    <a
                      className='typo-link-type-text-here-adding-used-off'
                      href="/assets/pdf/Mentions_Legales_Beach_Booker.pdf"
                      target="_blank"
                      download
                      style={{
                        color: '#F8F4F8',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        fontWeight: '500',
                        fontSize: '21px',
                        textAlign: 'right',
                        outline: 'none',
                      }}
                    >
                      {footerT.t('notice')}
                    </a>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
