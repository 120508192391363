/* eslint-disable consistent-return */
import { userLogin, getUserDetails, changeUserPassword } from "./api/user.api";


const authLogin = async (body) => {
    try {
        const res = await userLogin(body);
        return res;
    } catch (e) {
        console.log(e, "e");
    }
};

const getUserMe = async (userId) => {
    try {
        const res = await getUserDetails(userId);
        return res;
    } catch (e) {
        console.log(e, "e");
    }
};

const changePassword = async (body) => {
    try {
        const res = await changeUserPassword(body);
        return res;
    } catch (err) {
        if (err.response && err.response?.data?.data.length > 0) {
            return { message: Object.values(err.response.data.data[0])?.[0]?.toString() };
        } else {
            return { message: err.message }
        }
    }
};



export {
    authLogin,
    getUserMe,
    changePassword
};