/* eslint-disable array-callback-return */
import { Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RegisterForm from 'src/components/Authentication/RegisterForm';


export default function RegisterPage() {
    const { t } = useTranslation('translation');

    return (
        <>
            <Helmet>
                <title> {t("register")} | Beach Booker </title>
            </Helmet>
            <Container maxWidth="lg" sx={{ px: 2, pt: 5, pb: 15 }} className='editbookingforming-clsadding-daterang-register-cls-adding-topbottom'>
                <Typography
                    textAlign="center"
                    sx={{
                        fontSize: '40px',
                        color: '#06133A',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontFamily: 'TT Norms Pro Medium ,Helvetica,sans-serif!important',
                        fontHeight: 52,
                    }}
                >
                    {t("register")}
                </Typography>
                <Typography
                    textAlign="center"
                    fontFamily={'TT Norms Pro Medium ,Helvetica,sans-serif!important'}
                    sx={{
                        color: '#06133A',
                        mt: 1
                    }}>
                    {t("alreadyHaveAccount")} <Link to="/login">{t("login")}</Link>
                </Typography>

                <RegisterForm />
            </Container>
        </>
    );
}
