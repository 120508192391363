import React, { useCallback, useEffect, useState } from 'react';
import FinderHeader from './sections/FinderHeader';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { getCityNames, getFinderSearch } from 'src/services/api/filter.api';
import _ from 'lodash';
import { Box } from '@mui/material';
import Beaches from './sections/Beaches';
import FinderMap from './sections/FinderMap';
import { toast } from 'react-toastify';
import useDynamicRefs from 'use-dynamic-refs';
import { distance } from 'src/utils/common';
import moment from 'moment';

export default function FinderPage(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastFetchedItemsLength, setLastFetchedItemsLength] = useState(0);
  const [getRef, setRef] = useDynamicRefs();
  const [selectedSearchItem, setSelectedSearchItem] = useState({ searchItem: '', date: new Date() });
  const [noMoreData, setNoMoreData] = useState(true);
  const params = Object.fromEntries([...searchParams]);
  const [changedCoords, setChangedCoords] = useState({ lat: 51.505, lng: 0.09 });
  const [isFwidth, setIsFwidth] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isMapSearch, setIsMapSearch] = useState(false);

  const hasBoundsInUrl = () => {
    return !![params.swlat, params.swlng, params.nelat, params.nelng].find((i) => i !== undefined);
  };
  const getCurrentLocation = (options = {}) => {
    const _isAvailable = 'geolocation' in window.navigator;
    if (!_isAvailable) {
      toast.warning('Le navigateur ne prend pas en charge la géolocalisation');
    }
    return new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords);
        },
        () => {
          reject(new Error("Pas 'accès à la géolocalisation"));
        },
        options
      );
    });
  };

  const autoSetCurrentLocation = async () => {
    return await getCurrentLocation()
      .then(async (coords) => {
        await setCurrentLocation([coords.latitude, coords.longitude]);
        return [coords.latitude, coords.longitude];
      })
      .catch((err) => {
        setCurrentLocation(null);
        toast.warning(err?.message);
        return null;
      });
  };

  const fetchCities = async () => {
    let curLocation;
    if (!currentLocation) {
      curLocation = await autoSetCurrentLocation();
    }
    const res = await getCityNames({ sort: 'name' });
    let cityData = [];
    if (res.status && res.data) {
      cityData = res?.data;
    }
    await setCities(cityData);
    await fetchItems({}, cityData, curLocation);
  };

  const PAGE_SIZE = 50;
  const getAllData = async (query) => {
    const res = await getFinderSearch(query);
    setLoading(false);
  }

  const fetchItems = async (opts, citiesData, curLocation, called = 1) => {
    let latestLocation = curLocation ? curLocation : currentLocation;
    let citiesDataLatest = citiesData ? citiesData : cities;
    setLoading(true);
    setDisabled(true);

    const isAddToEnd = false; //params.searchsrc !== SearchFormDataSearchSrc.map && (params.page || 0) >= 2
    let paramQuery = params;
    if (opts && opts.latestParam) {
      paramQuery = opts.latestParam;
    }
    const query = {
      include: ['city', 'placeServices', 'imageFiles', '_beachesStats'],
      excludeIds: isAddToEnd ? items.map((itm) => itm.id) : undefined,
      cityId: undefined,
      dfrom: undefined,
      what: undefined,
      swlat: undefined,
      swlng: undefined,
      nelat: undefined,
      nelng: undefined,
      aroundMeLng: undefined,
      aroundMeLat: undefined,
      order: [['id', 'ASC']],
      count: PAGE_SIZE,
      offset: paramQuery.offset || 0,
    };

    if ((paramQuery.dfrom || '0') !== '0') {
      query.dfrom = paramQuery.dfrom;
    }

    if ((paramQuery.dend || '0') !== '0') {
      query.dend = paramQuery.dend;
    }

    if (paramQuery.is_restaurant) {
      query.is_restaurant = paramQuery.is_restaurant;
    }

    if ((paramQuery.what?.length || 0) > 0) {
      query.what = paramQuery.what;
    }
    if (!paramQuery.mapsearch && paramQuery.city && paramQuery.city !== 'all') {
      if (paramQuery.city != 'around_me') {
        const selectedCity = citiesDataLatest.find((c) => c.key === paramQuery.city);
        query.cityId = selectedCity?.id;
        setSelectedSearchItem({
          ...selectedSearchItem,
          searchItem: { ...selectedCity, itemType: 'city' },
          date: paramQuery.dfrom ? moment(paramQuery.dfrom).toDate() : moment().toDate(),
        });
      }
    } else {
      let selectedCity = citiesDataLatest[0];
      query.cityId = selectedCity?.id;
      setSelectedSearchItem({
        ...selectedSearchItem,
        searchItem: { ...selectedCity, itemType: 'city' },
        date: paramQuery.dfrom ? moment(paramQuery.dfrom).toDate() : moment().toDate(),
      });
    }
    if (!opts?.mapSearchDisabled && paramQuery.mapsearch && hasBoundsInUrl()) {
      query.swlat = paramQuery.swlat;
      query.swlng = paramQuery.swlng;
      query.nelat = paramQuery.nelat;
      query.nelng = paramQuery.nelng;
    }
    if (paramQuery.city === 'around_me' && latestLocation) {
      query.lat = latestLocation[0] || '';
      query.lng = latestLocation[1] || '';
      query.distance = 3840;
      setSearchParams({
        lat: query.lat,
        lng: query.lng,
        distance: 3840,
        ...(paramQuery.dfrom && { dfrom: paramQuery.dfrom }),
        ...(paramQuery.is_restaurant && { is_restaurant: paramQuery.is_restaurant }),
      });
    }

    if (paramQuery.lat && paramQuery.lng && paramQuery.mapsearch) {
      query.lat = paramQuery.lat;
      query.lng = paramQuery.lng;
      query.distance = 3840;

      setSearchParams({
        lat: paramQuery.lat,
        lng: paramQuery.lng,
        distance: 3840,
        ...(paramQuery.dfrom && { dfrom: paramQuery.dfrom }),
        ...(paramQuery.is_restaurant && { is_restaurant: paramQuery.is_restaurant }),
      });
    }

    const res = await getFinderSearch(query);
    console.log(res, 'res');
    setLoading(false);
    if (res && res.status && res.data.length > 0) {
      let latestData = [...res.data].map((ele) => {
        let lngLatPoint = ele.geometry ? JSON.parse(ele.geometry) : null;
        if (lngLatPoint) {
          let _distanceKm = null;
          if (latestLocation) {
            _distanceKm = distance(
              latestLocation[0],
              latestLocation[1],
              lngLatPoint.coordinates[0],
              lngLatPoint.coordinates[1]
            );
            _distanceKm = `${_distanceKm}km .`;
          }
          return {
            ...ele,
            lngLatPoint: lngLatPoint,
            _distanceKm: _distanceKm,
            name: ele.names ? ele.names?.fr : null,
          };
        }
      });
      console.log(latestData, "latestData 1");
      latestData = latestData.filter(function (element) {
        return element !== undefined;
      });

      console.log(latestData, "latestData 2");
      if (latestLocation && latestData.length === 0) {
        latestData = latestData = [...res.data].map((ele) => {
          let lngLatPoint = ele.geometry ? JSON.parse(ele.geometry) : null;
          return {
            ...ele,
            lngLatPoint: lngLatPoint,
            _distanceKm: null,
            name: ele.names ? ele.names?.fr : null,
          };
        });
      }

      console.log(latestData, "latestData 3");
      // if (latestData.length === 0) {
      //   await getAllData(query);
      // }

      const newItems = _.orderBy(latestData || [], ['_beachesStats.totalFree', 'name'], ['desc', 'asc']);

      if (opts && opts.isReset) {
        const existingData = [...newItems];
        setLastFetchedItemsLength(existingData.length);
        setItems(existingData);
        setChangedCoords({
          lat: existingData[0]?.lngLatPoint?.coordinates[0],
          lng: existingData[0]?.lngLatPoint?.coordinates[1],
        });
      } else {
        const existingData = [...items, ...newItems];
        setLastFetchedItemsLength(existingData.length);
        setItems(existingData);
      }
      setDisabled(false);
    } else {
      setItems([]);
      setLastFetchedItemsLength(0);
      setNoMoreData(false);
      setDisabled(false);
      await featUpdateAllData(query, opts, latestLocation);
    }
  };

  const featUpdateAllData = async (query, opts, latestLocation) => {
    console.log(query, 'query');
    delete query?.cityId
    const res = await getFinderSearch(query);
    setLoading(false);
    if (res && res.status && res.data.length > 0) {
      let latestData = [...res.data].map((ele) => {
        let lngLatPoint = ele.geometry ? JSON.parse(ele.geometry) : null;
        if (lngLatPoint) {
          let _distanceKm = null;
          if (latestLocation) {
            _distanceKm = distance(
              latestLocation[0],
              latestLocation[1],
              lngLatPoint.coordinates[0],
              lngLatPoint.coordinates[1]
            );
            _distanceKm = `${_distanceKm}km .`;
          }
          return {
            ...ele,
            lngLatPoint: lngLatPoint,
            _distanceKm: _distanceKm,
            name: ele.names ? ele.names?.fr : null,
          };
        }
      });

      latestData = latestData.filter(function (element) {
        return element !== undefined;
      });

      console.log(latestData, "latestData 2");
      if (latestLocation && latestData.length === 0) {
        latestData = latestData = [...res.data].map((ele) => {
          let lngLatPoint = ele.geometry ? JSON.parse(ele.geometry) : null;
          return {
            ...ele,
            lngLatPoint: lngLatPoint,
            _distanceKm: null,
            name: ele.names ? ele.names?.fr : null,
          };
        });
      }

      const newItems = _.orderBy(latestData || [], ['_beachesStats.totalFree', 'name'], ['desc', 'asc']);

      if (opts && opts.isReset) {
        const existingData = [...newItems];
        console.log(existingData, 'existingData 1');
        setLastFetchedItemsLength(existingData.length);
        setItems(existingData);
        setChangedCoords({
          lat: existingData[0]?.lngLatPoint?.coordinates[0],
          lng: existingData[0]?.lngLatPoint?.coordinates[1],
        });
      } else {
        const existingData = [...items, ...newItems];
        console.log(existingData, 'existingData 2');
        setLastFetchedItemsLength(existingData.length);
        setItems(existingData);
      }
      setDisabled(false);
    }
  }

  useEffect(() => {
    fetchCities();
  }, []);

  const handleClickScroll = (id) => {
    if (getRef(id) && getRef(id).current) {
      getRef(id).current.classList.add('active-beach');
      getRef(id).current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleLoadMoreData = useCallback(() => {
    if (params.offset) {
      let latestOffset = parseInt(params.offset) + PAGE_SIZE;
      setSearchParams({ ...params, count: PAGE_SIZE, offset: latestOffset });
      fetchItems();
    } else {
      setSearchParams({ ...params, count: PAGE_SIZE, offset: 0 });
    }
  }, [setSearchParams, params]);

  const refreshPageData = useCallback(
    (item) => {
      let latestParam = {
        ...params,
        count: PAGE_SIZE,
        offset: 0,
        city: item?.selectedCityValue?.key,
        dfrom: moment(item?.startDate).format('YYYY-MM-DD'),
        dend: moment(item?.endDate).format('YYYY-MM-DD'),
      };
      setSearchParams({ ...latestParam });
      fetchItems({ isReset: true, latestParam });
    },
    [setSearchParams, params]
  );

  const handleMoveLocation = useCallback((item) => {
    if (item && item.lngLatPoint && item.lngLatPoint.coordinates) {
      setChangedCoords({ lat: item.lngLatPoint.coordinates[0], lng: item.lngLatPoint.coordinates[1] });
    }
  }, []);

  const handleSearchByMap = (item) => {
    if (item && !disabled && isMapSearch) {
      let latestParam = { ...params, lat: item.lat, lng: item.lng, mapsearch: true };
      setChangedCoords({ lat: item.lat, lng: item.lng });
      setSearchParams({ ...latestParam });
      fetchItems({ isReset: true, latestParam });
    }
  };

  const handleSearchByMapCheckbox = (e) => {
    setIsMapSearch(e.target.checked);
  };

  return (
    <>
      <Helmet>
        <title> Place Booker | Beach Booker </title>
      </Helmet>
      <FinderHeader
        cities={cities}
        refreshPageData={refreshPageData}
        setSelectedSearchItem={setSelectedSearchItem}
        selectedSearchItem={selectedSearchItem}
        params={params}
      />
      <Box
        className="finder-list-location-xdawd-indewxing-csxks"
        sx={{
          display: 'flex',
          fontFamily: ['TT Norms Pro Regular', 'Helvetica', 'sans-serif'].join(','),
          '@media (max-width: 900px)': {
            width: '100%',
            flexDirection: "column-reverse"
          },
        }}
      >
        {!isFwidth && (
          <Beaches
            noMoreData={noMoreData}
            handleLoadMoreData={handleLoadMoreData}
            items={items}
            setRef={setRef}
            loading={loading}
            lastFetchedItemsLength={lastFetchedItemsLength}
            selectedSearchItem={selectedSearchItem}
            handleMoveLocation={handleMoveLocation}
            params={params}
          />
        )}
        <FinderMap
          changedCoords={changedCoords}
          handleClickScroll={handleClickScroll}
          items={items}
          currentLocation={currentLocation}
          setIsFwidth={setIsFwidth}
          isFwidth={isFwidth}
          handleSearchByMap={handleSearchByMap}
          disabled={disabled}
          setDisabled={setDisabled}
          handleSearchByMapCheckbox={handleSearchByMapCheckbox}
          isMapSearch={isMapSearch}
        />
      </Box>
    </>
  );
}
