import React from 'react';
import { Box, Radio, RadioGroup, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { BOOKING_RATE } from '../Booking/sections/BookingForm';
import PaymentBackButton from '../Booking/sections/shared/PaymentBackButton';
import PaymentMainTitle from '../Booking/sections/shared/PaymentMainTitle';
import ReservationBeachItem from '../Booking/sections/shared/ReservationBeachItem';
import BeachMap from '../Booking/sections/BeachMap';
import RestaurantBookingForm from '../Booking/sections/RestaurantBookingForm';
import { useAuth } from 'src/context/AuthContext';
import { useTranslation } from 'react-i18next';

export const ReservationLayout = styled(Box)(({ theme }) => ({
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
        width: '60%',
        margin: '0',
    },
}));

export default function EditReservation({
    id,
    date,
    details,
    setSelectedDate,
    setRestaurantDetails,
    isRestaurant,
    setIsRestaurant,
    restaurantDetails,
    setBeachDetails,
    beachDetails,
    getLoading,
    beach,
    handleOnClickBox,
    handleChangeMapCheckbox,
    setIsHalfDay,
    isHalfDay
}) {
    const navigate = useNavigate();
    const { user } = useAuth()
    const { t } = useTranslation('translation', { keyPrefix: 'booking' });

    // const [isUpdateDom, setIsUpdateDom] = useState(false);

    // useEffect(()=>{
    //     setIsUpdateDom((prevState)=>!prevState)
    // }, [beachDetails]);

    const upsert = (array, element) => { // (1)
        const i = array.findIndex(_element => _element.type === element.type);
        if (i > -1) array[i] = element; // (2)
        else array.push(element);
    }

    const handleBeachDetails = React.useCallback((value, type) => {
        let totalSeat = 0;
        let serviceFees = 0;
        let fees = BOOKING_RATE
        if (type === "sunbed") {
            totalSeat = value;
            serviceFees = totalSeat * 1;
            fees = totalSeat * fees
        }
        if (beachDetails) {
            if (beachDetails.details && beachDetails.details.length > 0) {
                let gridDetails = beachDetails.details;
                let alreadyDetails = _.find(gridDetails, { type: type })
                let detailsObj;
                if (alreadyDetails) {
                    detailsObj = { ...alreadyDetails, type, value, totalSeat, serviceFees, fees }
                } else {
                    detailsObj = { type, value, totalSeat, serviceFees, fees }
                }
                upsert(gridDetails, detailsObj);
                setBeachDetails(prevState => ({
                    ...prevState,
                    details: [...gridDetails],
                }));
            }
        }
    }, [beachDetails, setBeachDetails]);

    return (
        <ReservationLayout className='editbookingforming-clsadding-daterang-pcket-reserbation-cls-adding'>
            <>
                <PaymentBackButton prevTab={() => navigate(`/member/${user?.id}/my-booking`)} />
                <PaymentMainTitle title={t("reservation")} />
                <hr style={{ border: '1px solid #EAEDF8', margin: '20px 0' }} />
                <Typography color="#000" fontSize="20px" fontWeight="500">
                    {t("selectOptions")}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', mt: '20px' }}>
                    <Typography color="#000" fontSize="15px" fontWeight="600">
                        {t("prices")}
                    </Typography>
                    <hr style={{ border: '2px solid #F8F9FF', margin: '20px 0', width: '80%' }} />
                </Box>

                <ReservationBeachItem
                    handleClickNextButton={(e) => handleBeachDetails(e, "sunbed_0_degrees_free")}
                    handleClickPrevButton={(e) => handleBeachDetails(e, "sunbed_0_degrees_free")}
                    counter={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.value) || 0}
                    title={t("transat")}
                    nextDisabled={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.value) >= (beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.totalFree)}
                //fees={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.fees) || 0}
                />
                {beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" }) && (
                    <ReservationBeachItem
                        handleClickNextButton={(e) => handleBeachDetails(e, "double_sunbed_0_degrees_free")}
                        handleClickPrevButton={(e) => handleBeachDetails(e, "double_sunbed_0_degrees_free")}
                        counter={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" })?.value) || 0}
                        title={t("bed")}
                        nextDisabled={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" })?.value) >= (beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" })?.totalFree)}
                    //fees={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.fees) || 0}
                    />
                )}
                {restaurantDetails && (
                    <>
                        <FormGroup sx={{ color: 'black', mt: '5px' }}>
                            <FormControlLabel
                                checked={isRestaurant}
                                onClick={(e) => {
                                    setRestaurantDetails(null);
                                    setIsRestaurant(e.target.checked);
                                }}
                                control={<Checkbox
                                    checked={isRestaurant}
                                />}
                                sx={{
                                    ".MuiFormControlLabel-label": {
                                        fontSize: "20px"
                                    }
                                }}
                                label={t("reservationRestaurant")}
                            />
                        </FormGroup>
                        {isRestaurant && (
                            <RestaurantBookingForm
                                restaurant={restaurantDetails}
                                editTimeHour={restaurantDetails?.editTimeHour}
                                editTimeMinutes={restaurantDetails?.editTimeMinutes}
                                date={date}
                                setSelectedDate={setSelectedDate}
                                id={id}
                                setRestaurantDetails={setRestaurantDetails}
                                restaurantDetails={restaurantDetails}
                            />
                        )}
                    </>
                )}
                <FormGroup sx={{ color: 'black', mt: '10px' }}>
                    <FormControlLabel
                        checked={isHalfDay}
                        onClick={(e) => {
                            setBeachDetails(prevState => ({
                                ...prevState,
                                status: e.target.checked ? "reserved_half_day" : "reserved_full_day",
                            }));
                            setIsHalfDay(e.target.checked)
                        }}
                        control={<Checkbox
                            checked={isHalfDay}
                        />}
                        label={t("halfDay")}
                    />
                </FormGroup>
                {beachDetails && beachDetails.status === "reserved_half_day" && (
                    <FormControlLabel
                        sx={{ ml: 2 }}
                        control={
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="half_day_type"
                                value={beachDetails?.half_day_type}
                                defaultValue={beachDetails?.half_day_type}
                                onClick={(e) => {
                                    setBeachDetails(prevState => ({
                                        ...prevState,
                                        half_day_type: e.target.value,
                                    }));
                                }}
                            >
                                <FormControlLabel value="firstHalf" control={<Radio />} label={<Typography color={"black"}>{t("morning")}</Typography>} />
                                <FormControlLabel value="secondHalf" control={<Radio />} label={<Typography color={"black"}>{t("afternoon")}</Typography>} />
                            </RadioGroup>
                        }
                    />
                )}

                {beach && beach.is_map_book && (
                    <FormGroup sx={{ color: 'black', mt: '5px' }}>
                        <FormControlLabel
                            checked={beachDetails?.isMapBook}
                            onClick={handleChangeMapCheckbox}
                            control={<Checkbox
                                defaultChecked={beachDetails?.isMapBook}
                            />}
                            label={t("idealLocation")}
                        />
                    </FormGroup>
                )}

                {beach && beach.plan && beachDetails && beachDetails?.isMapBook && (
                    <BeachMap
                        setSelectedDate={setSelectedDate}
                        getLoading={getLoading}
                        beach={beach}
                        isMapShow={beachDetails?.isMapBook}
                        handleOnClickBox={handleOnClickBox}
                    />
                )}

            </>
        </ReservationLayout>
    );
}
