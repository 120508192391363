import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

function BeachIconImage({ src, handleOnClick, name, width = 75, height = 75, sx, className, rowIndex, columnIndex, isDragDisabledCheck = true }) {
    return (

        <Grid
            className='beachiconimg-cls-adding'
            item
            sx={{
                minHeight: "45px",
            }}
        >
            <Box
                className={className}
                component="img"
                alt={name}
                src={`/assets/beach/${src}`}
                onClick={() => handleOnClick && handleOnClick(name)}
                sx={{
                    width: width,
                    height: height,
                    border: "1px solid #aaa",
                    cursor: "pointer",
                    ...sx
                }}
            />
        </Grid>
    );
}

export default BeachIconImage;

BeachIconImage.propTypes = {
    src: PropTypes.any,
    name: PropTypes.string,
    className: PropTypes.string,
    handleOnClick: PropTypes.func,
};
