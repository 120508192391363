import _ from "lodash"
import moment from "moment"
import { DOUBLE_BED_CHARGE, DOUBLE_BED_CHARGE_DAY_2, DOUBLE_BED_CHARGE_DAY_3, SINGLE_BED_CHARGE, SINGLE_BED_CHARGE_DAY_2, SINGLE_BED_CHARGE_DAY_3 } from "src/pages/Booking/sections/BookingForm"

export function strToId(str) {
    return str
        .toLowerCase()
        .trim()
        .replace(/'/g, ' ')
        .replace(/-/g, ' ')
        .replace(/[^\w\s]/g, '')
        .replace(/\s+/g, '_')
}

export const lngLatJSONToString = (json) => {
    if (!json) return ''
    const lngLat = json.coordinates
    const lng = lngLat[0]
    const lat = lngLat[1]
    return `${lat}, ${lng}`
}

export const distance = (lat1, lon1, lat2, lon2, unit = "k") => {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist ? Math.round(dist) : null
}

export const getDaysOfCount = (date) => {
    if (date && date.startDate && date.endDate) {
        let a = moment(date.startDate);
        let b = moment(date.endDate);
        const days = b.diff(a, 'days')
        return days + 1
    }
    return 1
};

export const getPriceByDay = (dates, type = "single", isRestaurant = false) => {
    const days = getDaysOfCount(dates);
    if (type === "single") {
        if (isRestaurant) {
            return 1
        } else if (days === 1) {
            return SINGLE_BED_CHARGE
        }
        if (days === 2) {
            return SINGLE_BED_CHARGE_DAY_2
        }
        if (days >= 3) {
            return SINGLE_BED_CHARGE_DAY_3
        }
        return SINGLE_BED_CHARGE
    }
    if (type === "double") {
        if (isRestaurant) {
            return 2
        } else if (days === 1) {
            return DOUBLE_BED_CHARGE
        }
        if (days === 2) {
            return DOUBLE_BED_CHARGE_DAY_2
        }
        if (days >= 3) {
            return DOUBLE_BED_CHARGE_DAY_3
        }
        return DOUBLE_BED_CHARGE
    }

}

export const getPriceByCategories = (priceLists, type = "sunbed") => {
    // type will be "sunbed", "double_sunbed";
    if (priceLists && priceLists.price_categories && priceLists.price_categories.price_list_items && priceLists.price_categories.price_list_items.length > 0) {
        const price_list_items = priceLists.price_categories.price_list_items;
        let sunBedPrice = _.find(price_list_items, { type })?.price || 0;
        return sunBedPrice;
    }
    return 0
}