/* eslint-disable eqeqeq */
import { Field, Form, Formik } from 'formik';
import { Stack, FormControl, Button, Grid } from '@mui/material';
import { TextField } from 'formik-mui';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import PhoneField from '../form/formik/PhoneField';
import { handleContactForm } from '../../services/api/admin.api';
import { useTranslation } from 'react-i18next';

const ContactFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  name: Yup.string().required(),
  phone: Yup.string().required(),
  message: Yup.string().required(),
});

const defaultValuesData = {
  id: '',
  email: '',
  name: '',
  phone: '',
  message: '',
};

const ContactForm = () => {
  const { t } = useTranslation('translation');

  const handleSubmit = useCallback(async (values) => {
    const { email, name, phone, message } = values;

    try {
      const body = {
        subject: 'Beach Booker. B2C. Formulaire Nous contacter',
        name: name,
        email: email,
        phone: phone,
        message: message,
      };

      const res = await handleContactForm(body);
      if (!res.error && res.message) {
        return alert(res.message);
      } else {
        return alert(res.errormessage);
      }
    } catch (error) {
      return alert(error.message || error.error || error);
    }
  }, []);

  return (
    <Grid container direction={'row'} sx={{ mt: 2, mb: 6 }} className='contactform-cls'>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Formik
          enableReinitialize
          validationSchema={ContactFormSchema}
          onSubmit={handleSubmit}
          initialValues={defaultValuesData}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {({ errors }) => (
            <Form autoComplete="off" noValidate>
              <Stack
                spacing={3}
                sx={{
                  mt: 2,
                  width: '600px',
                  maxWidth: '600px',
                  '@media (max-width: 650px)': {
                    maxWidth: '90vw',
                  },
                }}
              >
                {JSON.stringify(errors)}
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="email"
                      fullWidth
                      placeholder={t('email')}
                      label={t('email')}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="name"
                      fullWidth
                      placeholder={t('password')}
                      label={t('password')}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={PhoneField}
                      name="phone"
                      fullWidth
                      placeholder={t('telephone')}
                      label={t('telephone')}
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row">
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      name="message"
                      fullWidth
                      placeholder={t('message')}
                      label={t('message')}
                      multiline={true}
                      rows={2}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent={'center'}>
                  <Button size="large" variant="contained" type="submit">
                    {t('send')}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
