import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getHutList } from '../../../services/api/admin.api';
import { useTranslation } from 'react-i18next';

const Layout = styled(Box)(({ theme }) => ({
  backgroundColor: '#FBECEB',
  padding: '40px 0 40px 40px',
  borderBottomLeftRadius: '50px',
  [theme.breakpoints.up('lg')]: {
    borderBottomLeftRadius: '200px',
    padding: '80px 0 80px 80px',
  },
}));

let Carousel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  gap: '40px',
  padding: '0 40px 20px 0',
  [theme.breakpoints.up('lg')]: {
    padding: '0 80px 40px 0',
  },
}));

let BeachesDesign = styled(Box)(({ theme }) => ({
  padding: '5px',
  '&:hover': { backgroundColor: 'white !important', cursor: 'pointer', borderRadius: '20px' },
}));
export default function PlaceAround({ data, id, coordinates }) {
  const [aroundPlaces, setArondPlaces] = useState([]);
  const { t } = useTranslation('translation');

  const geAroundPlacesList = async () => {
    try {
      const params = {
        lat: (coordinates || [])[0],
        lng: (coordinates || [])[1],
        city: data?.city?.name,
        distance: 3840,
        count: 8,
      };

      const res = await getHutList(params);
      const placeLimit = (res.data || []).length < 8 ? (res.data || []).length : 8;
      setArondPlaces((res?.data || []).slice(0, placeLimit));
    } catch (error) {
    }
  };
  useEffect(() => {
    if (data) {
      geAroundPlacesList();
    }
  }, [id, data]);

  return (
    <section style={{ backgroundColor: '#1A1919' }}>
      <Layout className='editbookingforming-clsadding-daterang-pcket-placearound'>

        <div className='placearound-cs-adding-here-alltype'>
          <Typography fontSize="40px" fontWeight="600" color="black" mb="30px">
            {t("around")}
          </Typography>
          <Carousel>
            {(aroundPlaces || []).map((data, index) => {
              const name = data?.names;

              return (
                <a
                  href={data.id}
                  style={{
                    textDecoration: 'none',
                    flex: '0 0 auto',
                  }}
                >
                  <BeachesDesign>
                    <img
                      src={(data?.image || [])[0]?.url}
                      alt=""
                      style={{ width: '15rem', height: '10rem', borderRadius: '30px' }}
                    />
                    <Typography
                      color="#D88584"
                      fontSize="18px"
                      fontWeight="700"
                      textTransform="uppercase"
                      mt="10px"
                      sx={{ fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important' }}
                    >
                      {name?.fr}
                    </Typography>
                    <Typography
                      fontSize="15px"
                      color="#BEC7DA"
                      sx={{ fontFamily: 'TT Norms Pro Medium, Helvetica, sans-serif !important', fontWeigth: 700 }}
                    >
                      {data?.city?.name}
                    </Typography>
                  </BeachesDesign>
                </a>
              );
            })}
          </Carousel>
        </div>

      </Layout>
    </section>
  );
}
