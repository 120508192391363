import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { TileLayer, Marker, MapContainer, ZoomControl, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useMap } from 'react-leaflet/hooks';
import { getRequiredSVGPinByCategory } from './CustomeIcon';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function SearchByMapComponent(props) {
  const map = useMapEvent({
    dragend: () => {
      let center = map.getCenter();
      if (props.handleSearchByMap && props.isMapSearch && !props.disabled) {
        props.handleSearchByMap(center);
      }
    },
  });
  return null;
}

export default function FinderMap({
  currentLocation,
  items,
  handleClickScroll,
  changedCoords,
  setIsFwidth,
  isFwidth,
  handleSearchByMap,
  setDisabled,
  disabled,
  setIsMapSearch,
  isMapSearch,
  handleSearchByMapCheckbox,
}) {
  function FlyMapTo() {
    const map = useMap();
    useEffect(() => {
      map.flyTo(changedCoords);
    }, [changedCoords]);
    return null;
  }

  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'custom-marker-cluster',
      iconSize: L.point(33, 33, true),
    });
  };

  const handleOnClickMarker = (item) => {
    handleClickScroll(item.id);
  };

  const handleMapWidth = useCallback(() => {
    setIsFwidth((prevState) => !prevState);
  }, []);

  const handleSearchByMapMOveEnd = useCallback(
    (e) => {
      setDisabled(true);
      if (!disabled && handleSearchByMap && isMapSearch) {
        handleSearchByMap(e);
      }
    },
    [disabled, handleSearchByMap, isMapSearch, setDisabled]
  );

  return (
    <Box
      className="finder-list-location-xs-cf-sty"
      sx={{
        color: 'black',
        width: isFwidth ? '100vw' : '50vw',
        height: 'calc(100vh - 76px)',
        border: '2px solid black',
        position: 'relative',
        '@media (max-width: 900px)': {
          width: '100%',
          height: '50vh',
        },
      }}
    >
      <MapContainer
        className="markercluster-map"
        center={[51.505, -0.09]}
        zoom={5}
        maxZoom={20}
        closePopupOnClick={false}
        preferCanvas={true}
        doubleClickZoom="center"

        whenReady={() => setDisabled(false)}
        sx={{
          '@media (max-width: 900px)': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <TileLayer
          zoom={5}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
        />
        <ZoomControl position="topright" />
        {isMapSearch && (
          <SearchByMapComponent
            disabled={disabled}
            isMapSearch={isMapSearch}
            handleSearchByMap={handleSearchByMapMOveEnd}
          />
        )}
        <FlyMapTo />
        {items.length > 0 && (
          <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
            polygonOptions={{
              fillColor: '#ffffff',
              color: '#f00800',
              weight: 1,
              height: 1,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            clusterPane="markerPane"
            showCoverageOnHover={true}
            zoomToBoundsOnClick={true}
            spiderfyOnMaxZoom={false}
            disableClusteringAtZoom={6}
          >
            {items.map((address, index) => {
              if (address?.lngLatPoint?.coordinates[0]) {
                return (
                  <Marker
                    eventHandlers={{
                      click: (e) => {
                        handleOnClickMarker(address);
                      },
                    }}
                    key={index}
                    position={[address?.lngLatPoint?.coordinates[0], address?.lngLatPoint?.coordinates[1]]}
                    icon={getRequiredSVGPinByCategory(address)}
                  />
                )
              }
            }
            )}
          </MarkerClusterGroup>
        )}
      </MapContainer>
      <Button onClick={handleMapWidth} className="edit-location-button" startIcon={<ArrowBackIosNewIcon />}></Button>
      <Box className="top-floating-input">
        <FormGroup className="for-all-inputl-cls-adding">
          <FormControlLabel
            className="checkbox-label"
            control={
              <Checkbox
                className="for-all-inputl-cls-adding-checking"
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                checked={isMapSearch}
                onChange={handleSearchByMapCheckbox}
              />
            }
            label="Rechercher en déplaçant la carte"
          />
        </FormGroup>
      </Box>
    </Box>
  );
}
