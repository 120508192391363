/* eslint-disable array-callback-return */
import { Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ContactForm from 'src/components/contact/ContactForm';

export default function ContactPage() {
  const { t } = useTranslation('translation');

  return (
    <>
      <Helmet>
        <title> Page de contact | Beach Booker </title>
      </Helmet>
      <Container maxWidth="lg" sx={{ px: 2, pt: 5, pb: 15 }} className='typopxing-aling-proper-cls-adding'>
        <Typography
          textAlign="center"
          sx={{
            fontSize: '40px',
            color: '#06133A',
            fontStyle: 'normal',
            fontWeight: '700',
            fontFamily: 'TT Norms Pro Medium ,Helvetica,sans-serif!important',
            fontHeight: 52,
          }}
        >
          {t("contactUs")}
        </Typography>
        <ContactForm />
      </Container>
    </>
  );
}
