import { Grid } from '@mui/material';
import React, { } from 'react';
import BeachIconImage from './shared/BeachIconImage';
import _ from 'lodash';
import { BEACH_ITEM_DATA } from '../data/beach.data';


export default function BeachMap(props) {
    const { beach, getLoading, isMapShow, handleOnClickBox } = props
    return (
        <Grid container direction={"row"} className='beachiconimg-cls-adding-map'>
            <Grid item xs={12} className='beachiconimg-cls-adding-item-twelve'>
                {
                    !getLoading
                    && beach?.plan.length
                    && beach?.plan.map((row, rowIndex) => (
                        <Grid
                            className='beachiconimg-cls-adding-beacehd-js'
                            container
                            wrap="nowrap"
                            sx={{ overflow: 'auto' }}
                            direction={"row"}
                            spacing={0.1}
                        >
                            {row.map((column, columnIndex) => {
                                let imgSrc;
                                let isClickable = true;
                                if (column.hasOwnProperty('available') && column.available == false) {
                                    isClickable = false;
                                    imgSrc = "beachbooker_beach_" + column.originalType + ".svg";
                                } else {
                                    imgSrc = _.find(BEACH_ITEM_DATA, { name: column.type })?.img
                                }
                                return (
                                    <Grid
                                        className='beachiconimg-cls-adding-beachofimg-cls-adding'
                                        item
                                    >
                                        <BeachIconImage
                                            rowIndex={rowIndex}
                                            columnIndex={columnIndex}
                                            width={50}
                                            height={50}
                                            src={imgSrc}
                                            name={column.type}
                                            className={`${!isClickable || column.type.includes('unavailable') ? "disabled" : null}`}
                                            sx={{
                                                border: column.selected ? "1px solid #69bed0" : "none",
                                                ...!isMapShow && { cursor: "not-allowed" },
                                                // ...(isAvailable) && { background: column.color },
                                            }}
                                            handleOnClick={() => {
                                                if (column.type && !column.type.includes('unavailable') && (column.type.startsWith("double_sunbed") || column.type.startsWith("sunbed"))) {
                                                    if (!isClickable) {
                                                        // handleDisplayDetails(rowIndex, columnIndex)
                                                    } else {
                                                        if (column.available) {
                                                            // moveIn(rowIndex, columnIndex, column)
                                                        } else {
                                                            handleOnClickBox(rowIndex, columnIndex)
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    ))}
            </Grid>
        </Grid >
    );
}
