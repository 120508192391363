import { Typography } from '@mui/material';
import React from 'react';

export default function PaymentMainTitle({ title, subTitle }) {
    return (
        <>
            <Typography
                className='typo-cls-addigbeachiconimg-cls-adding'
                color="#06133a"
                fontSize="32px"
                fontWeight="700"
                fontFamily={'"TT Norms Pro Medium",Helvetica,sans-serif!important'}
            >
                {title}
            </Typography>
            {subTitle && (
                <Typography
                    className='beachiconimg-cls-adding-main'
                    color={"#06133a"}
                    fontSize={"12px"}
                    sx={{ mt: 1 }}
                    fontFamily={'"TT Norms Pro Regular",Helvetica,sans-serif!important'}
                >
                    {subTitle}
                </Typography>
            )}
        </>
    );
}
