import React from 'react';
import IdentificationForm from './IdentificationForm';
import { Typography } from '@mui/material';
import PaymentMainTitle from './shared/PaymentMainTitle';
import PaymentBackButton from './shared/PaymentBackButton';
import { useAuth } from 'src/context/AuthContext';
import { useTranslation } from 'react-i18next';

export default function Identification(props) {
    const { prevTab, customerData = null, setUserData, id, setTabIndex } = props;
    const { user } = useAuth()
    const { t } = useTranslation('translation', { keyPrefix: "paymentTab" });

    return (
        <section className='identificationc-cls-addinghereused'>
            <PaymentBackButton prevTab={prevTab} />
            <PaymentMainTitle title="Identification" />
            <hr style={{ border: '1px solid #EAEDF8', margin: '20px 0' }} />
            <Typography color="#000" fontSize="20px" fontWeight="500">
                {t("enterYourDetails")}
            </Typography>
            <IdentificationForm
                customer={customerData ? customerData : user}
                setUserData={setUserData}
                id={id}
                setTabIndex={setTabIndex}
            />
        </section>
    );
}
