import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { setupPayment, createPayment } from '../../../services/api/admin.api';
import CheckoutForm from './CheckoutForm';
import { Box, Typography, styled } from '@mui/material';
import PaymentMainTitle from './shared/PaymentMainTitle';
import PaymentBackButton from './shared/PaymentBackButton';
import { PaymentStatusEnum } from '../../../types/common.type';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { MAP_FEES } from './BookingForm';
import { getPriceByDay } from 'src/utils/common';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Layout = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  [theme.breakpoints.up('md')]: {
    width: '60%',
    margin: '0',
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Payment({
  id: hutId,
  userData,
  totalAmount,
  prevTab,
  restaurantDetails,
  beachDetails,
  setIsCheckedCondition,
  isCheckedCondition,
  date,
  isRestaurant,
  defaultRestaurant,
  details,
  mainServiceFees
}) {
  const [paymentIntent, setPaymentIntent] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const { client_secret: clientSecret, id } = paymentIntent || {};
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: "paymentTab" });

  const setupIntentPayment = async () => {
    const res = await setupPayment();
    if (res) {
      console.log('card payment is : ', res);
      setPaymentIntent(res.data);
    }
  };

  const createBeachBookingPayment = async ({ cardTokenId }) => {
    setSaveLoading(true);
    const formData = new FormData();
    let dateStart = moment(date?.startDate).format('YYYY-MM-DD');
    let dateEnd = moment(date?.endDate).format('YYYY-MM-DD');

    //get invoice details
    let invoiceDetails = Object();
    let mapSelectionPrice = 0
    if (beachDetails && beachDetails.details) {
      if (beachDetails && beachDetails.details && beachDetails.details.length > 0) {
        beachDetails.details.map((ele) => {
          let positionLength = (ele && ele.position && beachDetails.isMapBook) ? ele.position.length : 0;
          let latestValueWithoutMap = ele.value - positionLength
          let charge = ele.type.startsWith("sunbed") ? getPriceByDay(date, "single", isRestaurant) : getPriceByDay(date, "double", isRestaurant);
          invoiceDetails[ele.type] = Number(latestValueWithoutMap) * charge;
          if (positionLength > 0 && beachDetails.isMapBook) {
            mapSelectionPrice += positionLength * MAP_FEES
          }
        });
      }
    }
    invoiceDetails.mapSelectionPrice = mapSelectionPrice;
    //end of get invoice details
    let restaurantPrice = 0;
    let total_person = 0
    if (restaurantDetails && restaurantDetails.details) {
      restaurantDetails.details.map((ele) => {
        restaurantPrice += Number(ele.value);
        total_person += Number(ele.value);
      })
    }
    invoiceDetails.restaurantPrice = restaurantPrice;

    const ordersData = {
      state: PaymentStatusEnum.AWAITING_PAYMENT,
      dateStart: dateStart,
      dateEnd: dateEnd,
      completeReason: '',
      commentClient: '',
      commentAdmin: '',
      contactEmail: userData?.email,
      contactPhone: userData?.phone,
      contactName: userData?.firstname,
      contactLastName: userData?.lastname,
      locale: 'fr',
      stripeCouponId: '',
      stripePaymentIntentId: '',
      stripePaymentStatus: '',
      paidAt: '',
      completedAt: '',
      hutId: hutId,
      noShowPaid: '',
      stripeSetupIntentId: '',
      details: '',
      date: '',
      comment: '',
      status: '',
      source: '',
      customerId: '',
      beachId: '',
      restaurant: true,
      orderItems: [],
    };
    let serviceFees = mainServiceFees
    if (restaurantDetails && restaurantDetails.details) {
      const resServiceFees = _.sumBy(restaurantDetails.details, "serviceFees");
      if (resServiceFees) {
        serviceFees = serviceFees + Number(resServiceFees)
      }
    }
    //console.log(mainServiceFees, 'mainServiceFees');
    formData.append('clientOrderAmount', totalAmount);
    formData.append('totalServiceFeesAmount', serviceFees);
    formData.append('cardTokenId', cardTokenId);
    formData.append('currency', 'usd');
    formData.append('order', JSON.stringify(ordersData));
    formData.append('invoiceDetails', JSON.stringify(invoiceDetails));

    if (restaurantDetails) {
      formData.append('restaurantDetails', JSON.stringify({ ...restaurantDetails, total_person }));
    }
    if (beachDetails) {
      if (details && defaultRestaurant && details.restaurants.length > 0) {
        formData.append('beachDetails', null);
      } else {
        formData.append('beachDetails', JSON.stringify(beachDetails));
      }
    }
    console.log(formData, 'formData');
    try {
      const res = await createPayment(formData);
      if (res?.status) {
        setSaveLoading(false)
        alert(res?.message);
        navigate('/');
      }
      setSaveLoading(false)
    } catch (err) {
      setSaveLoading(false)
      return alert(err.message || err.error || err);
    }
  };

  useEffect(() => {
    setupIntentPayment();
  }, [date, hutId]);

  const options = {
    clientSecret: `${id}_secret_${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
  };

  return (
    <Layout className='layout-mentiion-form-cls-adding'>
      <PaymentBackButton prevTab={prevTab} />
      <PaymentMainTitle
        title={t("payment")}
        subTitle={t("subTitle")}
      />
      <hr style={{ border: '1px solid #EAEDF8', margin: '20px 0' }} />
      <Typography
        color="#000"
        fontSize="22px"
        fontWeight="500"
        fontFamily={'"TT Norms Pro Medium",Helvetica,sans-serif!important'}
      >
        {t("enterYourDetails")}
      </Typography>
      <div style={{ color: 'black' }} className="stripe-card-form">
        {clientSecret && id ? (
          <Elements stripe={stripePromise} options={options} className='identificationc-cls-addinghereused-elements'>
            <CheckoutForm
              options={options}
              saveLoading={saveLoading}
              createBeachBookingPaymet={createBeachBookingPayment}
              isCheckedCondition={isCheckedCondition}
              setIsCheckedCondition={setIsCheckedCondition}
            />
          </Elements>
        ) : null}
      </div>
    </Layout>
  );
}
