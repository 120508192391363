import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import umbrella from '../../../assets/icons/umbrellaIcon.svg';
import down from '../../../assets/icons/down.svg';
import { LoadingButton } from '@mui/lab';
import BeachImageSlider from './BeachImageSlider';
import CustomeTooltip from 'src/components/shared/CustomeTooltip';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
const DELIMETER = '•';

export default function Beaches({
  items,
  lastFetchedItemsLength,
  setRef,
  handleLoadMoreData,
  noMoreData,
  loading,
  selectedSearchItem,
  handleMoveLocation,
  params,
}) {
  const { t } = useTranslation('translation');

  const navigate = useNavigate();

  const handleNavigate = (beachId) => {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get('dfrom');
    const dend = urlParams.get('dend') || moment().format("YYYY-MM-DD");
    if (params) {
      let restaurantData = String(params.is_restaurant) === "true"
      if (params && params.is_restaurant && restaurantData) {
        return navigate(`/place/${beachId}?dfrom=${date}&dend=${dend}&zoom=7&defaultRestaurant=${true}&isAround=${true}`, { replace: true });
      } else {
        return navigate(`/place/${beachId}?dfrom=${date}&dend=${dend}&isAround=${true}`, { replace: true });
      }
    } else {
      return navigate(`/place/${beachId}?dfrom=${date}&dend=${dend}&isAround=${true}`, { replace: true });
    }
  };

  const getServices = (placeServices) => {
    if (placeServices.length > 0) {
      return (placeServices || []).map((i) => (i.services.name ? i.services.name.trim() : null)).join(` ${DELIMETER} `);
    } else {
      return '';
    }
  };

  const availablePlacesText = (item) => {
    const text = 'Places disponibles' + ': ' + item?._beachStats?.totalFree;
    return text;
  };

  const availablePlacesNum = (item) => {
    return item?._beachStats?.totalFree || 0;
  };

  return (
    <Box
      className="beaches-file-cls-adding-off"
      sx={{
        width: '50vw',
        height: 'calc(100vh - 76px)',
        p: '25px 0 0 30px',
        overflow: 'hidden',
        '@media (max-width: 900px)': {
          width: '100%',
          height: '50vh',
          p: '20px 20px'
        },
      }}
    >
      <Box sx={{ mb: '20px' }} className="beaches-file-cls-adding-off-typo">
        <Typography
          color="#B9B0C1"
          fontSize="12px"
          fontFamily='"TT Norms Pro Regular",Helvetica,sans-serif!important'
          fontWeight={400}
        >
          {lastFetchedItemsLength} résultats
        </Typography>
        <Typography
          className='classics-style-cls-adding-here'
          fontFamily='"TT Norms Pro Medium",Helvetica,sans-serif!important'
          color="black"
          fontSize="25px"
          fontWeight="700"
        >
          {selectedSearchItem?.searchItem?.name || ''} {' '}
          {params.is_restaurant && params.is_restaurant == 'true' ? 'Restaurant' : 'Plages'}
        </Typography>
      </Box>
      <Box
        className="beaches-file-cls-adding-offthird"
        sx={{
          height: 'calc(100% - 77px)',
          overflowY: 'auto',
          overflowX: 'hidden ',
        }}
      >
        <Box
          className="beaches-file-cls-adding-off-fur"
          sx={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          {items.length > 0 ?
            (
              _.orderBy(items, 'isPartner', 'desc').map((beach) => {
                const name = beach?.name;
                const city = `${beach?.city?.name}, ${beach?.city?.country}`;
                const id = beach?.id;
                const { _distanceKm, place_services = [] } = beach;
                return (
                  <Box
                    key={beach.id}
                    ref={setRef(id)}
                    onClick={() => handleNavigate(beach?.id)}
                    sx={{
                      width: 'calc(50% - 10px)',
                      cursor: 'pointer',
                      p: '10px 10px 25px 10px',
                      borderRadius: '20px',
                      '&:hover': {
                        bgcolor: '#FFF0EF',
                      },
                    }}
                    className={'finder-box-container'}
                  >
                    <BeachImageSlider item={beach} handleMoveLocation={handleMoveLocation} />

                    <Typography
                      color="#06133a"
                      fontSize="22px"
                      fontWeight="700"
                      fontFamily="TT Norms Pro Medium,Helvetica,sans-serif!important"
                    >
                      {name}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }} className="beaches-file-cls-adding-off-flexgrid">
                      <Typography
                        color="#bcc1d1"
                        fontSize="17px"
                        fontStyle="normal"
                        fontFamily='"TT Norms Pro Regular",Helvetica,sans-serif!important'
                        sx={{
                          lineHeight: '20px',
                        }}
                      >
                        {_distanceKm} {city}
                      </Typography>
                      {availablePlacesNum(beach) > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mt: 0.5 }}>
                          <CustomeTooltip title={availablePlacesText(beach)}>
                            <Stack direction={'row'} spacing={0.5}>
                              <img src={umbrella} alt="" style={{ backgroundColor: 'transparent', width: '20px' }} />
                              <Typography color="#000" fontSize="12px">
                                {availablePlacesNum(beach)}
                              </Typography>
                            </Stack>
                          </CustomeTooltip>
                        </Box>
                      )}
                    </Box>
                    {place_services && place_services.length > 0 && (
                      <CustomeTooltip title={getServices(place_services)}>
                        <Typography
                          sx={{
                            mt: 0.5,
                            display: 'inline-block',
                            width: '98%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden !important',
                            textOverflow: 'ellipsis',
                          }}
                          display="inline"
                          color="#06133a"
                          fontSize="12px"
                          fontWeight="400"
                          fontFamily='"TT Norms Pro Regular",Helvetica,sans-serif!important'
                          noWrap
                        >
                          {getServices(place_services)}
                        </Typography>
                      </CustomeTooltip>
                    )}
                  </Box>
                );
              })) : <Box display="flex" justifyContent={"center"}><Typography fontSize="18px" fontWeight="600" color="#000">{t("noRecord")}</Typography></Box>}
        </Box>
        {items && items.length > 0 && (
          <LoadingButton
            sx={{
              display: 'flex',
              m: '3rem auto 10rem auto',
              color: '#ff8478',
              flexDirection: 'column',
              borderRadius: '15px',
              alignItems: 'center',
              p: '2px 25px',
              '&:hover': {
                bgcolor: '#FFF0EF',
              },
            }}
            loading={loading}
            disabled={!noMoreData}
          >
            <Typography fontSize="16px" fontWeight="600" textAlign="center" onClick={handleLoadMoreData}>
              Afficher plus
            </Typography>
            <img src={down} alt="" style={{ width: '20px' }} />
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}
