export default function TextField(theme) {
    return {
        defaultProps: {
            size: 'large',
            variant: "outlined"
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#06133a",
                    fontWeight: 700,
                    fontSize: "14px",
                    fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                    "&.Mui-focused": {
                        color: '#06133a'
                    }
                },
            }
        },

        MuiOutlinedInput: {
            // defaultProps: {
            //     notched: false,
            // },
            styleOverrides: {
                root: {
                    color: "#06133a",
                    fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                    '& fieldset': {
                        borderRadius: 19,
                        border: "2px solid #eaedf8",
                    },
                    '&:focus-within fieldset, &:focus-visible fieldset': {
                        color: "#06133a",
                        fontSize: "14px !important",
                        border: '2px solid #ff8478!important',
                    },
                    '&:hover fieldset': {
                        border: '2px solid #ff8478!important',
                    },
                },
                label: {
                    fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                    fontWeight: 700,
                    fontSize: "14px !important",
                    lineHeight: "1.42em",
                    '&:focus-within fieldset, &:focus-visible fieldset': {
                        color: "#06133a",
                        fontSize: "14px !important",
                        border: '2px solid #ff8478!important',
                    },
                },
                input: {
                    fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                    padding: '0px 19px',
                    fontSize: 16,
                    minHeight: "64px",
                    color: "#06133a",
                },
            }
        },
    };
}