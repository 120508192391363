import React from 'react';
import { Box, Radio, RadioGroup, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PaymentBackButton from './shared/PaymentBackButton';
import PaymentMainTitle from './shared/PaymentMainTitle';
import RestaurantBookingForm from './RestaurantBookingForm';
import ReservationBeachItem from './shared/ReservationBeachItem';
import { BOOKING_RATE } from './BookingForm';
import BeachMap from './BeachMap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import RestaurantBookOption from './shared/RestaurantBookOption';

export const ReservationLayout = styled(Box)(({ theme }) => ({
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
        width: '60%',
        margin: '0',
    },
}));

export default function Reservation({
    id,
    date,
    details,
    setSelectedDate,
    setRestaurantDetails,
    isRestaurant,
    setIsRestaurant,
    restaurantDetails,
    setBeachDetails,
    beachDetails,
    getLoading,
    beach,
    handleOnClickBox,
    handleChangeMapCheckbox,
    setIsHalfDay,
    isHalfDay,
    defaultRestaurant,
    setIsBeach,
    isBeach,
    handleRemoveMapSelection
}) {
    const navigate = useNavigate();
    const { t } = useTranslation('translation', { keyPrefix: 'booking' });

    const upsert = (array, element) => { // (1)
        const i = array.findIndex(_element => _element.type === element.type);
        if (i > -1) array[i] = element; // (2)
        else array.push(element);
    }

    const handleBeachDetails = React.useCallback((value, type, operationType = "") => {
        if (operationType === "minus" && handleRemoveMapSelection) {
            if (beachDetails && beachDetails.details && beachDetails.details.length > 0) {
                const detailsType = _.find(beachDetails.details, {type});
                if(detailsType){                    
                    if(detailsType.position && value < detailsType.position.length){
                        alert(t("mapUnselectWarning"));
                        return false
                    }
                }        
            }
        }        
        let totalSeat = 0;
        let serviceFees = 0;
        let fees = BOOKING_RATE
        if (type === "sunbed") {
            totalSeat = value;
            serviceFees = totalSeat * 1;
            fees = totalSeat * fees
        }
        if (beachDetails) {
            if (beachDetails.details && beachDetails.details.length > 0) {
                let gridDetails = beachDetails.details;
                let alreadyDetails = _.find(gridDetails, { type: type })
                let detailsObj;
                if (alreadyDetails) {
                    detailsObj = { ...alreadyDetails, type, value, totalSeat, serviceFees, fees }
                } else {
                    detailsObj = { type, value, totalSeat, serviceFees, fees }
                }
                upsert(gridDetails, detailsObj);
                setBeachDetails(prevState => ({
                    ...prevState,
                    details: [...gridDetails],
                }));
            }
        }
    }, [beachDetails, setBeachDetails]);

    return (

        <ReservationLayout className='identificationc-cls-addinghereused-reservation-laout-cls-adding'>
            <>
                <PaymentBackButton prevTab={() => navigate(`/place/${id}`)} />
                <PaymentMainTitle title={t("reservation")} />
                <hr style={{ border: '1px solid #EAEDF8', margin: '20px 0' }} />

                {details && defaultRestaurant && details.restaurants.length > 0 && (
                    <RestaurantBookOption
                        className='identificationc-cls-addinghereusedresturant-option'
                        isRestaurant={isRestaurant}
                        setRestaurantDetails={setRestaurantDetails}
                        setIsRestaurant={setIsRestaurant}
                        details={details}
                        date={date}
                        setSelectedDate={setSelectedDate}
                        id={id}
                        restaurantDetails={restaurantDetails}
                        defaultRestaurant={defaultRestaurant}
                    />
                )}
                {defaultRestaurant && (
                    <FormGroup sx={{ color: 'black', mt: '5px' }}>
                        <FormControlLabel
                            className='identificationc-cls-addinghereusedresturant-option-labeling'
                            checked={isBeach}
                            onClick={(e) => {
                                console.log(beachDetails, 'beachDetails');
                                setBeachDetails(prevState => ({
                                    ...prevState,
                                    details: prevState.details.map((ele) => { return { ...ele, value: 0 } }),
                                }));
                                setIsBeach(e.target.checked);
                            }}
                            sx={{
                                ".MuiFormControlLabel-label": {
                                    fontSize: "20px"
                                }
                            }}
                            control={<Checkbox />}
                            label={t("reservationBeach")}
                        />
                    </FormGroup>
                )}
                {(isBeach || !defaultRestaurant) && (
                    <>
                        <Typography color="#000" fontSize="20px" fontWeight="500">
                            {t("selectOptions")}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', mt: '20px' }}>
                            <Typography color="#000" fontSize="15px" fontWeight="600">
                                {t("prices")}
                            </Typography>
                            <hr style={{ border: '2px solid #F8F9FF', margin: '20px 0', width: '80%' }} />
                        </Box>

                        <ReservationBeachItem
                            handleClickNextButton={(e) => handleBeachDetails(e, "sunbed_0_degrees_free")}
                            handleClickPrevButton={(e) => handleBeachDetails(e, "sunbed_0_degrees_free", "minus")}
                            counter={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.value) || 0}
                            title={t("transat")}
                            nextDisabled={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.value) >= (beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.totalFree)}
                        //fees={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.fees) || 0}
                        />
                        {beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" }) && (
                            <ReservationBeachItem
                                handleClickNextButton={(e) => handleBeachDetails(e, "double_sunbed_0_degrees_free")}
                                handleClickPrevButton={(e) => handleBeachDetails(e, "double_sunbed_0_degrees_free", "minus")}
                                counter={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" })?.value) || 0}
                                title="Bed"
                                nextDisabled={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" })?.value) >= (beachDetails && beachDetails?.details.find(obj => { return obj.type === "double_sunbed_0_degrees_free" })?.totalFree)}
                            //fees={(beachDetails && beachDetails?.details.find(obj => { return obj.type === "sunbed_0_degrees_free" })?.fees) || 0}
                            />
                        )}

                        <FormGroup sx={{ color: 'black', mt: '10px' }} className='control-labeling-offing-cld-type'>
                            <FormControlLabel
                                checked={isHalfDay}
                                onClick={(e) => {
                                    setBeachDetails(prevState => ({
                                        ...prevState,
                                        status: e.target.checked ? "reserved_half_day" : "reserved_full_day",
                                    }));
                                    setIsHalfDay(e.target.checked)
                                }}
                                control={<Checkbox />}
                                label={t("halfDay")}
                            />
                        </FormGroup>
                        {beachDetails && beachDetails.status === "reserved_half_day" && (
                            <FormControlLabel
                                sx={{ ml: 2 }}
                                control={
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="half_day_type"
                                        value={beachDetails?.half_day_type}
                                        onClick={(e) => {
                                            setBeachDetails(prevState => ({
                                                ...prevState,
                                                half_day_type: e.target.value,
                                            }));
                                        }}
                                    >
                                        <FormControlLabel value="firstHalf" control={<Radio />} label={<Typography color={"black"}>{t("morning")}</Typography>} />
                                        <FormControlLabel value="secondHalf" control={<Radio />} label={<Typography color={"black"}>{t("afternoon")}</Typography>} />
                                    </RadioGroup>
                                }
                            />
                        )}

                        {beach && beach.is_map_book && (
                            <FormGroup sx={{ color: 'black', mt: '5px' }}>
                                <FormControlLabel
                                    checked={beachDetails.isMapBook}
                                    onClick={handleChangeMapCheckbox}
                                    control={<Checkbox />}
                                    label={t("idealLocation")}
                                />
                            </FormGroup>
                        )}

                        {beach && beach.plan && beachDetails?.isMapBook && (
                            <BeachMap
                                setSelectedDate={setSelectedDate}
                                getLoading={getLoading}
                                beach={beach}
                                isMapShow={beachDetails?.isMapBook}
                                handleOnClickBox={handleOnClickBox}
                            />
                        )}
                    </>
                )}

                {details && !defaultRestaurant && details.restaurants.length > 0 && (
                    <RestaurantBookOption
                        isRestaurant={isRestaurant}
                        setRestaurantDetails={setRestaurantDetails}
                        setIsRestaurant={setIsRestaurant}
                        details={details}
                        date={date}
                        setSelectedDate={setSelectedDate}
                        id={id}
                        restaurantDetails={restaurantDetails}
                        restaurantsData={details?.restaurants?.[0] || null}
                    />
                )}
            </>
        </ReservationLayout>
    );
}
