// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
        },
        // h1: {
        //   fontFamily: "TT Norms Pro Medium', Helvetica, sans-serif !important",
        //   fontSize: "72px !important",
        //   fontWeight: "bold !important"
        // },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
