import React, { useEffect, useState } from 'react';
import BookingHeader from './sections/BookingHeader';
import Tab from './sections/Tab';
import { useParams } from 'react-router-dom';
import { getPlaceDetails } from 'src/services/api/admin.api';

export default function BookingPage() {
    const { id } = useParams();
    const [details, setDetails] = useState(null);

    const getPlaceData = async (idData) => {
        try {
            const res = await getPlaceDetails(idData);
            if (res.status && res.data) {
                setDetails(res.data);
            }
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        getPlaceData(id);
    }, [id]);

    return (
        <>
            <BookingHeader className="booking-header-cls-addign-type-of-use" />
            <Tab details={details} className="booking-header-cls-addign-type-of-use-tab-edetails" />
        </>
    );
}
