/* eslint-disable array-callback-return */
import { Box, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import LoginForm from 'src/components/Authentication/LoginForm';

export default function LoginPage() {
    return (
        <>
            <Helmet className="finder-list-location-xdawdBooker">
                <title> Se connecter | Beach Booker </title>
            </Helmet>
            <Container maxWidth="lg" className='max-width-lg-cls-addingtypopmistakr'>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: 'calc(100vh - 250px)' }}
                >
                    <Grid item xs={3}>
                        <Typography
                            textAlign="center"
                            sx={{
                                fontSize: '40px',
                                color: '#06133A',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                fontFamily: 'TT Norms Pro Medium ,Helvetica,sans-serif!important',
                                fontHeight: 52,
                            }}
                        >
                            Se connecter
                        </Typography>
                        <Typography
                            textAlign="center"
                            fontFamily={'TT Norms Pro Medium ,Helvetica,sans-serif!important'}
                            sx={{
                                color: '#06133A',
                                mt: 1
                            }}>
                            Nouveau membre ? <Link to="/signup">S’inscrire</Link>
                        </Typography>
                        <LoginForm />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
