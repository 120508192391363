/* eslint-disable no-array-constructor */
/* eslint-disable eqeqeq */
import styled from '@emotion/styled';
import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { getBeachBeachReservationsCustomersPaymentsForADate, getHutBeachReservationsForACustomer } from 'src/services/api/admin.api';
import update from "immutability-helper";
import _ from 'lodash';
import { toast } from 'react-toastify';
import Identification from '../Booking/sections/Identification';
import { MAP_FEES, SERVICE_CHARGE_WITH_RESTAURANT } from '../Booking/sections/BookingForm';
import { ReservationLayout } from '../Booking/sections/Reservation';
import EditReservation from './EditReservation';
import EditPayment from './EditPayment';
import EditBookingForm from './EditBookingForm';
import { getPriceByDay } from 'src/utils/common';
import { useTranslation } from 'react-i18next';


const Circle = styled(Box)({
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    marginBottom: '5px',
});

const Label = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '600',
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block',
    },
}));

const Layout = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: ['TT Norms Pro Regular', 'Helvetica', 'sans-serif'].join(','),
});

const FlexBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '6rem',
});

const Line = styled(Box)(({ theme }) => ({
    border: '1px solid #EAEDF8',
    margin: '0 -32px 5px -32px ',
    width: '15rem',
    [theme.breakpoints.up('md')]: {
        margin: '0 -32px 22px -32px',
    },
}));

const FormLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '40px',
    justifyContent: 'center',
    padding: '45px 20px 70px 20px',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderBottomRightRadius: '50px',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        borderBottomRightRadius: '100px',
    },
}));

const USER_DEFAULT_DATA = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
};

export default function EditTab({ details }) {
    const { id } = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [saveLoading, setSaveLoading] = useState(false);
    const [userData, setUserData] = useState(USER_DEFAULT_DATA);
    const [totalAmount, setTotalAmount] = useState(20.5);
    const [paidAmount, setPaidAmount] = useState(0);
    const [restaurantDetails, setRestaurantDetails] = useState(null);
    const [beachDetails, setBeachDetails] = useState({
        id: 1,
        status: "reserved_full_day",
        half_day_type: "firstHalf",
        details: [],
        isMapBook: false,
    });
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [isHalfDay, setIsHalfDay] = useState(false);
    const [isCheckedCondition, setIsCheckedCondition] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [selectedBox, setSelectedBox] = useState([]);
    const [getLoading, setGetLoading] = useState(false);
    const [hutId, setHutId] = useState(null);
    const [beach, setBeach] = useState(null);
    const { t } = useTranslation('translation', { keyPrefix: 'booking' });
    const [mainServiceFees, setMainServiceFees] = useState(0);


    useEffect(() => {
        console.log(beachDetails, 'beachDetails');
    }, [beachDetails])

    const updateDetailsData = useCallback(async (detailsData, latestBeach) => {
        let reservation_beach = detailsData?.reservation_beach;
        let reservation_restaurant = detailsData?.reservation_restaurant;
        let paidAmountData = 0;
        let isRestaurantAvailable = reservation_restaurant ? true : false;

        if (reservation_beach) {
            const beachId = reservation_beach.beachId ? reservation_beach.beachId : null
            setHutId(reservation_beach?.beach?.hutId)
            let editDate = reservation_beach?.date ? reservation_beach?.date : moment(new Date()).format("YYYY-MM-DD")
            let half_day = reservation_beach?.half_day ? true : false;
            let status = half_day ? "reserved_half_day" : "reserved_full_day"
            let isMapBook = false;
            let reservationDetails = reservation_beach?.details ? JSON.parse(reservation_beach?.details) : [];
            let gridDetails = new Array()
            let mapRecordArr = new Array();
            let bedPosition = new Array();
            let sunBadPosition = new Array()

            let latestDate = moment(editDate, "YYYY-MM-DD").toDate()

            let dateRange = {
                startDate: latestDate,
                endDate: latestDate,
                key: "selection"
            }

            if (reservationDetails && reservationDetails.length > 0) {
                let transatTotal = 0
                let bedTotal = 0;

                for (let index = 0; index < reservationDetails.length; index++) {
                    let ele = reservationDetails[index];
                    if (ele.type.startsWith("sunbed")) {
                        transatTotal++
                    }
                    if (ele.type.startsWith("double_sunbed")) {
                        bedTotal++
                    }
                    if (ele.map_type && ele.map_type == "custom") {

                    } else {
                        await mapRecordArr.push(ele);
                        if (ele.type.startsWith("sunbed")) {
                            await sunBadPosition.push(ele?.position);
                        }
                        if (ele.type.startsWith("double_sunbed")) {
                            await bedPosition.push(ele?.position);
                        }
                        isMapBook = true
                    }
                }

                let singleCharge = getPriceByDay(dateRange, "single", isRestaurantAvailable);

                let doubleCharge = getPriceByDay(dateRange, "double", isRestaurantAvailable);

                let transactObj = {
                    type: "sunbed_0_degrees_free",
                    value: transatTotal,
                    totalSeat: transatTotal,
                    serviceFees: singleCharge,
                    fees: singleCharge * transatTotal,
                    position: sunBadPosition
                }
                let bedObj = {
                    type: "double_sunbed_0_degrees_free",
                    value: bedTotal,
                    totalSeat: bedTotal,
                    serviceFees: doubleCharge,
                    fees: doubleCharge * bedTotal,
                    position: bedPosition,

                }
                await gridDetails.push(transactObj, bedObj);
            }
            setSelectedDate(dateRange)

            let half_day_type = reservation_beach?.half_day_type ? reservation_beach?.half_day_type?.trim() : null;

            let detailsObj = {
                half_day,
                isMapBook,
                status,
                half_day_type,
                id: beachId,
                customerId: reservation_beach?.customerId,
                details: [...gridDetails]
            }
            let customerData = reservation_beach?.customer
            customerData.first_name = customerData?.firstname;
            customerData.last_name = customerData?.lastname;
            setUserData(customerData);
            setIsHalfDay(half_day);
            setBeachDetails({ ...detailsObj });

            if (mapRecordArr.length > 0) {
                for (let index = 0; index < mapRecordArr.length; index++) {
                    let eleItem = mapRecordArr[index]
                    const position = eleItem?.position;
                    if (position) {
                        await handleOnClickBox(position.row, position.col, detailsObj, latestBeach)
                    }
                }
            }
            if (detailsObj && detailsObj.details && detailsObj.details.length > 0) {
                detailsObj.details.map((ele) => {
                    let positionLength = (ele && ele.position) ? ele.position.length : 0;
                    let latestValueWithoutMap = ele.value - positionLength
                    let charge = ele.type.startsWith("sunbed") ? getPriceByDay(dateRange, "single", isRestaurantAvailable) : getPriceByDay(dateRange, "double", isRestaurantAvailable);
                    paidAmountData += Number(latestValueWithoutMap) * charge;
                    if (positionLength > 0) {
                        paidAmountData += positionLength * MAP_FEES
                    }
                })
            }
        }

        if (reservation_restaurant) {
            let resturantDetails = reservation_restaurant?.details ? JSON.parse(reservation_restaurant?.details) : [];
            let totalRes = resturantDetails?.length
            let resturantDetailsObj = { type: "dining_table_2_seater", value: totalRes, totalSeat: totalRes, serviceFees: totalRes * SERVICE_CHARGE_WITH_RESTAURANT }
            let editTime = reservation_restaurant?.reservation_time ? moment(reservation_restaurant?.reservation_time, "HH:mm").format("HH:mm") : "12:00"
            let timeArr = editTime.split(":");
            let restarantObject = {
                id: reservation_restaurant?.restaurant_id,
                status: "reserved_full_day",
                reservation_time: editTime,
                editTimeHour: timeArr[0] || "12",
                editTimeMinutes: timeArr[1] || "00",
                details: [{ ...resturantDetailsObj }]
            }

            setIsRestaurant(true)
            setRestaurantDetails({ ...restarantObject })
            if (restarantObject && restarantObject.details && restarantObject.details.length > 0) {
                restarantObject.details.map((ele) => {
                    paidAmountData += Number(ele.value);
                })
            }
            console.log(paidAmountData, '22');
        }
        setPaidAmount(paidAmountData);
        setGetLoading(false)
    }, []);

    // const getBeachData = async (dateObj, idData) => {
    //     setGetLoading(true);
    //     await getAdminBeachDetails(idData).then(async (resBeach) => {
    //         if (resBeach.status && resBeach.data) {
    //             let activeBeach = resBeach.data;
    //             let isScheduleMatch = false;
    //             let scheduleData = [];

    //             scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];

    //             if (scheduleData.length > 0) {
    //                 for (let j = 0; j < scheduleData.length; j++) {
    //                     if (scheduleData[j] === moment(dateObj).locale("en").format('dddd').toLowerCase()) {
    //                         isScheduleMatch = true;
    //                     }
    //                 }
    //             }

    //             if (activeBeach && activeBeach.plan && isScheduleMatch) {
    //                 let planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
    //                 if (planData.length > 0) {
    //                     planData = planData.filter((ele) => { return { ...ele, available: true } })
    //                 }
    //                 const latestBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
    //                 await setBeach({ ...latestBeach });
    //                 await refreshReservationsCustomersPayments(latestBeach, dateObj)
    //             } else {
    //                 setBeach(null);
    //             }
    //         } else {
    //             setBeach(null);
    //         }
    //     });
    // }

    const handleChangeMapCheckbox = useCallback(async (e) => {
        if (beach && beach.plan && beach.plan.length > 0) {
            setSelectedBox([])
            setBeachDetails(prevState => ({
                ...prevState,
                isMapBook: e.target.checked,
                details: prevState.details && prevState.details.length > 0 ? prevState.details.map((ele) => {
                    delete ele.position
                    return ele
                }) : []
            }));
            let _spotArray = [...beach.plan];
            for await (const [i, IValue] of Object.entries(_spotArray)) {
                for await (const [j, JValue] of Object.entries(IValue)) {
                    _spotArray[i][j] = { ..._spotArray[i][j], selected: false }
                }
            }
            setBeach({ ...beach, plan: _spotArray });
        }
    }, [setBeach, beach])

    const getBeachBeachReservationsCustomersPayments = async (beachId, dateObj) => {
        const resCustomersPaymentsData = await getBeachBeachReservationsCustomersPaymentsForADate(beachId, moment(dateObj).format("YYYY-MM-DD"));
        if (resCustomersPaymentsData && resCustomersPaymentsData.status) {
            const reservationsCustomersPayments = resCustomersPaymentsData.data.map((ele) => {
                ele.details = ele.details ? JSON.parse(ele.details) : [];
                return ele;
            })
            return reservationsCustomersPayments;
        }
        return []
    }

    const refreshReservationsCustomersPayments = async (beachSelectedObj, dateObj) => {
        let reservationsCustomersPayments = undefined;
        if (beachSelectedObj) {
            if (beachSelectedObj.plan) {

                let _gridPlans = [...beachSelectedObj.plan];
                reservationsCustomersPayments = await getBeachBeachReservationsCustomersPayments(beachSelectedObj.id, dateObj);
                if (reservationsCustomersPayments) {
                    // Add loyalty to this object
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        let reservationsResponse = await getHutBeachReservationsForACustomer(beachSelectedObj.id, reservationsCustomersPayments[i].customerId)
                        if (reservationsResponse && reservationsResponse.status && reservationsResponse.data) {
                            reservationsCustomersPayments[i].loyalty = reservationsResponse.data.length
                        }
                    }
                    // Add spot id to this object

                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            reservationsCustomersPayments[i].details[j].id = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y]?.id
                        }
                    }
                    // For each reservations we display the spots reserved
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            let reservationId = reservationsCustomersPayments[i].id;
                            let type = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y]?.type
                            let reservationTYpe = reservationsCustomersPayments[i].details[j].status
                            let newType = type?.substring(0, type.lastIndexOf("_")) + "_" + reservationTYpe
                            if (reservationTYpe == "reserved_half_day") {
                                let halfDayType = reservationsCustomersPayments[i].details[j].half_day_type;
                                if (halfDayType) {
                                    newType += `_${halfDayType}`;
                                }
                            }
                            // If "In", we set the right type (and icon) and available to false
                            if (reservationsCustomersPayments[i].status === 'in') {
                                newType += "_in"
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            } else if (reservationsCustomersPayments[i].status === 'out') {
                                // If "Out", we set to the old type and available to true (the spot can be reserved again)
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            }
                            // If "Noshow", we set to the old type and available to true (the spot can be reserved again)
                            else if (reservationsCustomersPayments[i].status === 'noshow') {
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            }
                            // If we don't have any status, we set available to false
                            else {
                                // TODO for now it's always "unpaid" because se don't manage payment
                                const statusImage = reservationsCustomersPayments[i].payment ? reservationsCustomersPayments[i].payment.status : "unpaid";
                                newType += "_" + statusImage;
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            }
                            // New type
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].type = newType
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].originalType = type
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].reservationId = reservationId
                        }
                    }
                    setBeach(prevState => ({
                        ...prevState,
                        plan: [..._gridPlans],
                    }));

                    await refreshAvailabilities(_gridPlans);
                }
            }
        }
    }

    const refreshAvailabilities = useCallback(async (beachSelectedPlan) => {
        let sunbedFullDayAvailableCountData = 0
        let doubleSunbedFullDayAvailableCountData = 0
        // let doubleSunbedHalfDayAvailableCount = 0;
        // let sunbedHalfDayAvailableCount = 0;

        const spots = beachSelectedPlan

        if (spots.length > 0) {
            if (spots) {
                for await (const [i, IValue] of Object.entries(spots)) {
                    for await (const [j] of Object.entries(IValue)) {
                        if (spots[i][j].type.startsWith("double_sunbed") && spots[i][j].type.indexOf('free') >= 0) {
                            doubleSunbedFullDayAvailableCountData++
                        } else if (spots[i][j].type.startsWith("sunbed") && spots[i][j].type.indexOf('free') >= 0) {
                            sunbedFullDayAvailableCountData++
                        }
                    }
                }
            }
        }
        let updateBeachDetails = beachDetails.details ? beachDetails.details : [];
        if (sunbedFullDayAvailableCountData > 0) {
            let alreadyDetails = _.find(updateBeachDetails, { type: "sunbed_0_degrees_free" })
            let detailsObj = null
            if (alreadyDetails) {
                detailsObj = { ...alreadyDetails, totalFree: sunbedFullDayAvailableCountData, position: [] }
            } else {
                detailsObj = {
                    type: "sunbed_0_degrees_free",
                    value: 0,
                    totalSeat: 0,
                    serviceFees: 0,
                    fees: 0,
                    totalFree: sunbedFullDayAvailableCountData,
                    position: []
                }
            }
            upsert(updateBeachDetails, detailsObj);
        }
        if (doubleSunbedFullDayAvailableCountData > 0) {
            let alreadyDetails = _.find(updateBeachDetails, { type: "double_sunbed_0_degrees_free" })
            let detailsDoubleObj
            if (alreadyDetails) {
                detailsDoubleObj = { ...alreadyDetails, totalFree: doubleSunbedFullDayAvailableCountData }
            } else {
                detailsDoubleObj = {
                    type: "double_sunbed_0_degrees_free",
                    value: 0,
                    totalSeat: 0,
                    serviceFees: 0,
                    fees: 0,
                    totalFree: doubleSunbedFullDayAvailableCountData,
                }
            }
            upsert(updateBeachDetails, detailsDoubleObj);
        }
        setBeachDetails({ ...beachDetails, details: [...updateBeachDetails] })
    }, [beachDetails]);

    const upsert = (array, element) => { // (1)
        const i = array.findIndex(_element => _element.type === element.type);
        if (i > -1) array[i] = element; // (2)
        else array.push(element);
    }

    const handleOnClickBox = useCallback(async (rowIndex, columnIndex, beachDetailsDataDefault = null, latestBeach = null) => {
        let beachData = latestBeach ? latestBeach : beach
        if (beachData && beachData.plan && beachData.plan.length > 0) {
            let beachDetailsData = beachDetailsDataDefault ? beachDetailsDataDefault : beachDetails;
            let _gridArray = [...beachData.plan];
            const existingVal = _gridArray[rowIndex][columnIndex];
            if (existingVal) {
                let updateBeachDetails = beachDetailsData.details ? beachDetailsData.details : [];
                if (updateBeachDetails && updateBeachDetails.length > 0) {
                    if (existingVal.type.startsWith("double_sunbed")) {
                        let doubleBedDetails = _.find(updateBeachDetails, { type: "double_sunbed_0_degrees_free" });
                        let currentObj = { ...existingVal, mapType: existingVal.type, x: rowIndex, y: columnIndex, row: rowIndex, col: columnIndex }
                        let doubleBedPosition = [];
                        if (doubleBedDetails && doubleBedDetails.position && doubleBedDetails.position.length > 0) {
                            let existingPosition = doubleBedDetails.position;
                            const index = existingPosition.findIndex((item) => item.row === rowIndex && item.col === columnIndex);
                            if (index !== -1) {
                                doubleBedPosition = update(existingPosition, {
                                    $splice: [[existingPosition.indexOf({ row: rowIndex, col: columnIndex }), 1]],
                                });
                            } else {
                                doubleBedPosition = [...existingPosition, currentObj]
                            }
                        } else {
                            doubleBedPosition = [{ ...currentObj }]
                        }
                        if (doubleBedPosition && doubleBedPosition.length > doubleBedDetails.value) {
                            return false
                        }
                        upsert(updateBeachDetails, { ...doubleBedDetails, position: doubleBedPosition, mapSelectedTotal: doubleBedPosition.length || 0 });
                        setSelectedBox(doubleBedPosition)
                        setBeachDetails({ ...beachDetailsData, details: updateBeachDetails })
                    } else if (existingVal.type.startsWith("sunbed")) {
                        let doubleBedDetails = _.find(updateBeachDetails, { type: "sunbed_0_degrees_free" });
                        let currentObj = { ...existingVal, x: rowIndex, y: columnIndex, mapType: existingVal.type, type: existingVal.type, row: rowIndex, col: columnIndex }
                        let doubleBedPosition = [];

                        if (doubleBedDetails && doubleBedDetails.position && doubleBedDetails.position.length > 0) {
                            let existingPostion = doubleBedDetails.position;
                            const index = existingPostion.findIndex((item) => item.row === rowIndex && item.col === columnIndex);
                            if (index !== -1) {
                                doubleBedPosition = update(existingPostion, {
                                    $splice: [[existingPostion.indexOf({ row: rowIndex, col: columnIndex }), 1]],
                                });
                            } else {
                                doubleBedPosition = [...existingPostion, currentObj]
                            }
                        } else {
                            doubleBedPosition = [{ ...currentObj }]
                        }
                        if (doubleBedPosition && doubleBedPosition.length > doubleBedDetails.value) {
                            return false
                        }
                        upsert(updateBeachDetails, { ...doubleBedDetails, position: doubleBedPosition, mapSelectedTotal: doubleBedPosition.length || 0 });
                        setSelectedBox(doubleBedPosition)
                        setBeachDetails({ ...beachDetailsData, details: updateBeachDetails })
                    }
                    _gridArray[rowIndex][columnIndex] = { ...existingVal, selected: existingVal?.selected ? !existingVal?.selected : true };
                    await setBeach(prevState => ({
                        ...prevState,
                        plan: [..._gridArray],
                    }));
                } else {
                    toast.error("Veuillez sélectionner au moins un numéro du compteur")
                }
            }
        }
    }, [beach, setBeach, beachDetails]);

    useEffect(() => {
        if (details) {
            setGetLoading(true)
            let reservation_beach = details?.reservation_beach;
            if (reservation_beach) {
                const beachId = reservation_beach.beachId ? reservation_beach.beachId : null
                let editDate = reservation_beach?.date ? reservation_beach?.date : moment(new Date()).format("YYYY-MM-DD")
                if (beachId) {
                    let startDate = editDate;
                    let latestBeach = null

                    if (reservation_beach.beach) {
                        let activeBeach = reservation_beach.beach;
                        let scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];

                        if (activeBeach && activeBeach.plan) {
                            let planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                            if (planData.length > 0) {
                                planData = planData.filter((ele) => { return { ...ele, available: true } })
                            }
                            latestBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
                            setBeach(latestBeach);
                            refreshReservationsCustomersPayments(latestBeach, startDate)
                        }
                    }
                    setTimeout(
                        () => {
                            updateDetailsData(details, latestBeach)
                        },
                        3000
                    );

                }
            }
        }
    }, [details]);

    const prevTab = (e) => {
        e.preventDefault();
        if (tabIndex > 0) {
            setTabIndex(tabIndex - 1);
        }
    };

    const nextTab = (e, type = "all") => {
        e.preventDefault();
        if (tabIndex < 2) {
            if (tabIndex == 0) {
                setTabIndex(tabIndex + 1);
                let invoiceDetails = Object();
                let mapSelectionPrice = 0
                if (beachDetails && beachDetails.details) {
                    if (beachDetails && beachDetails.details && beachDetails.details.length > 0) {
                        beachDetails.details.map((ele) => {
                            let positionLength = (ele && ele.position && beachDetails.isMapBook) ? ele.position.length : 0;
                            let latestValueWithoutMap = ele.value - positionLength
                            let charge = ele.type.startsWith("sunbed") ? getPriceByDay(selectedDate, "single", isRestaurant) : getPriceByDay(selectedDate, "double", isRestaurant);

                            invoiceDetails[ele.type] = Number(latestValueWithoutMap) * charge;
                            if (positionLength > 0 && beachDetails.isMapBook) {
                                mapSelectionPrice += positionLength * MAP_FEES
                            }
                        });
                    }
                }
                invoiceDetails.mapSelectionPrice = mapSelectionPrice;

                let restaurantPrice = 0
                if (restaurantDetails && restaurantDetails.details) {
                    restaurantDetails.details.map((ele) => {
                        restaurantPrice += Number(ele.value);
                    })
                }
                invoiceDetails.restaurantPrice = restaurantPrice;
                setInvoiceDetails(invoiceDetails)
            } else {
                setTabIndex(tabIndex + 1);
            }
        }
    };

    const circleStyle = (index) => {
        if (tabIndex === index) {
            return { backgroundColor: '#FF8478' };
        } else if (tabIndex > index) {
            return { backgroundColor: '#000' };
        } else {
            return { backgroundColor: '#BCC1D1' };
        }
    };

    const labelStyle = (index) => {
        if (tabIndex >= index) {
            return { color: '#000' };
        } else {
            return { color: '#BCC1D1' };
        }
    };

    return (
        <>
            <Layout className='editbookingforming-clsadding-daterang-pcket-laouyt-cla-dding'>
                <FlexBox>
                    <Circle style={circleStyle(0)}></Circle>
                    <Label style={labelStyle(0)}>{t("reservation")}</Label>
                </FlexBox>
                <Line></Line>
                <FlexBox>
                    <Circle style={circleStyle(1)}></Circle>
                    <Label style={labelStyle(1)}>{t("identification")}</Label>
                </FlexBox>
                <Line></Line>
                <FlexBox>
                    <Circle style={circleStyle(2)}></Circle>
                    <Label style={labelStyle(2)}>{t("payment")}</Label>
                </FlexBox>
            </Layout>
            <Box sx={{ bgcolor: '#1A1919' }}>

                <FormLayout className='editbookingforming-clsadding-daterang-pcket-form-laout'>
                    {tabIndex === 0 ? (
                        getLoading ? (
                            <ReservationLayout>
                                <Box justifyContent={"center"} sx={{ display: "flex", mt: 10, alignContent: "center", justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>
                            </ReservationLayout>
                        ) : (
                            beachDetails ?
                                <EditReservation
                                    id={hutId}
                                    details={details}
                                    setSelectedDate={setSelectedDate}
                                    setRestaurantDetails={setRestaurantDetails}
                                    isRestaurant={isRestaurant}
                                    setIsRestaurant={setIsRestaurant}
                                    restaurantDetails={restaurantDetails}
                                    setBeachDetails={setBeachDetails}
                                    beachDetails={beachDetails}
                                    getLoading={getLoading}
                                    beach={beach}
                                    handleOnClickBox={handleOnClickBox}
                                    handleChangeMapCheckbox={handleChangeMapCheckbox}
                                    setIsHalfDay={setIsHalfDay}
                                    isHalfDay={isHalfDay}
                                /> : null
                        )

                    ) : null}

                    {tabIndex === 1 ? (
                        <Identification
                            nextTab={nextTab}
                            prevTab={prevTab}
                            id={hutId}
                            userData={userData}
                            setUserData={setUserData}
                            setTabIndex={setTabIndex}
                            customerData={userData}
                        />
                    ) : null}

                    {tabIndex === 2 ? (
                        <EditPayment
                            nextTab={nextTab}
                            prevTab={prevTab}
                            date={selectedDate}
                            id={hutId}
                            userData={userData}
                            totalAmount={totalAmount}
                            restaurantDetails={restaurantDetails}
                            beachDetails={beachDetails}
                            isCheckedCondition={isCheckedCondition}
                            setIsCheckedCondition={setIsCheckedCondition}
                            invoiceDetails={invoiceDetails}
                            details={details}
                            saveLoading={saveLoading}
                            setSaveLoading={setSaveLoading}
                            paidAmount={paidAmount}
                        />
                    ) : null}

                    <EditBookingForm
                        nextTab={nextTab}
                        tabIndex={tabIndex}
                        date={selectedDate}
                        setSelectedDate={setSelectedDate}
                        id={hutId}
                        setTotalAmount={setTotalAmount}
                        totalAmount={totalAmount}
                        isCheckedCondition={isCheckedCondition}
                        restaurantDetails={restaurantDetails}
                        isRestaurant={isRestaurant}
                        beachDetails={beachDetails}
                        selectedBox={selectedBox}
                        paidAmount={paidAmount}
                        details={details}
                        setMainServiceFees={setMainServiceFees}
                        mainServiceFees={mainServiceFees}
                    />
                </FormLayout>
            </Box>
        </>
    );
}
