/* eslint-disable eqeqeq */
import { Field, Form, Formik } from 'formik';
import { Stack, FormControl, Grid } from '@mui/material';
import { TextField } from 'formik-mui';
import { useCallback } from 'react';
import * as Yup from 'yup';
import PhoneField from '../../../components/form/formik/PhoneField';
import ConfirmButton from './shared/ConfirmButton';
import { useTranslation } from 'react-i18next';

const ContactFormSchema = Yup.object().shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
});



export default function IdentificationForm({ setUserData, id, setTabIndex, customer }) {
    const { t } = useTranslation('translation');

    const defaultValuesData = {
        id: customer?.id || '',
        firstname: customer?.first_name || '',
        lastname: customer?.last_name || '',
        email: customer?.email || '',
        phone: customer?.phone || '',
        message: '',
    };

    const onSaveData = useCallback(async (values) => {
        setUserData({ ...values, id });
        setTabIndex(2);
    }, []);

    return (
        <Grid container direction={'row'} sx={{ mt: 2, mb: 6 }} className='identificationc-cls-addinghereused-grid-contenting-cls-adding'>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} className='identificationc-cls-addinghereused-formik-idea'>
                <Formik
                    enableReinitialize
                    validationSchema={ContactFormSchema}
                    onSubmit={onSaveData}
                    initialValues={defaultValuesData}
                    validateOnBlur={true}
                    validateOnChange={true}
                >
                    {({ errors }) => (
                        <Form autoComplete="off" noValidate className='identificationc-cls-addinghereused-stacformcontrol'>
                            <Stack spacing={3} sx={{ mt: 2, width: '600px', maxWidth: '600px' }} className='width-auto-cacl-heightcls-adding'>
                                {JSON.stringify(errors)}
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            component={TextField}
                                            name="firstname"
                                            fullWidth
                                            placeholder={t("firstName")}
                                            label={t("firstName")}
                                            required
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field component={TextField} name="lastname" fullWidth placeholder={t("lastName")} label={t("lastName")} required />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field component={TextField} name="email" fullWidth placeholder={t("email")} label={t("email")} required />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            component={PhoneField}
                                            name="phone"
                                            fullWidth
                                            placeholder={t("phone")}
                                            label={t("phone")}
                                            required
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row">
                                    <FormControl fullWidth>
                                        <Field
                                            component={TextField}
                                            name="message"
                                            fullWidth
                                            placeholder={t("leaveComment")}
                                            label={t("leaveComment")}
                                            multiline={true}
                                            rows={2}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2}>

                                    <ConfirmButton
                                        text={t("continue")}
                                    />
                                </Stack>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
}
