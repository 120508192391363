import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import { useState } from 'react';
import { Button } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CustomeTooltip from 'src/components/shared/CustomeTooltip';
import umbrella from '../../../assets/icons/umbrella.svg';

const CarouselItem = ({ item, selectedItem, handleMoveLocation }) => {
    const { url = null } = item;
    return (
        <>
            <Box
                className="finder-list-locationss"
                component="img"
                src={url}
                sx={{ width: '100%', height: '10rem', borderRadius: '20px', mb: '10px' }}
            />
            {selectedItem && selectedItem.isPartner && (
                <Box
                    className="finder-list-partner"
                    position={"absolute"}
                    sx={{
                        display: "block",
                        top: 10,
                        zIndex: 999,
                        left: "5%",
                    }}
                >
                    <Button startIcon={<img src={umbrella} alt="umbrella" style={{ width: "20px", height: "20px" }} />}>Partenaire</Button>
                </Box>
            )}
            <Box
                className="finder-list-location"
                position={"absolute"}
                sx={{
                    display: "none",
                    top: 10,
                    zIndex: 999,
                    right: "5%",
                }}
            >
                <CustomeTooltip title={selectedItem?.city?.name} className="finder-list-location-cusonm-tooltip">
                    <Button
                        startIcon={<FmdGoodOutlinedIcon />}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            handleMoveLocation && handleMoveLocation(selectedItem)
                        }}
                        sx={{
                            background: "#fff",
                            minWidth: "20px !important",
                            maxWidth: "20px !important",
                            height: "20px",
                            float: "right",
                            "svg": {
                                marginLeft: "12px",
                                fill: "#616161e6",
                                fontSize: "15px !important"
                            }
                        }}
                    />
                </CustomeTooltip>

            </Box>
        </>
    );
};

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div
        className={className}
        style={{ ...style, display: 'block', marginTop: '0px', marginLeft: "35px", zIndex: 999 }}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            if (onClick) {
                onClick(e)
            }
        }}
    />;
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div
        className={className} style={{ ...style, display: 'block', marginTop: '0px', marginRight: "45px" }}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            if (onClick) {
                onClick(e)
            }
        }}
    />;
}

export default function BeachImageSlider({ item, handleMoveLocation }) {
    const [data, setData] = useState();

    React.useEffect(() => {
        setData(item?.image || [])
    }, [item]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        rows: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <Slider className='finder-slick' {...settings}>
            {(data || []) &&
                (data || []).map((ele) => (
                    <div>
                        <CarouselItem
                            item={ele}
                            selectedItem={item}
                            handleMoveLocation={handleMoveLocation}
                        />
                    </div>
                ))}
        </Slider>
    );
}
