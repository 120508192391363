/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { getHutList, getPlaceDetailsV2 } from 'src/services/api/admin.api';
import PlaceHero from './sections/PlaceHero';
import PlaceDetails from './sections/PlaceDetails';
import { Box } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import PlaceAround from './sections/PlaceAround';
import { getPlaceDetails } from '../../services/api/admin.api';
import moment from 'moment';

const Layout = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    borderBottomRightRadius: '50px',
    [theme.breakpoints.up('lg')]: {
        borderBottomRightRadius: '200px',
    },
}));

export default function PlaceDetailsPage() {
    const [details, setDetails] = useState(null);
    const [data, setData] = useState({});
    const [date, setDate] = useState(null);
    const [coordinates, setCoordinates] = useState([]);
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);

    useEffect(() => {
        if (params?.dfrom && params?.dend) {
            let latestDate = moment(params?.dfrom, "YYYY-MM-DD").toDate()
            let endDate = moment(params?.dend, "YYYY-MM-DD").toDate()
            let dateRange = {
                startDate: latestDate,
                endDate: endDate,
                key: "selection"
            }
            setDate(dateRange)
        } else {
            let todayDate = moment().toDate()
            let dateRange = {
                startDate: todayDate,
                endDate: todayDate,
                key: "selection"
            }
            setDate(dateRange)
        }
    }, [params?.dfrom])

    const getPlaceData = async (idData, dfrom, dend) => {
        try {
            // const res = await getPlaceDetails(idData, { dfrom, dend });
            // if (res.status && res.data) {
            //     setDetails(res.data);
            // }
            const res = await getPlaceDetailsV2(idData, { dfrom, dend });
            if (res.status && res.data) {
                setDetails(res.data);
            }
        } catch (error) {
            return;
        }
    };

    useEffect(() => {
        getPlaceData(id, params?.dfrom, params?.dend);
    }, [id, params?.dfrom, params?.end]);

    const hutListData = async () => {
        const res = await getHutList({ id, count: 10, offset: 30 });
        if (res && res.status && res.data.length > 0) {
            setData(res.data);
        }
    };

    useEffect(() => {
        hutListData();
    }, []);

    return (
        <>
            <Helmet>
                <title> Place Booker | Beach Booker </title>
            </Helmet>
            {/* <PlaceSearch /> */}
            <Box
                sx={{ backgroundColor: '#FBECEB', fontFamily: ['TT Norms Pro Regular', 'Helvetica', 'sans-serif'].join(',') }} className='editbookingforming-clsadding-daterang-pcket-indexningdas'
            >
                <Layout className='editbookingforming-clsadding-daterang-pcketdasddas'>
                    <Box sx={{ mx: 'auto', width: 'fit-content' }}>
                        {details && <PlaceHero data={details} params={params} />}
                        <PlaceDetails
                            data={details}
                            id={id}
                            date={date}
                            setCoordinates={setCoordinates}
                            coordinates={coordinates}
                            setDate={setDate}
                        />
                    </Box>
                </Layout>
            </Box>
            <PlaceAround data={data} id={id} setCoordinates={setCoordinates} coordinates={coordinates} />
        </>
    );
}
