import api from './api';

const API_PREFIX = '/api/b2c';

const getHutList = async (params) => {
  const response = await api.get(`${API_PREFIX}/search-hut`, { params });
  return response;
};

const savingServices = async (params) => {
  const response = await api.post(`${API_PREFIX}/add-hut-service`, { params });
  return response;
};

const getPlaceDetails = async (id, params) => {
  const response = await api.get(`${API_PREFIX}/beach-detail/${id}`, { params });
  return response;
};

const getPlaceDetailsV2 = async (id, params) => {
  const response = await api.get(`/api/v2/b2c/beach-detail/${id}`, { params });
  return response;
};

const geAroundPlaces = async (payload) => {
  const response = await api.post(`${API_PREFIX}/huts/search/advanced`, payload);
  return response;
};

const getPriceListCategories = async (id) => {
  const url = `${API_PREFIX}/get-price-list-categories/${id}?disabled=false`;
  const response = await api.get(url);
  return response;
};

const handleContactForm = async (body) => {
  const url = `${API_PREFIX}/contact-us`;
  const response = await api.post(url, body);
  return response;
};

const handlePartnerSinup = async (body) => {
  const url = `${API_PREFIX}/partner`;
  const response = await api.post(url, body);
  return response;
};

const setupPayment = async (body = {}) => {
  const apiEndPoint = `${API_PREFIX}/setup-intent`;
  const response = await api.post(apiEndPoint, body);
  return response;
};

const createPayment = async (body = {}) => {
  const apiEndPoint = `${API_PREFIX}/create-payment`;
  const response = await api.post(apiEndPoint, body);
  return response;
};

const editPayment = async (body = {}, id) => {
  const apiEndPoint = `${API_PREFIX}/edit-reservation/${id}`;
  const response = await api.post(apiEndPoint, body);
  return response;
};

const createOrder = async (formData) => {
  const apiEndPoint = `${API_PREFIX}/create-order`;
  const response = await api.post(apiEndPoint, formData);
  return response;
};

const getAdminBeachDetails = async (id, params) => {
  const response = await api.get(`api/beach-detail/${id}`, { params });
  return response;
};

const getBeachBeachReservationsCustomersPaymentsForADate = async (beachId, date) => {
  const response = await api.get(`api/reservation-beach-customer-payments/${beachId}/${date}`);
  return response;
}

const getHutBeachReservationsForACustomer = async (beachId, customerId) => {
  const response = await api.get(`api/reservation-beach-customers/${beachId}/${customerId}`);
  return response;
}
export {
  editPayment,
  getHutList,
  savingServices,
  getPlaceDetails,
  geAroundPlaces,
  getPriceListCategories,
  handleContactForm,
  handlePartnerSinup,
  setupPayment,
  createPayment,
  createOrder,
  getAdminBeachDetails,
  getBeachBeachReservationsCustomersPaymentsForADate,
  getHutBeachReservationsForACustomer,
  getPlaceDetailsV2
};
