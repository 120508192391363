import * as React from 'react';
import ProductHeroLayout from './ProductHeroLayout';
import { Button, Divider, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import BeachSearchComponent from 'src/components/shared/BeachSearchComponent';
import { useCallback, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TypographyWithLine from 'src/components/shared/TypographyWithLine';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import DateRangePickerComponent from 'src/components/shared/DateRangePickerComponent';

const backgroundImage = '/assets/images/home-bg.jpg';

export default function ProductHero() {
  const navigate = useNavigate();
  const [city, setCity] = useState('');
  const [date, setDate] = useState(new Date());
  const [searchType, setSearchType] = useState('beach');
  const { t } = useTranslation('translation', { keyPrefix: 'homePage.hero' });
  const mainT = useTranslation('translation');
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const handleNavigate = () => {
    const startDate = date ? moment(selectedValue?.startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
    const endDate = date ? moment(selectedValue?.endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
    if (city && city.itemType == 'city') {
      navigate(`/finder?city=${city.key}&dfrom=${startDate}&dend=${endDate}&is_restaurant=${searchType == 'beach' ? false : true}`, {
        replace: true,
      });
    } else {
      if (city) {
        navigate(
          `/place/${city.id}?dfrom=${startDate}&dend=${endDate}&zoom=7&&defaultRestaurant=${searchType === 'beach' ? false : true}`,
          { replace: true }
        );
      } else {
        navigate(`/finder?dfrom=${startDate}&dend=${endDate}&is_restaurant=${searchType == 'beach' ? false : true}`, {
          replace: true,
        });
      }
    }
  };

  const handleOnchangeCustomer = useCallback((e, value) => {
    setCity(value);
  }, []);

  const handleChangeType = useCallback((e) => {
    setSearchType(e.target.value);
  }, []);
  const theme = useTheme();
  const inputStyles = {
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '13px',
      borderColor: 'transparent',
    },
    '.MuiAutocomplete-inputRoot': {
      height: theme.breakpoints.up('lg') ? '70px' : theme.breakpoints.up('sm') ? '60px' : '50px', // Adjust height for lg, sm, and xs screens
      // width: theme.breakpoints.up('lg') ? "550px" : theme.breakpoints.up('xs') ? '50%' : '50%', // Adjust width for lg, sm, and xs screens
    },
  };

  const handleChangeDate = React.useCallback(
    (e) => {
      if (e.startDate && e.endDate) {
        const lDate = moment(e.startDate).format('YYYY-MM-DD');
        const eDate = moment(e.endDate).format('YYYY-MM-DD');
        setSearchParams({ dfrom: lDate, dend: eDate });
        setSelectedValue(e);
      }
    },
    [setSearchParams]
  );

  return (
    <ProductHeroLayout
      className="finder-list-location-xdawd-product-herocls-adding"
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: '50%',
        backgroundColor: '#7fc7d9',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Stack

        mt="-6rem"
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={2}
        sx={{
          padding: '20px', // Added padding for mobile and tablet screens
          textAlign: 'center',
        }}
        className='home-hero-unit'
      >
        <TypographyWithLine
          variant="h1"
          sx={{
            color: 'white',
            width: '100%', // Full width for mobile and tablet screens
            fontSize: '2.5rem',
            fontFamily: 'TT Norms Pro Bold, Helvetica,sans-serif !important',
            fontWeight: 'bold',
          }}
          text={t('mainTitle')}
        />

        <TypographyWithLine
          className="font-tt-regular "
          text={t('subTitle')}
          sx={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '2.5rem',
          }}
        />

        <Typography
          className="font-normal"
          sx={{
            color: 'white',
            fontSize: '1.5rem',
            fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
          }}
        >
          1<sup>er</sup> {t('description')}
        </Typography>

        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <Select
            sx={{
              background: '#fff',
              border: 'none',
              height: '50px',
              borderRadius: '19px',
              fontSize: '1.5rem',
              textAlign: "justify",
              ".MuiSelect-select": {
                fontSize: "16px",
                fontFamily: "'TT Norms Pro Regular'"
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '60%',
                  fontSize: '1rem',
                  color: '#06133a',
                  '& .MuiMenuItem-root': {
                    padding: 2,
                    fontFamily: "'TT Norms Pro Regular'"
                  },
                },
              },
              variant: 'menu',
              transformOrigin: { vertical: 'top', horizontal: 'center' },
              getContentAnchorEl: null,
              disableScrollLock: true,
            }}
            size="large"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchType}
            onChange={handleChangeType}
            shrink={true}
          >
            <MenuItem value={'beach'}>{mainT.t('beach')}</MenuItem>
            <MenuItem value={'restaurant'}>{mainT.t('restaurant')}</MenuItem>
          </Select>
        </FormControl>

        <BeachSearchComponent
          label={t('or')}
          shrink={false}
          searchType={searchType}
          option={{ itemType: 'city', name: 'Around Me', key: 'around_me' }}
          handleOnchangeCustomer={handleOnchangeCustomer}
          style={inputStyles}
        />

        <DateRangePickerComponent
          type="home"
          defaultValue={selectedValue}
          handleChangeDate={handleChangeDate}
          dateClassNameProps={'landing-calendar-hero'}
          shrink={true}
          style={{
            background: '#fff',
            borderRadius: '19px',
            '.MuiInputBase-input': {
              marginTop: '12px',
            },
            '.MuiInputLabel-root': {
              marginTop: "15px",
              color: "#06133a",
              background: "transparent",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "1.42em",
              fontFamily: "TT Norms Pro Regular, Helvetica,sans-serif !important"
            }
          }}
        />
        {/* 
        <DatePickerComponent
          setDate={setDate}
          handleChangeDate={handleChangeDate}
          className={'landing-calendar'}
          shrink={false}
          style={{
            background: '#fff',
            borderRadius: '19px',
            '.MuiInputBase-input': {
              marginTop: '10px',
            },
          }}
        /> */}

        <Button
          fullWidth
          size="large"
          variant="contained"
          startIcon={<SearchIcon size="large" sx={{ width: '24px', height: '24px' }} />}
          sx={{
            border: 'none',
            borderRadius: '14px',
            fontSize: '1rem',
            pt: '20px',
            pb: '20px',
            fontWeight: 700,
            textTransform: 'uppercase',
            fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
          }}
          onClick={handleNavigate}
        >
          <Divider
            orientation="vertical"
            flexItem
            color="#fff"
            sx={{ height: '14px', width: '3px', mr: 2, ml: 0.5, mt: '-6px' }}
          />
          {t('search')}
        </Button>
      </Stack>
    </ProductHeroLayout>
  );
}
