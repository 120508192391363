import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default function CartButtons({ prevDisabled, nextDisabled = false, handleOnClickNext, handleOnClickPrev, selectedNumber = 0 }) {
    return (
        <>
            <div className='cartbtn-cls'>
                <button
                    className='both-side-add'
                    disabled={prevDisabled}
                    onClick={handleOnClickPrev}
                    style={{
                        border: `1px solid ${prevDisabled ? '#E3E3E3' : '#06133a'}`,
                        padding: '2px 4px',
                        color: '#E3E3E3',
                        borderRadius: '100%',
                        background: "#fff",
                    }}
                >
                    <RemoveIcon sx={{ color: prevDisabled ? "#e4e4e4" : "#06133a" }} />
                </button>
                <Typography sx={{ color: 'black', fontWeight: '600', fontSize: '17px' }}>{selectedNumber}</Typography>
                <button
                    className='both-side-add'
                    disabled={nextDisabled}
                    onClick={handleOnClickNext}
                    style={{
                        border: `1px solid ${nextDisabled ? '#E3E3E3' : '#06133a'}`,
                        padding: '2px 4px',
                        color: '#E3E3E3',
                        borderRadius: '100%',
                        background: "#fff",
                    }}
                >
                    <AddIcon sx={{ color: nextDisabled ? "#e4e4e4" : "#06133a" }} />
                </button>
            </div>
        </>
    );
}
