/* eslint-disable no-unused-expressions */
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Typography,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableSortLabel,
    TableBody,
    Card,
    styled,
    Toolbar,
    InputAdornment,
    OutlinedInput,
    Skeleton,
    Collapse,
    CardHeader,
    IconButton,
    Pagination,
    Stack
} from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { visuallyHidden } from '@mui/utils';
import { get } from 'lodash';
import { Icon } from '@iconify/react';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useDebounce from '../../hooks/useDebounce';
import SearchNotFound from '../shared/SearchNotFound';
import Scrollbar from '../scrollbar/Scrollbar';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3)
}));

const SearchInput = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));

const DataTable = forwardRef(({
    columns,
    data,
    idKey = "id",
    defaultOrder = 'asc',
    defaultOrderBy,
    onSelect,
    onSortChange,
    onPageChange,
    onLimitChange,
    onChange,
    onRowClick,
    limit = 10,
    totalRow = 0,
    renderBulkAction,
    initialLoading,
    selectable,
    collapsible,
    hasFilter = true,
    cardProps,
    renderDetailRow,
    size = "medium",
    detailRowTitle,
    topAction,
    filterToolBar,
    isPagination = true
}, ref) => {
    const theme = useTheme();
    const isMountedRef = useIsMountedRef();
    const isLight = theme.palette.mode === 'light';
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState('');
    const searchText = useDebounce(search, 500);
    const [rowsPerPage, setRowsPerPage] = useState(limit);
    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [openedRows, setOpenedRows] = useState({});

    useImperativeHandle(ref, () => ({
        refresh: () => {
            applyFilters();
        },
        clearSelection: () => {
            setSelected([]);
        }
    }));


    const handleRowClick = useCallback(
        (row) => (e) => {
            const { id } = row;
            onRowClick && onRowClick(row);
            if (selectable && !onRowClick) {
                const selectedIndex = selected.indexOf(id);
                const newSelected = [...selected];
                if (selectedIndex >= 0) {
                    newSelected.splice(selectedIndex, 1)
                } else {
                    newSelected.push(id);
                }
                setSelected(newSelected);
                onSelect(newSelected)
            }
        },
        [onRowClick, onSelect, selectable, selected],
    )

    const handleCheckUncheck = useCallback(
        (row) => (e) => {
            e.stopPropagation();
            const { id } = row;

            const selectedIndex = selected.indexOf(id);
            const newSelected = [...selected];
            if (selectedIndex >= 0) {
                newSelected.splice(selectedIndex, 1)
            } else {
                newSelected.push(id);
            }
            setSelected(newSelected);
            onSelect(newSelected)
        },
        [onSelect, selected],
    )

    const handleSelectAll = useCallback(
        (e) => {
            const { checked } = e.target
            if (checked) {
                const selected = data?.map((n) => n[idKey]);
                setSelected(selected);
                return;
            }
            setSelected([]);
        },
        [idKey, data],
    )

    const handleSortChange = useCallback(
        (columnName) => () => {
            const dir = orderBy === columnName && order === 'asc' ? 'desc' : 'asc';
            setOrder(dir);
            setOrderBy(columnName);
            onSortChange && onSortChange({
                order: dir,
                orderBy: columnName,
            })
        },
        [orderBy, order, onSortChange],
    )

    const handleSearchChange = useCallback(
        (event) => {
            const { value } = event.target;
            setSearch(value);
            setPage(0);
        },
        [],
    )

    const handlePageChange = useCallback(
        (event, value) => {
            // onPageChange && onPageChange(value);
            setPage(value)
        },
        [],
    )

    // const handleChangeRowsPerPage = useCallback(
    //     (event) => {
    //         onLimitChange && onLimitChange(event.target.value);
    //         setRowsPerPage(event.target.value)
    //         onPageChange && onPageChange(0);
    //         setPage(0)
    //     },
    //     [onLimitChange, onPageChange],
    // )

    const applyFilters = useCallback(
        () => {
            // const filter = {};
            // const searchableColumns = chain(columns).filter((column) => column.isSearchable).value();
            // if (searchText && searchableColumns.length > 0) {
            //     filter.search = searchText;
            //     filter.searchIn = map(searchableColumns, (item) => pick(item, ['name', 'type']));
            // } else {
            //     filter = {}
            // }
            // const filter = { search: searchText };
            // filter.search = searchText;
            if (orderBy && order) {
                const request = {
                    ...isPagination && { page },
                    sort: orderBy || null,
                    sortBy: order,
                    ...searchText && { search: searchText },
                }
                // mapFilterGraphQLQuery(requlest)
                onChange(request);
            } else {
                const request = {
                    ...isPagination && { page },
                    ...searchText && { search: searchText },
                }
                // mapFilterGraphQLQuery(request)
                onChange(request);
            }
        },
        [searchText, page, orderBy, order, onChange],
    )

    useEffect(() => {
        applyFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, orderBy, order, searchText])


    useEffect(() => {
        if (isMountedRef.current && initialLoading) {
            applyFilters()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef])

    const colSpan = columns.length + (selectable ? 1 : 0) + (collapsible ? 1 : 0)

    const toggleOpenRow = useCallback(
        (id, open) => (e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenedRows((state) => ({
                ...state,
                [id]: open
            }));
        },
        [],
    )

    return (
        <Card {...cardProps} className='datatablecard-cls-adding'>
            {detailRowTitle && (
                <CardHeader title={detailRowTitle} />
            )}
            {hasFilter && (
                <StyledToolbar
                    sx={{
                        ...(selected.length > 0 && {
                            color: isLight ? 'primary.main' : 'text.primary',
                            bgcolor: isLight ? 'primary.lighter' : 'primary.dark'
                        })
                    }}
                >
                    {selected.length > 0 ? (
                        <Typography component="div" variant="subtitle1">
                            {selected.length} selected
                        </Typography>
                    ) : (
                        <SearchInput
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <Box component={Icon}
                                        sx={{ color: 'text.disabled' }}
                                    />
                                </InputAdornment>
                            }
                        />
                    )}
                    {topAction && topAction}
                    {selected.length > 0 && renderBulkAction && renderBulkAction(selected)}
                </StyledToolbar>
            )}
            {filterToolBar && filterToolBar}
            <Scrollbar>
                <TableContainer>
                    <Table size={size}>
                        <TableHead>
                            <TableRow>
                                {collapsible && (
                                    <TableCell padding="checkbox" />
                                )}
                                {selectable && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selected.length > 0 && selected.length < data?.length}
                                            checked={data?.length > 0 && selected.length === data?.length}
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                )}
                                {columns.map((column, index) => {
                                    const sortDirection = orderBy === column.name ? order : false;
                                    return (
                                        <TableCell
                                            key={index}
                                            {...column.props}
                                            sortDirection={sortDirection}
                                        >
                                            {column.isSortable ? (
                                                <TableSortLabel
                                                    hideSortIcon
                                                    active={orderBy === column.name}
                                                    direction={orderBy === column.name ? order : 'asc'}
                                                    onClick={handleSortChange(column.name)}
                                                    sx={{
                                                        "&.Mui-active": {
                                                            color: "#637381"
                                                        }
                                                    }}
                                                >
                                                    {column.label}
                                                    {orderBy === column[idKey] ? (
                                                        <Box sx={{ ...visuallyHidden }}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            ) : column.label}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {!data && [1, 2, 3, 4, 5].map((index) => (
                                <TableRow
                                    hover={selectable || !!onRowClick}
                                    key={index}
                                    tabIndex={-1}
                                    role="checkbox"
                                >
                                    {collapsible && (
                                        <TableCell padding="checkbox">
                                            <Skeleton />
                                        </TableCell>
                                    )}
                                    {selectable && (
                                        <TableCell padding="checkbox">
                                            <Skeleton />
                                        </TableCell>
                                    )}

                                    {columns.map((column, index) => (
                                        <TableCell key={index} {...column.props}>
                                            {column.skeletonRender ? column.skeletonRender() : (<Skeleton width="90%" />)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            {data?.map((row) => {
                                const isItemSelected = selected.indexOf(row.id) !== -1;
                                const open = openedRows[row.id];
                                return (
                                    <>
                                        <TableRow
                                            hover
                                            key={row.id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                            onClick={handleRowClick(row)}
                                        >
                                            {collapsible && (
                                                <TableCell onClick={(e) => e.stopPropagation()} padding="checkbox">
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={toggleOpenRow(row.id, !open)}
                                                    >
                                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                            {selectable && (
                                                <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox onClick={handleCheckUncheck(row)} checked={isItemSelected} />
                                                </TableCell>
                                            )}
                                            {columns.map((column, index) => (
                                                <TableCell key={index} {...column.props} sx={{ color: "#637381" }}>
                                                    {column.render ? column.render(row) : (get(row, column.name) || column.defaultValue)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    {renderDetailRow && renderDetailRow(row)}
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}

                        </TableBody>
                        {data?.length === 0 && (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={colSpan}
                                    >
                                        <Box sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={search} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>
            {totalRow > 10 && (
                <Stack spacing={2} direction={"row"} justifyContent={"center"} sx={{ mt: 4, mb: 4 }}>
                    <Pagination
                        page={page}
                        onChange={handlePageChange}
                        siblingCount={1}
                        boundaryCount={1}
                        count={Math.round(totalRow / rowsPerPage)}
                        variant="outlined"
                        shape="rounded"
                    />
                </Stack>
            )}
            {/* <TablePagination
                // rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={totalRow}
                rowsPerPage={rowsPerPage}
                page={page}
                onChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Card>
    );
})


export default DataTable;

DataTable.propTypes = {
    columns: PropTypes.any,
    data: PropTypes.array,
    defaultOrder: PropTypes.string,
    defaultOrderBy: PropTypes.string,
    idKey: PropTypes.string,
    limit: PropTypes.number,
    totalRow: PropTypes.number,
    initialLoading: PropTypes.bool,
    selectable: PropTypes.bool,
    collapsible: PropTypes.bool,
    hasFilter: PropTypes.bool,
    renderBulkAction: PropTypes.any,
    onSortChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onLimitChange: PropTypes.func,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    onRowClick: PropTypes.func,
    renderDetailRow: PropTypes.func,
    detailRowTitle: PropTypes.string,
    cardProps: PropTypes.any,
    size: PropTypes.string,
    topAction: PropTypes.any,
    filterToolBar: PropTypes.any,
};
