/* eslint-disable array-callback-return */
import { Helmet } from 'react-helmet-async';
import ProductHero from './sections/ProductHero';
import ProductPartnerBeach from './sections/ProductPartnerBeach';
import ProductBeachBooker from './sections/ProductBeachBooker';
import ProductConnect from './sections/ProductConnect';
import ProductPartner from './sections/ProductPartner';

export default function HomePage() {


    return (
        <>
            <Helmet className="helmetcircle-adding-booker">
                <title> Beach Booker | Beach Booker </title>
            </Helmet>
            <ProductHero className="finder-list-location-xdawd-producthero-cks-adding" />
            <ProductPartnerBeach className="finder-list-location-xdawd-partner-brach-hering" />
            <ProductBeachBooker className="finder-list-location-xdawd-booker-finder" />
            <ProductConnect className="finder-list-location-xdawd-connet-parnterdsfs" />
            <ProductPartner className="finder-list-location-xdawd-pdasfdsfds" />
        </>
    );
}
