import { InputLabel, MenuItem, Select } from '@mui/material';

export default function SelectComponent({
    value = '',
    onChange,
    options = [],
    label,
    ...other
}) {
    return (<>
        {label && (
            <InputLabel 
            className='inputlabeling-cls-adding'
            sx={{
                mt: "-6px",
                "&.MuiInputLabel-shrink": {
                    marginTop: "3px",
                    marginLeft: "2px"
                }
            }} id="filter-simple-select-label">{label}</InputLabel>
        )}
        <Select
            value={value}
            onChange={onChange}
            label={label}
            className='selecting-lalbeling-cls-adding'
            sx={{
                background: '#fff',
                border: 'none',
                height: '40px',
                borderRadius: '4px',
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        fontSize: '16px',
                        fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                        color: '#06133a',
                        '& .MuiMenuItem-root': {
                            padding: 2,
                        },
                    },
                },
                variant: 'menu',
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                getContentAnchorEl: null,
                disableScrollLock: true,
            }}
            size="medium"
            labelId="filter-simple-select-label"
            id="filter-simple-select-label"
            shrink={false}
            {...other}
        >
            {options.map((ele, index) => {
                return (
                    <MenuItem
                        value={ele?.value}
                        key={index}
                    >
                        {ele?.label}
                    </MenuItem>
                )
            })}
        </Select>
    </>
    );
}

