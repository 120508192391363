import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CartButtons from 'src/components/cart-button/CartButtons';
import moment from 'moment';
import DateRangePickerComponent from 'src/components/shared/DateRangePickerComponent';
import { useCallback } from 'react';
import { getPriceByCategories, getPriceByDay } from 'src/utils/common';
import { useTranslation } from 'react-i18next';

const BoxLayout = styled(Box)(({ theme }) => ({
    width: '100%',
    marginTop: '30px',
    [theme.breakpoints.up('lg')]: {
        width: '25rem',
    },
}));

const FlexBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
});

export default function ProductBooking({ date, setDate, beachesStats, priceLists, startDate, endDate, noBookingText }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const [sunBed, setSunBed] = useState(0);
    const [restaurantBooked, setRestaurantBooked] = useState(0);
    const [bed, setBed] = useState(0);
    const { t } = useTranslation('translation');
    const restaurantData = String(params?.defaultRestaurant) === "true"

    const handleNavigate = () => {
        let latestDate = date.startDate ? moment(date.startDate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")
        let latestEndDate = date.endDate ? moment(date.endDate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")

        let parmasData;
        if (restaurantData) {
            parmasData = { dfrom: latestDate, dend: latestEndDate, defaultRestaurant: true, restaurantBooked }
        } else {
            parmasData = { dfrom: latestDate, dend: latestEndDate }
        }
        if (sunBed > 0) {
            parmasData.sunbed = sunBed
        }
        if (bed > 0) {
            parmasData.double_sunbed = bed
        }
        const paramsNames = new URLSearchParams(parmasData);
        const url = `/place/${id}/book?${paramsNames}`;
        navigate(url);
    };

    const handleUrlData = React.useCallback((type, value) => {
        setSearchParams({
            ...params,
            [type]: value,
        })
    }, [params]);

    const handleChangeDate = useCallback((e) => {
        setSunBed(0)
        setBed(0)
        if (e.startDate && e.endDate) {
            const lDate = moment(e.startDate).format('YYYY-MM-DD');
            const eDate = moment(e.endDate).format('YYYY-MM-DD');
            setSearchParams({ ...params, dfrom: lDate, dend: eDate });
            setDate(e);
        }
    }, [setDate, setSearchParams]);

    const handleDateRanges = (scheduleStartDate, scheduleEndDate, userStartDate, userEndDate) => {
        if (
            (moment(userStartDate).isBefore(moment(scheduleStartDate)) || moment(userEndDate).isAfter(moment(scheduleEndDate)))) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <BoxLayout className='editbookingforming-clsadding-daterang-pcket-placebooking-cls-adding-top'>
            <form
                className='onsubmit-type'
                onSubmit={(e) => e.preventDefault()}
                style={{
                    color: 'black',
                    border: '2px solid #EAEDF8',
                    padding: '15px 20px',
                    borderRadius: '20px',
                    width: '100%',
                    height: 'fit-content',
                }}
            >
                <Box
                    className='onsubmit-typebx'
                    sx={{

                        border: '2px solid #EAEDF8',
                        borderRadius: '20px',
                        position: 'relative',
                        marginTop: '-5px',
                    }}>
                    <DateRangePickerComponent
                        defaultValue={date}
                        handleChangeDate={handleChangeDate}
                    />
                </Box>
                {restaurantData && (
                    <>
                        <FlexBox mt="25px" className='onsubmit-typebxflexbox'>
                            <Typography className='onsubmit-typebxflexbox-covered' sx={{ color: 'black', fontSize: '18px', fontWeight: '600' }}>
                                {restaurantBooked > 1 ? t("covered") : t("coveredSingle")}
                            </Typography>
                            <FlexBox className='onsubmit-typebxflexbox-covered-griding'>
                                <CartButtons
                                    className='onsubmit-typebxflexbox-covered-griding-cart-btnd'
                                    handleOnClickPrev={() => {
                                        handleUrlData("restaurantBooked", restaurantBooked - 1)
                                        setRestaurantBooked(restaurantBooked - 1)
                                    }}
                                    prevDisabled={restaurantBooked <= 0}
                                    handleOnClickNext={() => {
                                        handleUrlData("restaurantBooked", restaurantBooked + 1)
                                        setRestaurantBooked(restaurantBooked + 1)
                                    }}
                                    selectedNumber={restaurantBooked}
                                />
                            </FlexBox>
                        </FlexBox>
                        <FlexBox mt="15px" className='onsubmit-typebxflexbox'>
                            <Typography className='onsubmit-typebxflexbox-covered' sx={{ color: 'black', fontSize: '18px', fontWeight: '600' }}>{t("total")}</Typography>
                            <FlexBox>
                                {/* <Typography color="black" fontSize="16px" fontWeight="600">
                                    {t("total")}
                                </Typography> */}
                                <Typography color="black" fontSize="22px" fontWeight="600" mb="10px">
                                    {restaurantBooked} €
                                </Typography>
                            </FlexBox>
                        </FlexBox>

                        <FlexBox my="25px" className='onsubmit-typebxflexbox-covered-griding-cart-btnd-gridoff'>
                            <Button
                                className='onclicking-type-dada'
                                onClick={handleNavigate}
                                type="submit"
                                sx={{
                                    textAlign: 'center',
                                    bgcolor: '#FF8478',
                                    width: '100%',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#E55446' },
                                    fontSize: '15px',
                                    borderRadius: '15px',
                                    py: '10px',
                                }}
                            >
                                {t("book")}
                            </Button>
                        </FlexBox>
                    </>
                )}
                {!restaurantData && (
                    <>
                        {beachesStats && (beachesStats?.sunbed?.free > 0 || beachesStats?.double_sunbed?.free > 0) && handleDateRanges(startDate, endDate, date.startDate, date.endDate) === true ? (
                            <>
                                <FlexBox mt="25px">
                                    <Typography sx={{ color: 'black', fontSize: '18px', fontWeight: '600' }}>{t("deckchairs")}</Typography>
                                    <FlexBox>
                                        <CartButtons
                                            handleOnClickPrev={() => {
                                                handleUrlData("sunbed", sunBed - 1)
                                                setSunBed(sunBed - 1)
                                            }}
                                            prevDisabled={sunBed <= 0}
                                            handleOnClickNext={() => {
                                                handleUrlData("sunbed", sunBed + 1)
                                                setSunBed(sunBed + 1)
                                            }}
                                            nextDisabled={beachesStats && beachesStats.sunbed && sunBed >= beachesStats.sunbed.free}
                                            selectedNumber={sunBed}
                                        />
                                    </FlexBox>
                                </FlexBox>
                                <FlexBox my="25px">
                                    <Typography sx={{ color: 'black', fontSize: '18px', fontWeight: '600' }}>{t("bed")}</Typography>
                                    <FlexBox>
                                        <CartButtons
                                            handleOnClickPrev={() => setBed(bed - 1)}
                                            prevDisabled={bed <= 0}
                                            nextDisabled={beachesStats && beachesStats.double_sunbed && bed >= beachesStats.double_sunbed.free}
                                            handleOnClickNext={() => setBed(bed + 1)}
                                            selectedNumber={bed}
                                        />
                                    </FlexBox>
                                </FlexBox>
                                <FlexBox>
                                    <Typography color="black" fontSize="16px" fontWeight="600">
                                        {t("total")}
                                    </Typography>
                                    <Typography color="black" fontSize="22px" fontWeight="600" mb="10px">
                                        {(getPriceByDay(date, "single", false) * sunBed) +
                                            (getPriceByDay(date, "double", false) * bed) +
                                            (sunBed > 0 ? getPriceByCategories(priceLists, "sunbed") * sunBed : 0) +
                                            (bed > 0 ? getPriceByCategories(priceLists, "double_sunbed") * bed : 0)
                                        } €
                                    </Typography>
                                </FlexBox>
                                <Button
                                    className='alling-tyuoip-cls'
                                    onClick={handleNavigate}
                                    type="submit"
                                    sx={{
                                        textAlign: 'center',
                                        bgcolor: '#FF8478',
                                        width: '100%',
                                        color: '#fff',
                                        '&:hover': { backgroundColor: '#E55446' },
                                        fontSize: '15px',
                                        borderRadius: '15px',
                                        py: '10px',
                                    }}
                                >
                                    {t("book")}
                                </Button>
                            </>
                        ) : (
                            <FlexBox
                                className='namingtypebtn-cls-add'
                                sx={{
                                    mt: 2,
                                    backgroundColor: "#eff6ff",
                                    color: "#1d4ed8",
                                    fontWeight: 700,
                                    padding: "10px 20px"
                                }}>
                                <Typography fontWeight={700} fontFamily={'"TT Norms Pro Regular",Helvetica,sans-serif!important'} fontSize={14}>
                                    {/* {t("alreadyReservedText")} */}
                                    {noBookingText}
                                </Typography>
                            </FlexBox>
                        )}
                    </>
                )}
            </form>
        </BoxLayout>
    );
}
