/* eslint-disable consistent-return */
import { useRoutes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MainLayout from './layouts/dashboard/MainLayout';
import ContactPage from './pages/Contact';
import PartnerSignupPage from './pages/PartnerSignup';
import PartnerPage from './pages/Partner';
import PlaceDetailsPage from './pages/Place';
import BookingPage from './pages/Booking';
import Footer from './layouts/footer/footer';
import FinderPage from './pages/Finder';
import LoginPage from './pages/Login/Index';
import RegisterPage from './pages/Register/Index';
import AuthGuard from './guards/AuthGuard';
import MyBookingPage from './pages/MyBooking/Index';
import EditBookingPage from './pages/MyBooking/EditBookingPage';
import ChangePasswordPage from './pages/ChangePassword/Index';
import ResetPassword from './components/Authentication/ResetPassword';
import SetNewPassword from './components/Authentication/SetNewPassword';

export default function Router(props) {
  const routes = useRoutes([
    // {
    //   path: 'login',
    //   element: <GuestGuard><LoginPage /></GuestGuard>,
    // },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <HomePage /> },
        { path: 'contact', element: <ContactPage /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'set-password/:token', element: <SetNewPassword /> },
        { path: 'signup', element: <RegisterPage /> },
        { path: 'partner', element: <PartnerPage /> },
        { path: 'place/:id', element: <PlaceDetailsPage /> },
        { path: 'partner/signup', element: <PartnerSignupPage /> },
      ],
    },
    {
      path: 'place/:id/book',
      element: (
        <>
          <BookingPage />
          <Footer />
        </>
      ),
    },
    {
      path: 'finder',
      exact: true,
      element: <FinderPage exact />,
    },
    {
      path: '/member',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { path: ':id/my-booking', element: <MyBookingPage /> },
        { path: 'edit-booking/:id', element: <EditBookingPage /> },
        { path: 'change-password', element: <ChangePasswordPage /> }
      ],
    },
  ]);

  return routes;
}
