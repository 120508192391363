import PropTypes from 'prop-types';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import FinderSearchBar from './FinderSearchBar';
import ProSiteButton from 'src/layouts/dashboard/main-header/ProSiteButton';

const headerStyles = {
  background: '#fff',
  height: '82px',
  justifyContent: 'center',
  padding: 0.5,
  boxShadow: '0 25px 50px -12px rgba(0,0,0,.5)!important',
  zIndex: '10',
  position: 'relative'
};

export default function FinderHeader({ cities, setSelectedSearchItem, selectedSearchItem, refreshPageData, params }) {
  return (
    <AppBar
      className="finder-list-location-appbar header-cls-adding-type"
      position="static"
      sx={headerStyles}
    >
      <Toolbar
        className='tooling-bar-cls-adding'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          className='typograph-cls-adding'
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            // pl: 5,
            fontFamily: 'monospace',
            fontWeight: 300,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
            minWidth: {
              xs: '100px',
              md: '300px',
              lg: '300px',
            },
          }}
        >
          <Box className='primary-svg-lc-sadding' component="img" alt="logo" src="/assets/logo-primary.svg" sx={{ maxWidth: { xs: '6rem', sm: "15rem", md: '15rem' } }}/>
        </Typography>
        <FinderSearchBar
          refreshPageData={refreshPageData}
          cities={cities}
          setSelectedSearchItem={setSelectedSearchItem}
          selectedSearchItem={selectedSearchItem}
          params={params}
        />
        <ProSiteButton />
      </Toolbar>
    </AppBar>
  );
}

FinderHeader.propTypes = {
  onOpenNav: PropTypes.func,
};