import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TypographyWithLine from 'src/components/shared/TypographyWithLine';
import { Button, Divider } from '@mui/material';
import sendIcon from 'src/assets/icons/send.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ProductConnect() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'homePage.connect' });

  return (
    <Box component="section" sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#1a1919' }} className="finder-list-location-xdawd-product-conntet">
      <Container sx={{ mt: 4, mb: 4, display: 'flex', position: 'relative' }} maxWidth="lg" className="finder-list-location-xdawd-product-conntedsat">
        <Grid container spacing={2} justifyContent="center" alignItems="center" className="finder-list-location-xdawd-product-left">
          <Grid item xs={12} sm={6} className="finder-list-location-xdawd-product-unique">
            <TypographyWithLine
              sx={{
                border: '1px solid red',
                fontFamily: '"TT Norms Pro Medium", Helvetica, sans-serif!important',
                fontSize: '1.6rem',
              }}
              whiteSpace={{ sm: "normal", lg: "nowrap" }}
              color="#fff"
              text={t('title')}
              line={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="finder-list-location-xdawd-product-mixo">
            <Button
              className='btn-progessing-app-cls'
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '2px solid #fff',
                p: '0px 20px',
                borderRadius: '15px',
                '@media (min-width: 601px)': {
                  marginLeft: 'auto',
                },
              }}
              onClick={() => navigate('/contact')}
            >
              <Box component="img" src={sendIcon}></Box>
              <Divider
                orientation="vertical"
                color="#fff"
                sx={{ fontSize: '25px', height: '20px', width: '3px', mx: 1.5 }}
              />
              <TypographyWithLine
                text={t('buttonText')}
                sx={{
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#fff',
                  '&:before': {
                    width: '0',
                  },
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductConnect;
