import api from './api';

const API_PREFIX = '/api/b2c';

const userLogin = async (body) => {
  const response = await api.post(`${API_PREFIX}/login`, body);
  return response;
};

const getUserDetails = async (userId) => {
  const response = await api.get(`${API_PREFIX}/get-user`);
  return response;
};

const changeUserPassword = async (body) => {
  const response = await api.post(`${API_PREFIX}/change-password`, body);
  return response;
};

const getBeachSearchData = async (text, searchType = null) => {
  if (!text) {
    return;
  }
  let params = new URLSearchParams();
  params.append('city', text);

  if (searchType && searchType === "restaurant") {
    params.append("is_restaurant", true);
  } else {
    params.append("is_restaurant", false);
  }
  const response = await api.get(`${API_PREFIX}/search-hut`, { params });
  return response;
};

const checkResturantAvailabel = async (params) => {
  try {
    const response = await api.get(`${API_PREFIX}/check-availabilty/`, {
      params
    });
    return response;
  } catch (err) {
  }
};

export { getBeachSearchData, userLogin, getUserDetails, changeUserPassword, checkResturantAvailabel };
