import * as React from 'react';
import ProductBeachBookerLayout from './ProductBeachBookerLayout';
import TypographyWithLine from 'src/components/shared/TypographyWithLine';
import { Grid } from '@mui/material';
import BeachCard from 'src/components/home/BeachCard';
import { useTranslation } from "react-i18next";

const backgroundImage = '/assets/images/home-save-time.jpg';

export default function ProductBeachBooker() {
  const { t } = useTranslation('translation', { keyPrefix: 'homePage.beach' });

  return (
    <ProductBeachBookerLayout
      className="finder-list-location-xdawd-dada"
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'top',
        backgroundSize: 'cover',
      }}
    >
      <TypographyWithLine
        text={t("title")}
        color="#fff"
        sx={{
          position: 'relative',
          fontSize: "3rem",
          fontFamily: '"TT Norms Pro Medium",Helvetica,sans-serif!important',
          lineHeight: "3rem",
          '&:before': {
            position: 'absolute',
            width: '100px',
            backgroundColor: '#fff',
            height: '8px',
            top: '-20px',
            content: '""',
          },
        }}
      />
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <BeachCard
            imageName={'reservation.png'}
            primaryText={t("reservationPrimary")}
            secondaryText={t("reservationSecondary")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BeachCard
            imageName={'restauration.png'}
            primaryText={t("restorationPrimary")}
            secondaryText={t("restorationSecondary")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BeachCard
            imageName={'conciergerie.png'}
            primaryText={t("premiumPrimary")}
            secondaryText={t("premiumSecondary")}
          />
        </Grid>
      </Grid>
    </ProductBeachBookerLayout>
  );
}
