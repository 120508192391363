import api from './api';

const API_PREFIX = "/api/b2c";

const getFinderSearch = async (params) => {
  const response = await api.get(`${API_PREFIX}/search-hut`, { params });
  return response;
};

const getCityNames = async (params) => {
  const response = await api.get(`api/b2c/city-list`, { params });
  return response;
};


export { getFinderSearch, getCityNames };
