import SplitForm from './SplitForm';

const CheckoutForm = ({ createBeachBookingPaymet, isCheckedCondition, setIsCheckedCondition, saveLoading }) => {
  return <SplitForm
    className='beachiconimg-cls-adding-checkoitform'
    saveLoading={saveLoading}
    setIsCheckedCondition={setIsCheckedCondition}
    isCheckedCondition={isCheckedCondition}
    createBeachBookingPaymet={createBeachBookingPaymet}
  />;
};

export default CheckoutForm;
