import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';


export default function ActionMoreMenu({ onClickDelete, onClickEdit, row }) {
    const { t } = useTranslation('translation');

    const checkEditAccess = useCallback((row) => {
        if (row && row.date) {
            let todayDate = moment().add(1, "day").toDate();
            let start_time = "8:00"
            let rDate = moment(row?.date + " " + start_time).toDate();
            if (moment(rDate).isAfter(todayDate)) {
                return true
            }
        }
        return false;
    }, []);

    const handleDownload = useCallback((row) => {
        if (row && row.orders && row.orders.receipt_pdf) {
            window.open(row.orders.receipt_pdf, 'Download');
        } else {
            alert("No Receipt Found");
        }
    }, []);

    return (
        <>
            <Stack key={row.id} direction={'row'} spacing={1} justifyContent="center" className='actionmenu-cls-adding'>
                <LoadingButton size="small" variant="outlined" onClick={() => handleDownload(row)}>
                    {t("downloadInvoice")}
                </LoadingButton>
                {checkEditAccess(row) && (
                    <>
                        <Button size="small" variant="outlined" onClick={() => onClickEdit(row)}>
                            {t("modifier")}
                        </Button>

                        <Button size="small" variant="outlined" onClick={() => onClickDelete(row.id)}>
                            {t("cancel")}
                        </Button>
                    </>
                )}
            </Stack>
        </>
    );
}
