import { Box, Typography } from '@mui/material';
import React from 'react';
import CartButtons from 'src/components/cart-button/CartButtons';

export default function ReservationPlaceItem({ handleClickPrevButton, handleClickNextButton, counter = 0, title }) {
    return (
        <>
            <Box
                className='beachiconimg-cls-adding-placement-cls-adding'
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#FFF0EE',
                    borderRadius: '10px',
                    p: '8px 16px',
                    mb: 3
                }}
            >
                <Box className="grid-type-cls-adding">
                    <Typography color="#242141" fontWeight="600" fontSize="16px">
                        {title}
                    </Typography>
                    {/* <Typography color="#FFA17E" fontSize="14px" fontWeight="600">
                            0.00 €
                        </Typography> */}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }} className='beachiconimg-cls-adding-carts-b'>
                    <CartButtons
                        handleOnClickPrev={() => handleClickPrevButton(counter - 1)}
                        prevDisabled={counter <= 0}
                        handleOnClickNext={() => handleClickNextButton(counter + 1)}
                        nextDisabled={false}
                        selectedNumber={counter}
                    />
                </Box>
            </Box>
        </>
    );
}
