import { FormGroup, Link } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import RestaurantBookingForm from '../RestaurantBookingForm';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function RestaurantBookOption({
    isRestaurant,
    setRestaurantDetails,
    setIsRestaurant,
    details,
    date,
    setSelectedDate,
    id,
    restaurantDetails,
    defaultRestaurant,
    restaurantsData
}) {
    const { t } = useTranslation('translation', { keyPrefix: 'booking' });

    return (
        <>
            {!defaultRestaurant && (
                <>
                    <FormGroup sx={{ color: 'black', mt: '5px' }} className='resturant-cls-adding-here-type'>
                        <FormControlLabel
                            checked={isRestaurant}
                            onClick={(e) => {
                                setRestaurantDetails(null);
                                setIsRestaurant(e.target.checked);
                            }}
                            sx={{
                                ".MuiFormControlLabel-label": {
                                    fontSize: "20px"
                                }
                            }}
                            control={<Checkbox />}
                            label={t("reservationRestaurant")}
                        />
                    </FormGroup>
                    {restaurantsData && restaurantsData?.pdf_file && (
                        <FormGroup sx={{ color: 'black', mt: '-5px', ml: "30px", cursor: "pointer" }} className='cursort-pointing-cls-adding'>
                            <Link target="_blank" href={restaurantsData?.pdf_file}>({t("restaurantMenu")})</Link>
                        </FormGroup>
                    )}
                </>
            )}
            {(isRestaurant || defaultRestaurant) && (
                <RestaurantBookingForm
                    className="restiant-booking-form-cls-adding"
                    restaurant={details?.restaurants[0]}
                    pdfFile={restaurantsData?.pdf_file && defaultRestaurant ? restaurantsData?.pdf_file : null}
                    date={date}
                    setSelectedDate={setSelectedDate}
                    id={id}
                    setRestaurantDetails={setRestaurantDetails}
                    restaurantDetails={restaurantDetails}
                />
            )}
        </>
    );
}
