import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import { useTranslation } from 'react-i18next';

export const getLabelName = (label) => {
  switch (label) {
    case 'Today':
      return "Aujourd'hui";
    case 'Yesterday':
      return 'Hier';
    case 'This Week':
      return 'Cette semaine';
    case 'Last Week':
      return 'La semaine dernière';
    case 'This Month':
      return 'Ce mois-ci';
    case 'Last Month':
      return 'Le mois dernier';
    default:
      return label;
  }
};

export default function DateRangePickerComponent({
  handleChangeDate,
  style,
  setDate,
  defaultValue,
  shrink = true,
  disabled = false,
  type = null,
  dateClassNameProps
}) {
  const [selectedValue, setSelectedValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation('translation');

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const onChange = useCallback((event) => {
    const { selection } = event;
    setSelectedValue(selection);
  }, []);

  return (
    <>
      <TextField
        className='daterangepicker-clsadding'
        fullWidth
        disabled={disabled}
        onClick={() => setIsOpen((prevState) => !prevState)}
        size="small"
        label={t('when')}
        value={
          selectedValue?.startDate
            ? moment(selectedValue.startDate).format('DD MMMM') +
            ' - ' +
            moment(selectedValue.endDate).format('DD MMMM')
            : ''
        }
        InputLabelProps={{
          shrink: shrink,
        }}
        sx={{
          ...style,
        }}
      />
      {isOpen && (
        <Dialog
          open={true}
          width={'sm'}
          disableScrollLock={true}
          fullWidth
          hideBackdrop
          className='dialog-open-cls-adding-here'
          sx={{            
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",                
              },
            },
          }}
        >
          <DialogContent sx={{padding:"0px 0px"}}>
            {type === "home" ?
              <DateRangePicker
                autoFocus={true}
                onChange={onChange}
                ranges={[selectedValue]}
                showSelectionPreview={false}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                retainEndDateOnFirstSelection={true}
                staticRanges={[]}
                months={2}
                inputRanges={[]}
                direction="horizontal"
                id="date-range-picker"
                className={`dateRangePickerClass ${dateClassNameProps}`}
                locale={locales[i18n.language]}
              /> : (
                <DateRangePicker
                minDate={moment().toDate()}
                  autoFocus={true}
                  onChange={onChange}
                  ranges={[selectedValue]}
                  showSelectionPreview={false}
                  showDateDisplay={false}
                  showMonthAndYearPickers={false}
                  retainEndDateOnFirstSelection={true}
                  staticRanges={[
                    ...defaultStaticRanges.map((ele) => {
                      return {
                        ...ele,
                        label: getLabelName(ele.label),
                      };
                    }),
                  ]}
                  months={2}
                  inputRanges={[]}
                  direction="horizontal"
                  id="date-range-picker"
                  className={`dateRangePickerClass ${dateClassNameProps}`}
                  locale={locales[i18n.language]}
                />
              )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <Button
                onClick={() => {
                  handleChangeDate(selectedValue);
                  setIsOpen(false);
                }}
                variant="contained"
                size="small"
                sx={{
                  fontFamily: "'TT Norms Pro Regular', Helvetica, sans-serif !important"
                }}
              >
                {t('validate')}
              </Button>
              <Button
                onClick={() => setIsOpen(false)}
                variant="outlined"
                size="small"
                sx={{
                  fontFamily: "'TT Norms Pro Regular', Helvetica, sans-serif !important"
                }}
              >
                {t('cancel')}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
