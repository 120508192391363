import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import * as locales from 'react-date-range/dist/locale';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-date-range';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  dialog: {
    top: 30
  }
})

export default function DatePickerHeaderComponent({
  handleChangeDate,
  date,
  className,
  style,
  setDate,
  defaultValue,
  shrink = true,
  disabled = false,
  label
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue || new Date());
  const { t, i18n } = useTranslation('translation');
  const [selectedValue, setSelectedValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleOpen = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);


  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const onChange = useCallback((event) => {
    const { selection } = event;
    setSelectedValue(selection);
  }, []);


  const classes = useStyles()

  return (
    <>
      <TextField
        className='datepicker-cls-adding'
        fullWidth
        disabled={disabled}
        onClick={handleOpen}
        size="small"
        border="2px solid yellow"
        InputLabelProps={{
          shrink: false, // Prevent label from shrinking when active
          sx: {
            p: "0.5rem",
            pl: "1rem",
            transform: 'none', // Prevent label from shrinking (inline style)
          },
        }}
        label={t('when')}
        type="text"
        value={
          selectedValue?.startDate
            ? moment(selectedValue.startDate).format('DD MMM') +
            ' - ' +
            moment(selectedValue.endDate).format('DD MMM')
            : ''
        }
        inputProps={{
          min: moment().format('YYYY-MM-DD'), // Set the minimum date to today
        }}
        sx={{
          ...style,
        }}
      />
      {open && (
        <Dialog
          open={true}
          disableScrollLock={true}
          fullWidth
          width="sm"
          hideBackdrop
          className='dialog-open-cls-adding-here'
          scroll="paper"
          classes={{
            paper: classes.dialog,
            scrollPaper: classes.topScrollPaper,
            paperScrollBody: classes.topPaperScrollBody,
          }}

        >
          <DialogContent>
            <DateRangePicker
              onChange={onChange}
              ranges={[selectedValue]}
              showSelectionPreview={false}
              showDateDisplay={false}
              showMonthAndYearPickers={false}
              retainEndDateOnFirstSelection={true}
              staticRanges={[]}
              months={2}
              inputRanges={[]}
              direction="horizontal"
              id="date-range-picker"
              locale={locales[i18n.language]}
              className={`dateRangePickerClass`}
            />
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <Button
                onClick={() => {
                  handleChangeDate(selectedValue);
                  setOpen(false);
                }}
                variant="contained"
                size="small"
              >
                {t('validate')}
              </Button>
              <Button onClick={() => setOpen(false)} variant="outlined" size="small">
                {t('cancel')}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
